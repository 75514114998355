import * as THREE from 'three';
import DmxServo from './DmxServo';

class DmxServo3D extends DmxServo {
    constructor(modelData) {
        super(modelData);
        this.displayAs = "DmxServo3D";

        this.channelX = modelData.channelX || 0;
        this.channelY = modelData.channelY || 0;
        this.channelZ = modelData.channelZ || 0;

        this.minAngleX = modelData.minAngleX || -90;
        this.maxAngleX = modelData.maxAngleX || 90;
        this.minAngleY = modelData.minAngleY || -90;
        this.maxAngleY = modelData.maxAngleY || 90;
        this.minAngleZ = modelData.minAngleZ || -90;
        this.maxAngleZ = modelData.maxAngleZ || 90;

        this.currentAngleX = 0;
        this.currentAngleY = 0;
        this.currentAngleZ = 0;

        this.reverseX = modelData.reverseX || false;
        this.reverseY = modelData.reverseY || false;
        this.reverseZ = modelData.reverseZ || false;
    }

    createMesh() {
        const group = new THREE.Group();

        // Create servo body
        const bodyGeometry = new THREE.BoxGeometry(0.4, 0.4, 0.4);
        const bodyMaterial = new THREE.MeshPhongMaterial({ color: 0x888888 });
        const bodyMesh = new THREE.Mesh(bodyGeometry, bodyMaterial);
        group.add(bodyMesh);

        // Create servo arm
        const armGeometry = new THREE.BoxGeometry(0.3, 0.3, 0.3);
        const armMaterial = new THREE.MeshPhongMaterial({ color: 0x444444 });
        this.armMesh = new THREE.Mesh(armGeometry, armMaterial);
        this.armMesh.position.set(0, 0.35, 0);
        group.add(this.armMesh);

        return group;
    }

    updateFromDmxValues(dmxValues) {
        super.updateFromDmxValues(dmxValues);

        if (this.channelX > 0 && this.channelX <= dmxValues.length) {
            const dmxValueX = dmxValues[this.channelX - 1];
            this.setAngleX(this.dmxToAngle(dmxValueX, this.minAngleX, this.maxAngleX, this.reverseX));
        }

        if (this.channelY > 0 && this.channelY <= dmxValues.length) {
            const dmxValueY = dmxValues[this.channelY - 1];
            this.setAngleY(this.dmxToAngle(dmxValueY, this.minAngleY, this.maxAngleY, this.reverseY));
        }

        if (this.channelZ > 0 && this.channelZ <= dmxValues.length) {
            const dmxValueZ = dmxValues[this.channelZ - 1];
            this.setAngleZ(this.dmxToAngle(dmxValueZ, this.minAngleZ, this.maxAngleZ, this.reverseZ));
        }
    }

    setAngleX(angle) {
        this.currentAngleX = Math.max(this.minAngleX, Math.min(this.maxAngleX, angle));
        this.updateMesh();
    }

    setAngleY(angle) {
        this.currentAngleY = Math.max(this.minAngleY, Math.min(this.maxAngleY, angle));
        this.updateMesh();
    }

    setAngleZ(angle) {
        this.currentAngleZ = Math.max(this.minAngleZ, Math.min(this.maxAngleZ, angle));
        this.updateMesh();
    }

    dmxToAngle(dmxValue, minAngle, maxAngle, reverse) {
        const normalizedValue = dmxValue / 255;
        const angle = minAngle + normalizedValue * (maxAngle - minAngle);
        return reverse ? maxAngle - angle + minAngle : angle;
    }

    updateMesh() {
        if (this.armMesh) {
            this.armMesh.rotation.set(
                THREE.MathUtils.degToRad(this.currentAngleX),
                THREE.MathUtils.degToRad(this.currentAngleY),
                THREE.MathUtils.degToRad(this.currentAngleZ)
            );
        }
    }

    onPropertyChange(propertyName, value) {
        switch (propertyName) {
            case 'channelX':
            case 'channelY':
            case 'channelZ':
                this[propertyName] = parseInt(value);
                return true;
            case 'minAngleX':
            case 'maxAngleX':
            case 'minAngleY':
            case 'maxAngleY':
            case 'minAngleZ':
            case 'maxAngleZ':
                this[propertyName] = parseFloat(value);
                this.updateMesh(); // Reapply current angles to ensure they're within new limits
                return true;
            case 'reverseX':
            case 'reverseY':
            case 'reverseZ':
                this[propertyName] = Boolean(value);
                this.updateMesh(); // Reapply current angles to account for reverse changes
                return true;
            default:
                return super.onPropertyChange(propertyName, value);
        }
    }

    getProperties() {
        return {
            ...super.getProperties(),
            channelX: this.channelX,
            channelY: this.channelY,
            channelZ: this.channelZ,
            minAngleX: this.minAngleX,
            maxAngleX: this.maxAngleX,
            minAngleY: this.minAngleY,
            maxAngleY: this.maxAngleY,
            minAngleZ: this.minAngleZ,
            maxAngleZ: this.maxAngleZ,
            reverseX: this.reverseX,
            reverseY: this.reverseY,
            reverseZ: this.reverseZ,
        };
    }
}

export default DmxServo3D;