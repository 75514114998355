import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo } from '../../utils/logger';

class MatrixModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.name = 'MatrixModel';
    this.parseModelData();
  }

  parseModelData() {
    const {
      parm1,
      parm2,
      parm3,
      DisplayAs,
      Dir,
      StartSide,
      AlternateNodes,
      NoZig,
      StringType,
      PixelSize,
    } = this.modelData;

    this.strings = parseInt(parm1) || 1;
    this.pixelsPerString = parseInt(parm2) || 50;
    this.strandsPerString = parseInt(parm3) || 1;
    this.vMatrix = DisplayAs === 'Vert Matrix';
    this.isLtoR = Dir !== 'R';
    this.isBotToTop = StartSide === 'B';
    this.alternateNodes = AlternateNodes === 'true';
    this.noZig = NoZig === 'true';
    this.stringType = StringType || 'RGB Nodes';
    this.pixelSize = parseFloat(PixelSize) || 2;
    this.singleNode = this.stringType.startsWith('Single Color');

    this.numStrands = this.strings * this.strandsPerString;
    this.pixelsPerStrand = this.pixelsPerString / this.strandsPerString;
    this.totalPixels = this.numStrands * this.pixelsPerStrand;

    logInfo('Matrix parameters:', {
      name: this.modelData.name,
      strings: this.strings,
      pixelsPerString: this.pixelsPerString,
      strandsPerString: this.strandsPerString,
      vMatrix: this.vMatrix,
      isLtoR: this.isLtoR,
      isBotToTop: this.isBotToTop,
      alternateNodes: this.alternateNodes,
      noZig: this.noZig,
      stringType: this.stringType,
      pixelSize: this.pixelSize,
      totalPixels: this.totalPixels,
    });
  }

  createGeometry() {
    if (this.vMatrix) {
      return this.createVMatrixGeometry();
    } else {
      return this.createHMatrixGeometry();
    }
  }

  createVMatrixGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    let pixelIndex = 0;

    for (let x = 0; x < this.numStrands; x++) {
      const stringNum = Math.floor(x / this.strandsPerString);
      const segmentNum = x % this.strandsPerString;

      for (let y = 0; y < this.pixelsPerStrand; y++) {
        const bufX = this.isLtoR ? x : this.numStrands - x - 1;
        let bufY;

        if (this.alternateNodes) {
          if (this.isBotToTop) {
            bufY = y < this.pixelsPerStrand / 2 ? y * 2 : (this.pixelsPerStrand - 1 - y) * 2 + 1;
          } else {
            bufY =
              y < this.pixelsPerStrand / 2
                ? this.pixelsPerStrand - 1 - y * 2
                : y * 2 - this.pixelsPerStrand + 1;
          }
        } else if (this.noZig) {
          bufY = this.isBotToTop ? y : this.pixelsPerStrand - y - 1;
        } else {
          bufY = this.isBotToTop === (segmentNum % 2 === 0) ? y : this.pixelsPerStrand - y - 1;
        }

        positions.push(bufX - this.numStrands / 2, bufY - this.pixelsPerStrand / 2, 0);

        if (pixelIndex === 0) {
          colors.push(0, 1, 1); // Teal color for the first pixel
        } else if (pixelIndex === this.totalPixels - 1) {
          colors.push(0.5, 0, 0); // Dull red color for the last pixel
        } else {
          colors.push(1, 1, 1); // White color for all other pixels
        }

        pixelIndex++;
      }
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    return geometry;
  }

  createHMatrixGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    let pixelIndex = 0;

    for (let y = 0; y < this.numStrands; y++) {
      const stringNum = Math.floor(y / this.strandsPerString);
      const segmentNum = y % this.strandsPerString;

      for (let x = 0; x < this.pixelsPerStrand; x++) {
        const bufY = this.isBotToTop ? y : this.numStrands - y - 1;
        let bufX;

        if (this.alternateNodes) {
          if (this.isLtoR) {
            bufX = x < this.pixelsPerStrand / 2 ? x * 2 : (this.pixelsPerStrand - 1 - x) * 2 + 1;
          } else {
            bufX =
              x < this.pixelsPerStrand / 2
                ? this.pixelsPerStrand - 1 - x * 2
                : x * 2 - this.pixelsPerStrand + 1;
          }
        } else if (this.noZig) {
          bufX = this.isLtoR ? x : this.pixelsPerStrand - x - 1;
        } else {
          bufX = this.isLtoR !== (segmentNum % 2 === 0) ? this.pixelsPerStrand - x - 1 : x;
        }

        positions.push(bufX - this.pixelsPerStrand / 2, bufY - this.numStrands / 2, 0);

        if (pixelIndex === 0) {
          colors.push(0, 1, 1); // Teal color for the first pixel
        } else if (pixelIndex === this.totalPixels - 1) {
          colors.push(0.5, 0, 0); // Dull red color for the last pixel
        } else {
          colors.push(1, 1, 1); // White color for all other pixels
        }

        pixelIndex++;
      }
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    return geometry;
  }

  createMesh() {
    const geometry = this.createGeometry();
    const material = new THREE.PointsMaterial({
      size: this.pixelSize,
      sizeAttenuation: false,
      vertexColors: true,
    });

    const points = new THREE.Points(geometry, material);
    return this.applyTransformations(points);
  }

  applyTransformations(object) {
    const { worldPos } = this.modelData.screenLocation;
    object.position.set(
      worldPos.x * this.worldScale,
      worldPos.y * this.worldScale,
      worldPos.z * this.worldScale
    );

    // Convert rotation from degrees to radians
    const rotationX = THREE.MathUtils.degToRad(parseFloat(this.modelData.rotation.x));
    const rotationY = THREE.MathUtils.degToRad(parseFloat(this.modelData.rotation.y));
    const rotationZ = THREE.MathUtils.degToRad(parseFloat(this.modelData.rotation.z));

    // Create a rotation matrix
    const rotationMatrix = new THREE.Matrix4();
    rotationMatrix.makeRotationFromEuler(new THREE.Euler(rotationX, rotationY, rotationZ, 'ZYX'));

    // Apply the rotation matrix to the object
    object.setRotationFromMatrix(rotationMatrix);

    const { width, height, depth } = this.modelData.screenLocation;
    object.scale.set(width * this.worldScale, height * this.worldScale, depth * this.worldScale);

    logInfo('Created Matrix mesh:', {
      name: this.modelData.name,
      vMatrix: this.vMatrix,
      vertices: object.geometry.attributes.position.count,
      position: object.position,
      scale: object.scale,
      rotation: object.rotation,
      rotationDegrees: {
        x: this.modelData.rotation.x,
        y: this.modelData.rotation.y,
        z: this.modelData.rotation.z,
      },
    });

    return object;
  }
}

export default MatrixModel;
