import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo, logWarning, logError } from '../../utils/logger';

class SubModel extends BaseModel {
    constructor(modelData) {
        super(modelData);
        this.name = 'SubModel';
        this.parseModelData();
    }

    parseModelData() {
        const { StartChannel, ChannelCount, Name } = this.modelData;
        this.startChannel = parseInt(StartChannel) || 1;
        this.channelCount = parseInt(ChannelCount) || 1;
        this.subModelName = Name || '';

        logInfo('SubModel parameters:', {
            startChannel: this.startChannel,
            channelCount: this.channelCount,
            subModelName: this.subModelName,
        });
    }

    createGeometry() {
        // SubModel doesn't have its own geometry, it's a part of the parent model
        // This is a placeholder geometry for visualization purposes
        return new THREE.BufferGeometry();
    }

    createMesh() {
        const geometry = this.createGeometry();
        const material = new THREE.PointsMaterial({
            size: parseFloat(this.modelData.PixelSize) || 2,
            sizeAttenuation: false,
            color: 0xffff00, // Yellow color to distinguish submodels
        });

        const points = new THREE.Points(geometry, material);
        return this.applyTransformations(points);
    }

    applyTransformations(object) {
        // SubModel position is relative to the parent model
        // This is a placeholder transformation
        const { worldPos } = this.modelData.screenLocation || { worldPos: { x: 0, y: 0, z: 0 } };
        object.position.set(worldPos.x, worldPos.y, worldPos.z);
        return object;
    }

    getDimension() {
        // SubModel dimension is typically determined by the parent model
        // This is a placeholder value
        return 1;
    }
}

export default SubModel;