import * as THREE from 'three';
import DmxModel from './DmxModel';
import DmxColorAbilityRGB from './MovingHeads/DmxColorAbilityRGB';
import DmxPresetAbility from './DmxPresetAbility';

class DmxGeneral extends DmxModel {
    constructor(modelData) {
        super(modelData);
        this.displayAs = "DmxGeneral";
        this.colorAbility = new DmxColorAbilityRGB(modelData);
        this.presetAbility = new DmxPresetAbility(modelData);
        this.shutterChannel = modelData.shutterChannel || 0;
        this.shutterOpenThreshold = modelData.shutterOpenThreshold || 1;
        this.shutterOnValue = modelData.shutterOnValue || 0;
        this.beamLength = modelData.beamLength || 1;
        this.beamWidth = modelData.beamWidth || 1;
    }

    createGeometry() {
        // Create a group to hold all the geometries
        const group = new THREE.Group();

        // Create the base of the fixture
        const baseGeometry = new THREE.BoxGeometry(0.5, 0.5, 0.2);
        const baseMesh = new THREE.Mesh(baseGeometry);
        group.add(baseMesh);

        // Create the fixture body
        const bodyGeometry = new THREE.CylinderGeometry(0.2, 0.2, 0.4, 32);
        const bodyMesh = new THREE.Mesh(bodyGeometry);
        bodyMesh.rotation.x = Math.PI / 2;
        bodyMesh.position.z = 0.2;
        group.add(bodyMesh);

        // Create the beam
        this.beamGeometry = new THREE.ConeGeometry(this.beamWidth / 2, this.beamLength, 32);
        this.beamMesh = new THREE.Mesh(this.beamGeometry);
        this.beamMesh.rotation.x = Math.PI / 2;
        this.beamMesh.position.z = 0.4 + this.beamLength / 2;
        group.add(this.beamMesh);

        return group;
    }

    createMesh() {
        const group = this.createGeometry();

        // Apply materials
        group.children[0].material = new THREE.MeshPhongMaterial({ color: 0x888888 });
        group.children[1].material = new THREE.MeshPhongMaterial({ color: 0x444444 });
        this.beamMesh.material = new THREE.MeshPhongMaterial({
            color: 0xffffff,
            transparent: true,
            opacity: 0.5,
            side: THREE.DoubleSide
        });

        // Add a point light to simulate the light output
        this.light = new THREE.PointLight(0xffffff, 1, this.beamLength * 2);
        this.light.position.set(0, 0, 0.4);
        group.add(this.light);

        // Apply transformations from BaseModel
        return this.applyTransformations(group);
    }

    updateFromDmxValues(dmxValues) {
        super.updateFromDmxValues(dmxValues);

        // Update color
        this.colorAbility.updateFromDmxValues(dmxValues);
        const color = this.colorAbility.getBeamColor();

        // Update preset
        this.presetAbility.updateFromDmxValues(dmxValues);

        // Update shutter
        const shutterOpen = this.isShutterOpen(dmxValues);

        // Apply updates to the mesh
        if (this.beamMesh && this.light) {
            this.beamMesh.material.color.setRGB(color.r, color.g, color.b);
            this.light.color.setRGB(color.r, color.g, color.b);
            this.beamMesh.visible = shutterOpen;
            this.light.intensity = shutterOpen ? 1 : 0;
        }
    }

    isShutterOpen(dmxValues) {
        if (this.shutterChannel > 0 && this.shutterChannel <= dmxValues.length) {
            return dmxValues[this.shutterChannel - 1] >= this.shutterOpenThreshold;
        }
        return true;
    }

    setBeamDimensions(length, width) {
        this.beamLength = length;
        this.beamWidth = width;
        if (this.beamMesh) {
            const newGeometry = new THREE.ConeGeometry(width / 2, length, 32);
            this.beamMesh.geometry.dispose();
            this.beamMesh.geometry = newGeometry;
            this.beamMesh.position.z = 0.4 + length / 2;
        }
        if (this.light) {
            this.light.distance = length * 2;
        }
    }

    onPropertyChange(propertyName, value) {
        switch (propertyName) {
            case 'beamLength':
                this.setBeamDimensions(parseFloat(value), this.beamWidth);
                return true;
            case 'beamWidth':
                this.setBeamDimensions(this.beamLength, parseFloat(value));
                return true;
            case 'shutterChannel':
                this.shutterChannel = parseInt(value);
                return true;
            case 'shutterOpenThreshold':
                this.shutterOpenThreshold = parseInt(value);
                return true;
            case 'shutterOnValue':
                this.shutterOnValue = parseInt(value);
                return true;
            default:
                return super.onPropertyChange(propertyName, value);
        }
    }

    getProperties() {
        return {
            ...super.getProperties(),
            beamLength: this.beamLength,
            beamWidth: this.beamWidth,
            shutterChannel: this.shutterChannel,
            shutterOpenThreshold: this.shutterOpenThreshold,
            shutterOnValue: this.shutterOnValue,
            ...this.colorAbility.getChannelProperties(),
            ...this.presetAbility.getChannelProperties()
        };
    }
}

export default DmxGeneral;