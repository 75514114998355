import * as THREE from 'three';
import BaseModel from './models/BaseModel';
import CustomModel from './models/CustomModel';
import TreeModel from './models/TreeModel';
import ArchesModel from './models/ArchesModel';
import CandyCaneModel from './models/CandyCaneModel';
import CircleModel from './models/CircleModel';
import SphereModel from './models/SphereModel';
import SpinnerModel from './models/SpinnerModel';
import MatrixModel from './models/MatrixModel';
import WindowFrameModel from './models/WindowFrameModel';
import StarModel from './models/StarModel';
import CubeModel from './models/CubeModel';
import IciclesModel from './models/IciclesModel';
import PolyLineModel from './models/PolyLineModel';
import SingleLineModel from './models/SingleLineModel';
import ChannelBlockModel from './models/ChannelBlockModel';
import GridlinesObject from './models/GridlinesObject';
import WreathModel from './models/WreathModel';
import BoxedScreenLocation from './models/BoxedScreenLocation';
import ImageModel from './models/ImageModel';
import ModelGroup from './models/ModelGroup';
import MultiPointModel from './models/MultiPointModel';
import SubModel from './models/SubModel';
import { logInfo, logWarning, logError } from '../utils/logger';

// Import DMX models
import DmxColorAbility from './models/DMX/MovingHeads/DmxColorAbility';
import DmxColorAbilityCMY from './models/DMX/MovingHeads/DmxColorAbilityCMY';
import DmxColorAbilityRGB from './models/DMX/MovingHeads/DmxColorAbilityRGB';
import DmxColorAbilityWheel from './models/DMX/MovingHeads/DmxColorAbilityWheel';
import DmxDimmerAbility from './models/DMX/DmxDimmerAbility';
import DmxFloodArea from './models/DMX/DmxFloodArea';
import DmxFloodlight from './models/DMX/DmxFloodlight';
import DmxGeneral from './models/DMX/DmxGeneral';
import DmxImage from './models/DMX/DmxImage';
import DmxModel from './models/DMX/DmxModel';
import DmxMotor from './models/DMX/DmxMotor';
import DmxMovingHead from './models/DMX/MovingHeads/DmxMovingHead';
import DmxMovingHeadAdv from './models/DMX/MovingHeads/DmxMovingHeadAdv';
import DmxPanTiltAbility from './models/DMX/MovingHeads/DmxPanTiltAbility';
import DmxPresetAbility from './models/DMX/DmxPresetAbility';
import DmxServo from './models/DMX/DmxServo';
import DmxServo3D from './models/DMX/DmxServo3D';
import DmxShutterAbility from './models/DMX/DmxShutterAbility';
import DmxSkull from './models/DMX/DmxSkull';
import DmxSkulltronix from './models/DMX/DmxSkulltronix';

class ModelCache {
  constructor() {
    this.cache = new Map();
  }

  get(key) {
    return this.cache.get(key) || null;
  }

  set(key, model) {
    this.cache.set(key, model);
  }

  has(key) {
    return this.cache.has(key);
  }

  clear() {
    this.cache.clear();
  }
}

class ModelRenderer {
  constructor() {
    this.modelClasses = {
      Base: BaseModel,
      Custom: CustomModel,
      Tree: TreeModel,
      'Tree 360': TreeModel,
      'Tree 270': TreeModel,
      'Tree Ribbon': TreeModel,
      'Tree Flat': TreeModel,
      'Tree 180': TreeModel,
      Arches: ArchesModel,
      'Candy Cane': CandyCaneModel,
      'Candy Canes': CandyCaneModel,
      Circle: CircleModel,
      Sphere: SphereModel,
      Spinner: SpinnerModel,
      Matrix: MatrixModel,
      'Horiz Matrix': MatrixModel,
      'Vert Matrix': MatrixModel,
      'Window Frame': WindowFrameModel,
      Star: StarModel,
      Cube: CubeModel,
      Icicles: IciclesModel,
      'Poly Line': PolyLineModel,
      'Single Line': SingleLineModel,
      'Channel Block': ChannelBlockModel,
      Gridlines: GridlinesObject,
      Wreath: WreathModel,
      BoxedScreenLocation: BoxedScreenLocation,
      Image: ImageModel,
      ModelGroup: ModelGroup,
      MultiPoint: MultiPointModel,
      SubModel: SubModel,
      // DMX models
      'DMX Color Ability': DmxColorAbility,
      'DMX Color Ability CMY': DmxColorAbilityCMY,
      'DMX Color Ability RGB': DmxColorAbilityRGB,
      'DMX Color Ability Wheel': DmxColorAbilityWheel,
      'DMX Dimmer Ability': DmxDimmerAbility,
      'DMX Flood Area': DmxFloodArea,
      'DMX Floodlight': DmxFloodlight,
      'DMX General': DmxGeneral,
      DmxGeneral: DmxGeneral,
      'DMX Image': DmxImage,
      'DMX Model': DmxModel,
      'DMX Motor': DmxMotor,
      'DMX Moving Head': DmxMovingHead,
      'DMX Moving Head Advanced': DmxMovingHeadAdv,
      'DMX Pan Tilt Ability': DmxPanTiltAbility,
      'DMX Preset Ability': DmxPresetAbility,
      'DMX Servo': DmxServo,
      'DMX Servo 3D': DmxServo3D,
      'DMX Shutter Ability': DmxShutterAbility,
      'DMX Skull': DmxSkull,
      'DMX Skulltronix': DmxSkulltronix,
    };
    this.worldScale = 1;
    this.modelCache = new ModelCache();
  }

  createMesh(modelData, sharedMaterial) {
    const cacheKey = `${modelData.name}_${modelData.displayAs}`;

    if (this.modelCache.has(cacheKey)) {
      logInfo(`Using cached model for ${modelData.name}`);
      return this.modelCache.get(cacheKey).clone();
    }

    logInfo(`Processing model: ${modelData.name}, Type: ${modelData.displayAs}`);

    try {
      let ModelClass = this.getModelClass(modelData);

      if (!ModelClass) {
        logWarning(`Unknown model type for model: ${modelData.name}`);
        return this.createFallbackMesh(modelData);
      }

      logInfo(`Using ModelClass: ${ModelClass.name} for model: ${modelData.name}`);
      logInfo('Model data passed to constructor:', modelData);

      const model = new ModelClass(modelData);
      model.setWorldScale(this.worldScale);

      logInfo(`Creating mesh for model: ${modelData.name}`);
      let object = model.createMesh();
      logInfo(`Mesh created for model: ${modelData.name}`, object);

      if (!object) {
        logError(`Failed to create mesh for model: ${modelData.name}`);
        return this.createFallbackMesh(modelData);
      }

      this.applySharedMaterial(object, sharedMaterial, modelData);

      object.updateMatrixWorld();
      logInfo(`Transformation matrix for ${modelData.name}:`, object.matrixWorld.elements);

      object.userData = {
        name: modelData.name,
        controller: modelData.controller,
        port: modelData.port,
        stringType: modelData.stringType,
        startChannel: modelData.startChannel,
        pixelCount: modelData.pixelCount,
        model: model,
      };

      if (!this.isValidMesh(object)) {
        logWarning(`Invalid mesh created for model: ${modelData.name}`);
        return this.createFallbackMesh(modelData);
      }

      // Create bounding box
      const boundingBox = this.createBoundingBox(object);

      // Create a group to hold both the object and its bounding box
      const group = new THREE.Group();
      group.add(object);
      group.add(boundingBox);

      // Transfer the userData to the group
      group.userData = object.userData;

      logInfo('Final object after creation:', group);

      this.modelCache.set(cacheKey, group.clone());

      return group;
    } catch (error) {
      logError('Error creating mesh for model:', modelData.name, error);
      return this.createFallbackMesh(modelData);
    }
  }

  createBoundingBox(mesh) {
    const bbox = new THREE.Box3().setFromObject(mesh);
    const boxGeometry = new THREE.BoxGeometry(
      bbox.max.x - bbox.min.x,
      bbox.max.y - bbox.min.y,
      bbox.max.z - bbox.min.z
    );
    const boxMaterial = new THREE.MeshBasicMaterial({
      color: 0x00ff00,
      wireframe: true,
      transparent: true,
      opacity: 0.5,
      visible: false, // Initially invisible
    });
    const boundingBox = new THREE.Mesh(boxGeometry, boxMaterial);
    boundingBox.position.copy(bbox.getCenter(new THREE.Vector3()));
    boundingBox.userData = { isBoundingBox: true };
    return boundingBox;
  }

  getModelClass(modelData) {
    if (modelData.displayAs) {
      // Specific check for DmxGeneral
      if (modelData.displayAs === 'DmxGeneral') {
        logInfo(`DmxGeneral model detected for ${modelData.name}`);
        return this.modelClasses['DmxGeneral'];
      }

      const displayType = modelData.displayAs.split(' ')[0];

      // Check for DMX models
      if (displayType === 'DMX' || modelData.displayAs.startsWith('DMX ')) {
        logInfo(`DMX model detected: ${modelData.displayAs} for ${modelData.name}`);
        return this.modelClasses[modelData.displayAs] || this.modelClasses['DMX General'];
      }

      const ModelClass = this.modelClasses[modelData.displayAs] ||
        this.modelClasses[displayType] ||
        this.modelClasses['Base'];

      logInfo(`Model class selected for ${modelData.name}: ${ModelClass.name}`);
      return ModelClass;
    } else if (modelData.customModel) {
      logInfo(`Custom model detected for ${modelData.name}`);
      return this.modelClasses['Custom'];
    } else if (modelData.displayAs === 'Horiz Matrix' || modelData.displayAs === 'Vert Matrix') {
      logInfo(`Matrix model detected for ${modelData.name}`);
      return this.modelClasses['Matrix'];
    }
    logInfo(`Fallback to Base model for ${modelData.name}`);
    return this.modelClasses['Base'];
  }

  applySharedMaterial(object, sharedMaterial, modelData) {
    if (object instanceof THREE.Group) {
      object.children.forEach((child) =>
        this.applySharedMaterial(child, sharedMaterial, modelData)
      );
    } else if (object.material && sharedMaterial) {
      object.material.color = sharedMaterial.color;
      if (object.material instanceof THREE.PointsMaterial) {
        object.material.size = parseFloat(modelData.pixelSize) || sharedMaterial.size || 2;
        object.material.sizeAttenuation = false;
      } else {
        object.material.wireframe = sharedMaterial.wireframe || false;
      }
      object.material.transparent = sharedMaterial.transparent || false;
      object.material.opacity = sharedMaterial.opacity || 1;
    }
  }

  isValidMesh(mesh) {
    if (mesh instanceof THREE.Group) {
      return mesh.children.some((child) => this.isValidMesh(child));
    }
    if (!mesh || !mesh.geometry) return false;
    const position = mesh.geometry.attributes.position;
    if (!position || !position.array) return false;
    for (let i = 0; i < position.array.length; i++) {
      if (isNaN(position.array[i])) return false;
    }
    return true;
  }

  createFallbackMesh(modelData) {
    logInfo(`Creating fallback mesh for model: ${modelData.name}`);
    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    const mesh = new THREE.Mesh(geometry, material);
    return mesh;
  }

  setWorldScale(scale) {
    this.worldScale = scale;
  }
}

export default ModelRenderer;
