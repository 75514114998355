import * as THREE from 'three';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import logoImage from '../assets/logo.png';
import Drawing from 'dxf-writer';

const CHUNK_SIZE = 5; // Number of controllers to process in each chunk

// Utility functions
function addControllerHeader(doc, controller, margin, logo) {
  const pageWidth = doc.internal.pageSize.width;
  const logoHeight = 15;
  const logoWidth = (logo.width / logo.height) * logoHeight;
  const logoX = pageWidth - margin - logoWidth;
  const logoY = margin;

  doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

  doc.setFontSize(14);
  doc.setTextColor(0, 51, 102);
  doc.text(`${controller.name} - ${controller.ip}`, margin, margin + 7);
  doc.setFontSize(10);
  doc.setTextColor(0);
  doc.text(`Model: ${controller.model} - ${controller.variant}`, margin, margin + 14);
}

function addFooter(doc) {
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(8);
  doc.setTextColor(100);
  doc.text('Have you donated to xLights today?', doc.internal.pageSize.width / 2, pageHeight - 10, {
    align: 'center',
  });
}

// Vector drawing utility functions
const drawLine = (doc, x1, y1, x2, y2, color = [0, 0, 0], lineWidth = 0.1) => {
  doc.setDrawColor(...color);
  doc.setLineWidth(lineWidth);
  doc.line(x1, y1, x2, y2);
};

const drawCircle = (doc, x, y, radius, color = [0, 0, 0], lineWidth = 0.1, fill = false) => {
  doc.setDrawColor(...color);
  doc.setFillColor(...color);
  doc.setLineWidth(lineWidth);
  doc.circle(x, y, radius, fill ? 'F' : 'S');
};

const drawText = (doc, text, x, y, fontSize = 10, color = [0, 0, 0], align = 'left') => {
  doc.setFontSize(fontSize);
  doc.setTextColor(...color);
  doc.text(text, x, y, { align: align });
};

// Updated generateWiringDiagramPDF function
export const generateWiringDiagramPDF = (wiringData, fileName, isRearView, rotation, modelName, layoutName) => {
  return new Promise((resolve, reject) => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4',
    });

    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 5; // Reduced margin for more space

    const logo = new Image();
    logo.src = logoImage;

    logo.onload = () => {
      try {
        console.log('Wiring data received:', wiringData);

        // Add logo
        const logoHeight = 10; // Reduced logo size
        const logoWidth = (logo.width / logo.height) * logoHeight;
        const logoX = pageWidth - margin - logoWidth;
        const logoY = margin;
        doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

        // Add title and info with smaller font size
        drawText(doc, `Wiring Diagram: ${layoutName} - ${modelName}`, margin, margin + 5, 14, [0, 51, 102]);
        drawText(doc, `View: ${isRearView ? 'CAUTION: Reverse view' : 'CAUTION: Front view'}`, margin, margin + 10, 10);
        drawText(doc, `Rotation: ${rotation}°`, margin, margin + 15, 10);

        // Set up drawing area
        const drawingAreaWidth = pageWidth - 2 * margin;
        const drawingAreaHeight = pageHeight - 2 * margin - 20; // 20 for title and info
        const drawingAreaX = margin;
        const drawingAreaY = margin + 20;

        // Parse wiring data
        const { width, height, nodes } = wiringData;

        if (!width || !height || !nodes || !Array.isArray(nodes)) {
          throw new Error('Invalid wiring data format');
        }

        // Calculate scaling factor
        const scaleX = drawingAreaWidth / width;
        const scaleY = drawingAreaHeight / height;
        const scale = Math.min(scaleX, scaleY) * 0.95; // 95% of available space

        // Calculate center of drawing area
        const centerX = drawingAreaX + drawingAreaWidth / 2;
        const centerY = drawingAreaY + drawingAreaHeight / 2;

        // Calculate offsets to center the diagram
        const offsetX = (drawingAreaWidth - width * scale) / 2;
        const offsetY = (drawingAreaHeight - height * scale) / 2;

        // Draw wiring diagram
        doc.setDrawColor(0); // Black
        doc.setLineWidth(0.2); // Increased line width

        nodes.forEach((node, index) => {
          // Apply rotation to node coordinates
          const rotatedX = Math.cos(rotation * Math.PI / 180) * (node.x - width / 2) -
            Math.sin(rotation * Math.PI / 180) * (node.y - height / 2) + width / 2;
          const rotatedY = Math.sin(rotation * Math.PI / 180) * (node.x - width / 2) +
            Math.cos(rotation * Math.PI / 180) * (node.y - height / 2) + height / 2;

          const x = drawingAreaX + offsetX + (isRearView ? width - rotatedX : rotatedX) * scale;
          // Flip the y-coordinate to correct the upside-down issue
          const y = drawingAreaY + drawingAreaHeight - offsetY - rotatedY * scale;

          // Draw node point (slightly larger and filled)
          drawCircle(doc, x, y, 0.45, [0], 0.1, true);

          // Draw node number in dark blue with vertical offset
          const verticalOffset = -0.85; // Changed sign to move text up
          drawText(doc, (index + 1).toString(), x, y + verticalOffset, 5, [237, 28, 36], 'center');

          // Draw line to next node
          if (index < nodes.length - 1) {
            const nextNode = nodes[index + 1];
            const nextRotatedX = Math.cos(rotation * Math.PI / 180) * (nextNode.x - width / 2) -
              Math.sin(rotation * Math.PI / 180) * (nextNode.y - height / 2) + width / 2;
            const nextRotatedY = Math.sin(rotation * Math.PI / 180) * (nextNode.x - width / 2) +
              Math.cos(rotation * Math.PI / 180) * (nextNode.y - height / 2) + height / 2;

            const nextX = drawingAreaX + offsetX + (isRearView ? width - nextRotatedX : nextRotatedX) * scale;
            // Flip the y-coordinate for the next node as well
            const nextY = drawingAreaY + drawingAreaHeight - offsetY - nextRotatedY * scale;
            drawLine(doc, x, y, nextX, nextY, [0], 0.2);
          }
        });

        // Save and download PDF
        doc.save(fileName);
        resolve();
      } catch (error) {
        console.error('Error in generateWiringDiagramPDF:', error);
        reject(new Error(`Error generating wiring diagram PDF: ${error.message}`));
      }
    };

    logo.onerror = () => {
      reject(new Error('Failed to load logo image'));
    };
  });
};

// Updated helper function to parse wiring data
const parseWiringData = (wiringData) => {
  if (!wiringData || typeof wiringData !== 'object') {
    console.error('Invalid wiring data:', wiringData);
    return { rows: 0, cols: 0, nodes: [] };
  }

  const rows = wiringData.height || 0;
  const cols = wiringData.width || 0;
  const nodes = [];

  if (wiringData.nodes && Array.isArray(wiringData.nodes)) {
    wiringData.nodes.forEach((node, index) => {
      nodes.push({
        number: index + 1,
        x: node.x,
        y: node.y
      });
    });
  }

  return { rows, cols, nodes };
};

// Updated generateVisualizerPDF function
export const generateVisualizerPDF = (scene, camera, layout, includeDXF = false, progressCallback = () => { }, fileName = 'xlights_layout_visualization.pdf') => {
  if (includeDXF) {
    return generateDXFPDF(scene, camera, layout, progressCallback, fileName);
  }

  return new Promise((resolve, reject) => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4',
    });

    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 10;

    const logo = new Image();
    logo.src = logoImage;

    logo.onload = () => {
      try {
        progressCallback(10); // Start progress

        const logoHeight = 15;
        const logoWidth = (logo.width / logo.height) * logoHeight;
        const logoX = pageWidth - margin - logoWidth;
        const logoY = margin;

        doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

        drawText(doc, 'xLights Layout Visualization', margin, margin + 10, 16);

        progressCallback(30); // Logo and title added

        // Extract data from Three.js scene and camera
        const sceneData = extractSceneData(scene, camera);

        progressCallback(60); // Scene data extracted

        // Render scene data to PDF using vector graphics
        renderSceneToPDF(doc, sceneData, margin, margin + 20, pageWidth - 2 * margin, pageHeight - 4 * margin - 20);

        progressCallback(80); // Scene rendered to PDF

        drawText(doc, 'Have you donated to xLights today?', pageWidth / 2, pageHeight - 5, 8, [100, 100, 100], 'center');

        // Save to downloads folder
        doc.save(fileName);

        progressCallback(100); // PDF saved
        resolve();
      } catch (error) {
        reject(new Error(`Error generating visualizer PDF: ${error.message}`));
      }
    };

    logo.onerror = () => {
      reject(new Error('Failed to load logo image'));
    };
  });
};

// Helper functions for vector-based rendering
const extractSceneData = (scene, camera) => {
  const sceneData = [];
  const frustum = new THREE.Frustum();
  const projScreenMatrix = new THREE.Matrix4();
  projScreenMatrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
  frustum.setFromProjectionMatrix(projScreenMatrix);

  scene.traverse((object) => {
    if (object instanceof THREE.Mesh && frustum.intersectsObject(object)) {
      const geometry = object.geometry;
      const position = object.position;
      const scale = object.scale;
      const rotation = object.rotation;

      if (geometry instanceof THREE.BufferGeometry) {
        const positions = geometry.attributes.position.array;
        const element = {
          type: 'mesh',
          points: [],
          color: object.material.color.getHexString(),
        };

        for (let i = 0; i < positions.length; i += 3) {
          let x = positions[i] * scale.x;
          let y = positions[i + 1] * scale.y;
          let z = positions[i + 2] * scale.z;

          const rotatedPoint = new THREE.Vector3(x, y, z).applyEuler(rotation);
          rotatedPoint.add(position);

          element.points.push({
            x: rotatedPoint.x,
            y: rotatedPoint.y,
            z: rotatedPoint.z,
          });
        }

        sceneData.push(element);
      }
    }
  });

  return sceneData;
};

const renderSceneToPDF = (doc, sceneData, x, y, width, height) => {
  // Calculate scaling factors
  const xValues = sceneData.flatMap(element => element.points.map(point => point.x));
  const yValues = sceneData.flatMap(element => element.points.map(point => point.y));
  const minX = Math.min(...xValues);
  const maxX = Math.max(...xValues);
  const minY = Math.min(...yValues);
  const maxY = Math.max(...yValues);

  const scaleX = width / (maxX - minX);
  const scaleY = height / (maxY - minY);
  const scale = Math.min(scaleX, scaleY);

  // Render each element
  sceneData.forEach(element => {
    switch (element.type) {
      case 'mesh':
        // Draw lines connecting the points
        doc.setDrawColor(`#${element.color}`);
        doc.setLineWidth(0.1);
        for (let i = 0; i < element.points.length; i += 3) {
          const p1 = element.points[i];
          const p2 = element.points[(i + 1) % element.points.length];
          const p3 = element.points[(i + 2) % element.points.length];

          const x1 = x + (p1.x - minX) * scale;
          const y1 = y + (p1.y - minY) * scale;
          const x2 = x + (p2.x - minX) * scale;
          const y2 = y + (p2.y - minY) * scale;
          const x3 = x + (p3.x - minX) * scale;
          const y3 = y + (p3.y - minY) * scale;

          doc.line(x1, y1, x2, y2);
          doc.line(x2, y2, x3, y3);
          doc.line(x3, y3, x1, y1);
        }
        break;
      // Add more cases for other element types as needed
    }
  });
};

// Existing functions (unchanged)

export const generatePDF = (
  layout,
  returnBlob = false,
  progressCallback = () => { },
  fileName = 'controller_layout.pdf'
) => {
  return new Promise((resolve, reject) => {
    if (!layout || !layout.controllers || !layout.portMapping) {
      reject(new Error('Invalid layout data provided'));
      return;
    }

    const doc = new jsPDF({
      compression: true,
      compressPdf: true,
    });
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 10;
    const headerHeight = 20;

    const logo = new Image();
    logo.src = logoImage;
    logo.onload = () => {
      const totalControllers = layout.controllers.length;
      const chunks = Math.ceil(totalControllers / CHUNK_SIZE);

      const processChunk = (chunkIndex) => {
        const start = chunkIndex * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, totalControllers);

        for (let i = start; i < end; i++) {
          const controller = layout.controllers[i];
          if (i > 0) doc.addPage();

          addControllerHeader(doc, controller, margin, logo);
          addFooter(doc);

          const portCount = controller.portCount || 48;
          const isDoubleColumn = portCount > 24;
          const body = generateTableBody(
            controller,
            layout.portMapping[controller.name],
            portCount
          );

          if (isDoubleColumn) {
            generateDoubleColumnTable(
              doc,
              body,
              portCount,
              margin,
              headerHeight,
              pageWidth,
              pageHeight,
              controller,
              logo
            );
          } else {
            generateSingleColumnTable(
              doc,
              body,
              margin,
              headerHeight,
              pageWidth,
              pageHeight,
              controller,
              logo
            );
          }

          progressCallback(((i + 1) / totalControllers) * 100);
        }

        if (chunkIndex < chunks - 1) {
          setTimeout(() => processChunk(chunkIndex + 1), 0);
        } else {
          if (returnBlob) {
            resolve(doc.output('blob'));
          } else {
            const pdfOutput = doc.output('blob');
            const url = URL.createObjectURL(pdfOutput);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            URL.revokeObjectURL(url);
            resolve();
          }
        }
      };

      processChunk(0);
    };

    logo.onerror = () => {
      reject(new Error('Failed to load logo image'));
    };
  });
};



function generateDoubleColumnTable(
  doc,
  body,
  portCount,
  margin,
  headerHeight,
  pageWidth,
  pageHeight,
  controller,
  logo
) {
  const midX = pageWidth / 2;
  const leftTableBody = [];
  const rightTableBody = [];

  const halfPortCount = Math.ceil(portCount / 2);

  for (let i = 0; i < halfPortCount; i++) {
    leftTableBody.push(body[i]);
  }

  for (let i = halfPortCount; i < portCount; i++) {
    rightTableBody.push(body[i]);
  }

  const tableOptionsLeft = getTableOptions(
    doc,
    margin,
    headerHeight,
    controller,
    logo,
    margin,
    midX - margin * 1.5
  );

  const tableOptionsRight = getTableOptions(
    doc,
    margin,
    headerHeight,
    controller,
    logo,
    midX + margin * 0.5,
    midX - margin * 1.5
  );

  doc.autoTable({
    ...tableOptionsLeft,
    body: leftTableBody,
  });

  doc.autoTable({
    ...tableOptionsRight,
    body: rightTableBody,
  });
}

function generateSingleColumnTable(
  doc,
  body,
  margin,
  headerHeight,
  pageWidth,
  pageHeight,
  controller,
  logo
) {
  const tableOptions = getTableOptions(
    doc,
    margin,
    headerHeight,
    controller,
    logo,
    margin,
    pageWidth - margin * 2
  );

  doc.autoTable({
    ...tableOptions,
    body: body,
  });
}

function getTableOptions(doc, margin, headerHeight, controller, logo, startX, tableWidth) {
  return {
    startY: margin + headerHeight + 5,
    margin: { left: startX, right: margin },
    head: [['Port', 'Total Pixels', 'Models']],
    theme: 'grid',
    styles: { fontSize: 8 },
    headStyles: { fillColor: [0, 51, 102], halign: 'center' },
    columnStyles: {
      0: { cellWidth: 15, halign: 'center' },
      1: { cellWidth: 20, halign: 'center' },
      2: { cellWidth: tableWidth - 35, halign: 'left' },
    },
    didDrawPage: (data) => {
      addFooter(doc);
    },
  };
}

function generateTableBody(controller, portMapping, portCount) {
  const body = [];
  for (let port = 1; port <= portCount; port++) {
    const models = portMapping[port] || [];
    const totalPixels = models.reduce(
      (sum, model) => sum + (model.pixelsOnThisPort || model.pixelCount || 0),
      0
    );

    // Sort models by Smart Receiver
    const sortedModels = models.sort((a, b) => {
      const smartReceiverA = a.controllerConnection?.smartRemote || '0';
      const smartReceiverB = b.controllerConnection?.smartRemote || '0';
      return parseInt(smartReceiverA) - parseInt(smartReceiverB);
    });

    let currentSmartReceiver = null;
    const modelNames = sortedModels.map((model) => {
      const smartReceiver = model.controllerConnection?.smartRemote;
      if (smartReceiver && smartReceiver !== currentSmartReceiver) {
        currentSmartReceiver = smartReceiver;
        return `Smart Remote ${String.fromCharCode(64 + parseInt(smartReceiver))}\n${formatModelName(model.name, model.pixelCount)}`;

      }
      return formatModelName(model.name, model.pixelCount);
    });
    body.push([`${port}`, `${totalPixels}`, modelNames.join('\n')]);
  }
  return body;
}

function formatModelName(name, pixels) {
  return `${name} (${pixels !== undefined ? pixels : 'unknown'})`;
}

// Keep all other existing functions unchanged
export const exportToDXF = (scene, camera, progressCallback) => {
  try {
    progressCallback(45, 'Starting DXF export');
    const d = new Drawing();
    const frustum = new THREE.Frustum();
    const projScreenMatrix = new THREE.Matrix4();
    projScreenMatrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
    frustum.setFromProjectionMatrix(projScreenMatrix);

    let minX = Infinity,
      minY = Infinity,
      maxX = -Infinity,
      maxY = -Infinity;
    let objectCount = 0;

    // First pass: collect all points and find min/max
    const points = [];

    scene.traverse((object) => {
      if (object instanceof THREE.Mesh && frustum.intersectsObject(object)) {
        objectCount++;

        const geometry = object.geometry;
        const position = object.position;
        const scale = object.scale;
        const rotation = object.rotation;

        if (geometry instanceof THREE.BufferGeometry) {
          const positions = geometry.attributes.position.array;
          for (let i = 0; i < positions.length; i += 3) {
            let x = positions[i];
            let y = positions[i + 1];
            let z = positions[i + 2];

            x *= scale.x;
            y *= scale.y;
            z *= scale.z;

            const rotatedPoint = new THREE.Vector3(x, y, z).applyEuler(rotation);
            rotatedPoint.add(position);

            minX = Math.min(minX, rotatedPoint.x);
            minY = Math.min(minY, rotatedPoint.y);
            maxX = Math.max(maxX, rotatedPoint.x);
            maxY = Math.max(maxY, rotatedPoint.y);

            points.push(rotatedPoint);
          }
        }
      }
    });

    // Scaling to A4
    const a4Width = 210; // mm
    const a4Height = 297; // mm
    const margin = 10;
    const availableWidth = a4Width - 2 * margin;
    const availableHeight = a4Height - 2 * margin;
    const scaleX = availableWidth / (maxX - minX || 1);
    const scaleY = availableHeight / (maxY - minY || 1);
    const scale = Math.min(scaleX, scaleY);

    // Now write scaled points into DXF
    points.forEach((point) => {
      const x = (point.x - minX) * scale + margin;
      const y = (point.y - minY) * scale + margin;
      d.drawPoint(x, y);
    });

    progressCallback(60, 'DXF export completed');
    return d.toDxfString();
  } catch (error) {
    console.error('Error in exportToDXF:', error);
    throw new Error(`Error exporting to DXF: ${error.message}`);
  }
};

const RenderSceneToImage = (scene, camera, progressCallback) => {
  try {
    progressCallback(65, 'Rendering scene to image');
    const Width = 1920;
    const Height = 1080;

    if (!scene || !camera) {
      throw new Error('Scene or camera is undefined');
    }

    // Store the original background
    const originalBackground = scene.background;
    scene.background = new THREE.Color(0xffffff);

    // Set scene background to null (transparent)
    scene.background = null;

    scene.updateMatrixWorld();
    camera.updateMatrixWorld();

    const Renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    Renderer.setSize(Width, Height);
    Renderer.setClearColor(0x000000, 0); // Transparent background

    Renderer.render(scene, camera);

    // Create a canvas to draw the rendered image with a white background
    const Canvas = document.createElement('canvas');
    Canvas.width = Width;
    Canvas.height = Height;
    const Context = Canvas.getContext('2d');

    // Fill the background with white
    Context.fillStyle = 'white';
    Context.fillRect(0, 0, Canvas.width, Canvas.height);

    // Draw the rendered image onto the canvas
    Context.drawImage(Renderer.domElement, 0, 0);

    // Get the final image data
    const ImageBase64 = Canvas.toDataURL('image/png');

    Renderer.dispose();

    // Restore the original scene background
    scene.background = originalBackground;

    progressCallback(70, 'Scene rendered to image');

    let dxfData = '';
    try {
      dxfData = exportToDXF(scene, camera, progressCallback);
    } catch (error) {
      console.error('Error exporting to DXF:', error);
    }

    return { ImageBase64, dxfData };
  } catch (error) {
    console.error('Error in RenderSceneToImage:', error);
    throw new Error(`Error rendering scene to image: ${error.message}`);
  }
};

export const generateDXFPDF = (
  scene,
  camera,
  layout,
  progressCallback = () => { },
  fileName = 'xlights_layout_visualization_dxf.pdf'
) => {
  return new Promise((resolve, reject) => {
    try {
      progressCallback(10, 'Initializing DXF PDF generation');
      const { ImageBase64, dxfData } = RenderSceneToImage(scene, camera, progressCallback);
      progressCallback(75, 'DXF data and image generated');

      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });

      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const margin = 10;

      doc.setFontSize(16);
      doc.setTextColor(0);
      doc.text('xLights Layout Visualization (DXF)', margin, margin + 10);

      const imgWidth = pageWidth - 2 * margin;
      const imgHeight = pageHeight - 4 * margin - 20;
      doc.addImage(ImageBase64, 'PNG', margin, margin + 20, imgWidth, imgHeight);

      progressCallback(80, 'Image added to PDF');

      // Informing the user about the DXF attachment
      doc.setFontSize(10);
      doc.setTextColor(0);
      doc.text(
        'Note: The DXF file of your layout is provided as a separate download.',
        margin,
        margin + imgHeight + 25,
        { maxWidth: imgWidth }
      );

      doc.setFontSize(8);
      doc.setTextColor(100);
      doc.text('Have you donated to xLights today?', pageWidth / 2, pageHeight - 5, {
        align: 'center',
      });

      const pdfBlob = doc.output('blob');

      // Initiate PDF download
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const pdfLink = document.createElement('a');
      pdfLink.href = pdfUrl;
      pdfLink.download = fileName;
      pdfLink.click();
      URL.revokeObjectURL(pdfUrl);

      // Initiate DXF download
      const dxfBlob = new Blob([dxfData], { type: 'application/dxf' });
      const dxfUrl = URL.createObjectURL(dxfBlob);
      const dxfLink = document.createElement('a');
      dxfLink.href = dxfUrl;
      dxfLink.download = 'layout.dxf';
      dxfLink.click();
      URL.revokeObjectURL(dxfUrl);

      progressCallback(100, 'PDF and DXF files saved and download initiated');
      resolve();
    } catch (error) {
      console.error('Error in generateDXFPDF:', error);
      reject(new Error(`Error generating DXF PDF: ${error.message}`));
    }
  });
};

// Update generatePDFWithoutLogo to use fileName
const generatePDFWithoutLogo = (doc, scene, camera, pageWidth, pageHeight, margin, fileName) => {
  // Add title
  doc.setFontSize(16);
  doc.setTextColor(0);
  doc.text('xLights Layout Visualization', margin, margin + 10);

  // Generate image from the scene
  const { ImageBase64 } = RenderSceneToImage(scene, camera, () => { });

  // Add image to PDF
  const imgWidth = pageWidth - 2 * margin;
  const imgHeight = pageHeight - 4 * margin - 20; // 20 for title and margins
  doc.addImage(ImageBase64, 'PNG', margin, margin + 20, imgWidth, imgHeight);

  // Add footer
  doc.setFontSize(8);
  doc.setTextColor(100);
  doc.text('Have you donated to xLights today?', pageWidth / 2, pageHeight - 5, {
    align: 'center',
  });

  // Save to downloads folder
  const pdfOutput = doc.output('blob');
  const url = URL.createObjectURL(pdfOutput);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(url);
};