import * as THREE from 'three';
import BaseModel from './BaseModel';

class CandyCaneModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.name = 'CandyCaneModel';
    this.parseModelData();
  }

  parseModelData() {
    const {
      parm1,
      parm2,
      parm3,
      Dir,
      StringType,
      CandyCaneHeight,
      CandyCaneReverse,
      CandyCaneSticks,
      AlternateNodes,
    } = this.modelData;
    this.numCanes = parseInt(parm1) || 1;
    this.segmentsPerCane = parseInt(parm2) || 1;
    this.lightsPerSegment = parseInt(parm3) || 1;
    this.isLtoR = Dir !== 'R';
    this.stringType = StringType || 'RGB';
    this.caneHeight = parseFloat(CandyCaneHeight) || 1.0;
    this.reverse = CandyCaneReverse === 'true';
    this.sticks = CandyCaneSticks === 'true';
    this.alternateNodes = AlternateNodes === 'true';
    this.singleNode = this.stringType === 'Single Color';

    console.log('CandyCane parameters:', {
      numCanes: this.numCanes,
      segmentsPerCane: this.segmentsPerCane,
      lightsPerSegment: this.lightsPerSegment,
      isLtoR: this.isLtoR,
      stringType: this.stringType,
      caneHeight: this.caneHeight,
      reverse: this.reverse,
      sticks: this.sticks,
      alternateNodes: this.alternateNodes,
      singleNode: this.singleNode,
    });
  }

  createGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    const caneGap = 2.0;
    const widthPerCane = (this.segmentsPerCane * this.lightsPerSegment * 3.0) / 9.0;
    const width = this.numCanes * widthPerCane + (this.numCanes - 1) * caneGap;
    const height = this.segmentsPerCane * this.lightsPerSegment - widthPerCane / 2.0;

    const angle = THREE.MathUtils.degToRad(this.modelData.Angle || 0);

    const totalLights = this.numCanes * this.segmentsPerCane * this.lightsPerSegment;
    let currentLight = 0;

    for (let cane = 0; cane < this.numCanes; cane++) {
      currentLight = this.createCaneGeometry(
        cane,
        widthPerCane,
        caneGap,
        angle,
        positions,
        colors,
        currentLight,
        totalLights
      );
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    return geometry;
  }

  createCaneGeometry(
    cane,
    widthPerCane,
    caneGap,
    angle,
    positions,
    colors,
    currentLight,
    totalLights
  ) {
    const lightsPerCane = this.segmentsPerCane * this.lightsPerSegment;
    const upright = Math.floor((lightsPerCane * 6) / 9);
    const x = cane * (widthPerCane + caneGap) + (this.reverse ? widthPerCane : 0);

    if (this.sticks) {
      return this.createStickGeometry(cane, x, angle, positions, colors, currentLight, totalLights);
    } else {
      return this.createCurvedCaneGeometry(
        cane,
        x,
        widthPerCane,
        upright,
        angle,
        positions,
        colors,
        currentLight,
        totalLights
      );
    }
  }

  createStickGeometry(cane, x, angle, positions, colors, currentLight, totalLights) {
    for (let light = 0; light < this.segmentsPerCane * this.lightsPerSegment; light++) {
      let y;
      if (this.alternateNodes) {
        if (light + 1 <= (this.segmentsPerCane + 1) / 2) {
          y = this.caneHeight * 2 * light;
        } else {
          y = this.caneHeight * ((this.segmentsPerCane - (light + 1)) * 2 + 1);
        }
      } else {
        y = this.caneHeight * light;
      }
      const [rotatedX, rotatedY] = this.rotatePoint(x, y, angle);
      positions.push(rotatedX, rotatedY, 0);

      if (currentLight === 0) {
        colors.push(0, 1, 1); // Teal color for the first light
      } else if (currentLight === totalLights - 1) {
        colors.push(0.5, 0, 0); // Dull red color for the last light
      } else {
        colors.push(1, 1, 1); // White color for all other lights
      }

      currentLight++;
    }
    return currentLight;
  }

  createCurvedCaneGeometry(
    cane,
    x,
    widthPerCane,
    upright,
    angle,
    positions,
    colors,
    currentLight,
    totalLights
  ) {
    const lightsPerCane = this.segmentsPerCane * this.lightsPerSegment;
    const arclights = lightsPerCane - upright;
    const cx = x + (this.reverse ? -widthPerCane / 2 : widthPerCane / 2);

    // Upright part
    for (let light = 0; light < upright; light++) {
      let y;
      if (this.alternateNodes) {
        if (light + 1 <= (upright + 1) / 2) {
          y = this.caneHeight * 2 * light;
        } else {
          y = this.caneHeight * ((upright - (light + 1)) * 2 + 1);
        }
      } else {
        y = this.caneHeight * light;
      }
      const [rotatedX, rotatedY] = this.rotatePoint(x, y, angle);
      positions.push(rotatedX, rotatedY, 0);

      if (currentLight === 0) {
        colors.push(0, 1, 1); // Teal color for the first light
      } else if (currentLight === totalLights - 1) {
        colors.push(0.5, 0, 0); // Dull red color for the last light
      } else {
        colors.push(1, 1, 1); // White color for all other lights
      }

      currentLight++;
    }

    // Curved part
    for (let light = 0; light < arclights; light++) {
      const arcAngle = Math.PI - (Math.PI * (light + 1)) / arclights;
      const y = (upright - 1) * this.caneHeight + (Math.sin(arcAngle) * widthPerCane) / 2;
      const xOffset = (Math.cos(arcAngle) * widthPerCane) / 2;
      const curveX = this.reverse ? cx - xOffset : cx + xOffset;
      const [rotatedX, rotatedY] = this.rotatePoint(curveX, y, angle);
      positions.push(rotatedX, rotatedY, 0);

      if (currentLight === 0) {
        colors.push(0, 1, 1); // Teal color for the first light
      } else if (currentLight === totalLights - 1) {
        colors.push(0.5, 0, 0); // Dull red color for the last light
      } else {
        colors.push(1, 1, 1); // White color for all other lights
      }

      currentLight++;
    }

    return currentLight;
  }

  rotatePoint(x, y, angle) {
    const rotatedX = x * Math.cos(angle) - y * Math.sin(angle);
    const rotatedY = x * Math.sin(angle) + y * Math.cos(angle);
    return [rotatedX, rotatedY];
  }

  createMesh() {
    const geometry = this.createGeometry();
    const material = new THREE.PointsMaterial({
      size: parseFloat(this.modelData.PixelSize) || 2,
      sizeAttenuation: false,
      vertexColors: true,
    });

    const points = new THREE.Points(geometry, material);
    return this.applyTransformations(points);
  }

  applyTransformations(object) {
    const { worldPos } = this.modelData.screenLocation;
    object.position.set(worldPos.x, worldPos.y, worldPos.z);

    // Apply scaling here based on the number of canes
    const scale = 6.0 / this.numCanes;
    object.scale.set(scale, scale, scale);

    return object;
  }

  getDimension() {
    // This method mimics the C++ GetDimension method
    return 6.0 / this.numCanes;
  }
}

export default CandyCaneModel;
