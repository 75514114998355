import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo } from '../../utils/logger';

class StarModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.name = 'StarModel';
    this.firstPixelPosition = null;
    this.parseModelData();
  }

  parseModelData() {
    const { parm1, parm2, parm3, starStartLocation, starLayerSizes, starRatio, starCenterPercent } =
      this.modelData;
    this.numStrings = parseInt(parm1) || 1;
    this.totalLights = parseInt(parm2) || 20;
    this.numPoints = Math.max(parseInt(parm3) || 5, 2);
    this.starStartLocation = starStartLocation || 'Bottom Ctr-CW';
    this.layerSizes = Array.isArray(starLayerSizes) ? starLayerSizes : [this.totalLights];
    this.starRatio = parseFloat(starRatio) || 2.618034;
    this.innerPercent = parseInt(starCenterPercent) || -1;
    this.singleNode = this.modelData.StringType === 'Single Color';

    logInfo('Parsed Star parameters:', this);
  }

  createGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];
    const sizes = [];

    const bufferSize = this.calculateBufferSize();
    const outerRadius = bufferSize / 4;
    const layerCount = this.layerSizes.length;
    const pointAngleGap = (Math.PI * 2.0) / this.numPoints;
    const directionUnit = this.starStartLocation.includes('-CCW') ? -1.0 : 1.0;
    const startAngle = this.getStartAngle();
    const starSegments = 2 * this.numPoints;

    let layerRadiusDelta = 0;
    if (layerCount > 1) {
      if (this.innerPercent === -1) {
        this.innerPercent = 100.0 / layerCount;
      }
      layerRadiusDelta = (outerRadius * (100.0 - this.innerPercent)) / (100.0 * (layerCount - 1.0));
    }

    let currentNode = 0;

    if (!this.singleNode) {
      for (let layer = layerCount - 1; layer >= 0; layer--) {
        const layerLights = this.layerSizes[layer];
        const layerRadius = outerRadius - (layerCount - 1 - layer) * layerRadiusDelta;
        const innerRadius = layerRadius / this.starRatio;

        let curAngle = startAngle;
        let startOuter = !this.starStartLocation.includes('Bottom Ctr');

        const totalSegmentLength =
          starSegments * this.getSegmentLength(layerRadius, innerRadius, pointAngleGap);
        const coordGap = totalSegmentLength / layerLights;

        for (let lightIndex = 0; lightIndex < layerLights; lightIndex++) {
          const segmentIndex = Math.floor((lightIndex * starSegments) / layerLights);
          const segmentT = ((lightIndex * starSegments) / layerLights) % 1;

          const isOuter = (segmentIndex % 2 === 0) === startOuter;
          const radius = isOuter ? layerRadius : innerRadius;
          const nextRadius = isOuter ? innerRadius : layerRadius;

          const angleStart = curAngle + (segmentIndex * pointAngleGap * directionUnit) / 2.0;
          const angleEnd = angleStart + (pointAngleGap * directionUnit) / 2.0;

          const start = this.getPointOnCircle(radius, angleStart);
          const end = this.getPointOnCircle(nextRadius, angleEnd);

          const point = this.getPositionOnLine(start, end, segmentT);

          positions.push(point.x, point.y, 0);

          if (currentNode === 0) {
            colors.push(0, 1, 1); // Teal color for the first node
            sizes.push(parseFloat(this.modelData.pixelSize) || 2);
            this.firstPixelPosition = { x: point.x, y: point.y };
          } else if (currentNode === this.totalLights - 1) {
            colors.push(0.5, 0, 0); // Dull red color for the last node
            sizes.push(parseFloat(this.modelData.pixelSize) || 2);
          } else {
            colors.push(1, 1, 1); // White color for other nodes
            sizes.push(parseFloat(this.modelData.pixelSize) || 2);
          }

          currentNode++;
        }
      }
    } else {
      // Single node logic
      positions.push(0, 0, 0);
      colors.push(0, 1, 1); // Teal color for the single node
      sizes.push(parseFloat(this.modelData.pixelSize) || 2);
      this.firstPixelPosition = { x: 0, y: 0 };
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1));

    logInfo('Total nodes created:', currentNode);

    return geometry;
  }

  getStartAngle() {
    if (this.starStartLocation.includes('Top')) {
      return Math.PI / 2;
    } else if (this.starStartLocation.includes('Bottom')) {
      return -Math.PI / 2;
    } else if (this.starStartLocation.includes('Left')) {
      return Math.PI;
    } else {
      return 0;
    }
  }

  getPointOnCircle(radius, angle) {
    return {
      x: radius * Math.cos(angle),
      y: radius * Math.sin(angle),
    };
  }

  getPositionOnLine(start, end, t) {
    return {
      x: start.x + (end.x - start.x) * t,
      y: start.y + (end.y - start.y) * t,
    };
  }

  getSegmentLength(outerRadius, innerRadius, angle) {
    const start = this.getPointOnCircle(outerRadius, 0);
    const end = this.getPointOnCircle(innerRadius, angle / 2);
    return Math.sqrt(Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2));
  }

  calculateBufferSize() {
    let maxLightsOnLayer = 0;
    this.layerSizes.forEach((layerSize, index) => {
      const layersOutside = this.layerSizes.length - index - 1;
      maxLightsOnLayer = Math.max(
        maxLightsOnLayer,
        1 + Math.floor(layerSize * (1.0 + layersOutside / this.layerSizes.length))
      );
    });
    return maxLightsOnLayer * 2;
  }

  createMesh() {
    const geometry = this.createGeometry();
    const material = new THREE.PointsMaterial({
      size: parseFloat(this.modelData.pixelSize) || 2,
      sizeAttenuation: false,
      vertexColors: true,
      transparent: true,
      opacity: 1,
      alphaTest: 0.5,
      depthWrite: false,
    });

    const points = new THREE.Points(geometry, material);
    const group = new THREE.Group();
    group.add(points);

    return this.applyTransformations(group);
  }

  applyTransformations(object) {
    const { worldPos, width, height, depth } = this.modelData.screenLocation;
    object.position.set(worldPos.x, worldPos.y, worldPos.z);

    if (this.modelData.rotation) {
      object.rotation.set(
        THREE.MathUtils.degToRad(this.modelData.rotation.x),
        THREE.MathUtils.degToRad(this.modelData.rotation.y),
        THREE.MathUtils.degToRad(this.modelData.rotation.z)
      );
    }

    object.scale.set(width, height, depth);

    return object;
  }

  setWorldScale(scale) {
    this.worldScale = scale;
  }
}

export default StarModel;
