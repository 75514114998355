import * as THREE from 'three';
import DmxModel from './DmxModel';

class DmxMotor extends DmxModel {
    constructor(modelData) {
        super(modelData);
        this.displayAs = "DmxMotor";
        this.channelCoarse = modelData.channelCoarse || 0;
        this.channelFine = modelData.channelFine || 0;
        this.minLimit = modelData.minLimit || -180;
        this.maxLimit = modelData.maxLimit || 180;
        this.rangeOfMotion = modelData.rangeOfMotion || 180;
        this.orientZero = modelData.orientZero || 0;
        this.orientHome = modelData.orientHome || 0;
        this.slewLimit = modelData.slewLimit || 0;
        this.reverse = modelData.reverse || false;
        this.upsideDown = modelData.upsideDown || false;
        this.currentPosition = 0;
        this.rev = this.reverse ? -1 : 1;
    }

    createMesh() {
        const group = new THREE.Group();

        // Create the base of the motor
        const baseGeometry = new THREE.CylinderGeometry(0.2, 0.2, 0.1, 32);
        const baseMaterial = new THREE.MeshPhongMaterial({ color: 0x888888 });
        const baseMesh = new THREE.Mesh(baseGeometry, baseMaterial);
        group.add(baseMesh);

        // Create the rotating part of the motor
        const rotorGeometry = new THREE.BoxGeometry(0.3, 0.3, 0.2);
        const rotorMaterial = new THREE.MeshPhongMaterial({ color: 0x444444 });
        this.rotorMesh = new THREE.Mesh(rotorGeometry, rotorMaterial);
        this.rotorMesh.position.y = 0.15;
        group.add(this.rotorMesh);

        // Create an arrow to show the motor's direction
        const arrowGeometry = new THREE.ConeGeometry(0.1, 0.2, 32);
        const arrowMaterial = new THREE.MeshPhongMaterial({ color: 0xff0000 });
        this.arrowMesh = new THREE.Mesh(arrowGeometry, arrowMaterial);
        this.arrowMesh.position.y = 0.35;
        this.arrowMesh.rotation.x = Math.PI / 2;
        group.add(this.arrowMesh);

        return group;
    }

    updateFromDmxValues(dmxValues) {
        super.updateFromDmxValues(dmxValues);

        if (this.channelCoarse > 0 && this.channelCoarse <= dmxValues.length) {
            let position = dmxValues[this.channelCoarse - 1];
            if (this.channelFine > 0 && this.channelFine <= dmxValues.length) {
                position = (position * 256 + dmxValues[this.channelFine - 1]) / 65535 * this.rangeOfMotion;
            } else {
                position = position / 255 * this.rangeOfMotion;
            }
            this.setPosition(position);
        }
    }

    setPosition(position) {
        let limitedPos = Math.max(this.minLimit, Math.min(this.maxLimit, position));
        if (this.upsideDown) {
            limitedPos = -limitedPos;
        }
        this.currentPosition = limitedPos;

        if (this.rotorMesh && this.arrowMesh) {
            const rotation = THREE.MathUtils.degToRad(this.currentPosition * this.rev + this.orientZero);
            this.rotorMesh.rotation.y = rotation;
            this.arrowMesh.rotation.y = rotation;
        }
    }

    convertPosToCmd(position) {
        let limitedPos = Math.max(this.minLimit, Math.min(this.maxLimit, position));
        if (this.upsideDown) {
            limitedPos = -limitedPos;
        }
        const gotoHome = this.maxValue * this.orientHome / this.rangeOfMotion;
        const amountToMove = this.maxValue * limitedPos / this.rangeOfMotion * this.rev;
        let cmd = gotoHome + amountToMove;
        const fullSpin = this.maxValue * 360 / this.rangeOfMotion;

        if (cmd < 0) {
            cmd = cmd + fullSpin < this.maxValue ? cmd + fullSpin : 0;
        } else if (cmd > this.maxValue) {
            cmd = cmd - fullSpin >= 0 ? cmd - fullSpin : this.maxValue;
        }
        return cmd;
    }

    getPosition(channelValue) {
        return ((1 - (channelValue - this.minValue) / (this.maxValue - this.minValue)) * (this.rev * this.rangeOfMotion) - (this.rev * this.rangeOfMotion));
    }

    onPropertyChange(propertyName, value) {
        switch (propertyName) {
            case 'channelCoarse':
            case 'channelFine':
            case 'minLimit':
            case 'maxLimit':
            case 'rangeOfMotion':
            case 'orientZero':
            case 'orientHome':
            case 'slewLimit':
                this[propertyName] = Number(value);
                return true;
            case 'reverse':
            case 'upsideDown':
                this[propertyName] = Boolean(value);
                this.rev = this.reverse ? -1 : 1;
                return true;
            default:
                return super.onPropertyChange(propertyName, value);
        }
    }

    getProperties() {
        return {
            ...super.getProperties(),
            channelCoarse: this.channelCoarse,
            channelFine: this.channelFine,
            minLimit: this.minLimit,
            maxLimit: this.maxLimit,
            rangeOfMotion: this.rangeOfMotion,
            orientZero: this.orientZero,
            orientHome: this.orientHome,
            slewLimit: this.slewLimit,
            reverse: this.reverse,
            upsideDown: this.upsideDown
        };
    }
}

export default DmxMotor;