import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const TermsAndConditions = () => (
  <Container maxWidth="md">
    <Box my={4}>
      <Typography variant="h4" component="h1" gutterBottom>
        Terms and Conditions
      </Typography>
      <Typography paragraph>
        Welcome to xLights.rock. By using our services, you agree to these terms. Please read them
        carefully.
      </Typography>
      <Typography paragraph>Last updated: August 1, 2023</Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        Use of Our Services
      </Typography>
      <Typography paragraph>
        Our services are provided for personal, non-commercial use. Commercial use requires prior
        written approval. You agree to use our services only as permitted by law and these terms. We
        may suspend or stop providing our services to you if you do not comply with our terms or
        policies or if we are investigating suspected misconduct.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        Your xLights.rock Account
      </Typography>
      <Typography paragraph>
        You may need an account to use some of our services. You are responsible for maintaining the
        confidentiality of your account and password. You agree to notify us immediately of any
        unauthorized use of your account.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        Privacy and Copyright Protection
      </Typography>
      <Typography paragraph>
        Our privacy policy explains how we treat your personal data and protect your privacy when
        you use our services. By using our services, you agree that xLights.rock can use such data
        in accordance with our privacy policy.
      </Typography>
      <Typography paragraph>
        We respond to notices of alleged copyright infringement and terminate accounts of repeat
        infringers according to the process set out in the U.S. Digital Millennium Copyright Act.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        Your Content in Our Services
      </Typography>
      <Typography paragraph>
        Our services allow you to upload, submit, store, send or receive content. You retain
        ownership of any intellectual property rights that you hold in that content. When you
        upload, submit, store, send or receive content to or through our services, you give
        xLights.rock a worldwide license to use, host, store, reproduce, modify, create derivative
        works, communicate, publish, publicly perform, publicly display and distribute such content.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        Modifying and Terminating Our Services
      </Typography>
      <Typography paragraph>
        We are constantly changing and improving our services. We may add or remove functionalities
        or features, and we may suspend or stop a service altogether. You can stop using our
        services at any time, although we'll be sorry to see you go. xLights.rock may also stop
        providing services to you, or add or create new limits to our services at any time.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        Our Warranties and Disclaimers
      </Typography>
      <Typography paragraph>
        We provide our services using a commercially reasonable level of skill and care and we hope
        that you will enjoy using them. But there are certain things that we don't promise about our
        services. OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OR ADDITIONAL TERMS, NEITHER
        XLIGHTS.ROCK NOR ITS SUPPLIERS OR DISTRIBUTORS MAKE ANY SPECIFIC PROMISES ABOUT THE
        SERVICES.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        Liability for Our Services
      </Typography>
      <Typography paragraph>
        WHEN PERMITTED BY LAW, XLIGHTS.ROCK, AND XLIGHTS.ROCK'S SUPPLIERS AND DISTRIBUTORS, WILL NOT
        BE RESPONSIBLE FOR LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL,
        CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        Business Uses of Our Services
      </Typography>
      <Typography paragraph>
        If you are using our services on behalf of a business, that business accepts these terms. It
        will hold harmless and indemnify xLights.rock and its affiliates, officers, agents, and
        employees from any claim, suit or action arising from or related to the use of the services
        or violation of these terms, including any liability or expense arising from claims, losses,
        damages, suits, judgments, litigation costs and attorneys' fees.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        About These Terms
      </Typography>
      <Typography paragraph>
        We may modify these terms or any additional terms that apply to a service to, for example,
        reflect changes to the law or changes to our services. You should look at the terms
        regularly. We'll post notice of modifications to these terms on this page. Changes will not
        apply retroactively and will become effective no sooner than fourteen days after they are
        posted. However, changes addressing new functions for a service or changes made for legal
        reasons will be effective immediately.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        Contact Us
      </Typography>
      <Typography paragraph>
        If you have any questions about these Terms, please contact us at terms@xlights.rock.
      </Typography>
    </Box>
  </Container>
);

export default TermsAndConditions;
