import * as THREE from 'three';
import DmxMotor from '../DmxMotor';

class DmxPanTiltAbility {
    constructor(modelData) {
        this.panMotor = new DmxMotor({
            ...modelData.panMotor,
            channelCoarse: modelData.panChannelCoarse,
            channelFine: modelData.panChannelFine,
        });

        this.tiltMotor = new DmxMotor({
            ...modelData.tiltMotor,
            channelCoarse: modelData.tiltChannelCoarse,
            channelFine: modelData.tiltChannelFine,
        });

        this.panRotation = 0;
        this.tiltRotation = 0;
    }

    updateFromDmxValues(dmxValues) {
        this.panMotor.updateFromDmxValues(dmxValues);
        this.tiltMotor.updateFromDmxValues(dmxValues);

        this.panRotation = THREE.MathUtils.degToRad(this.panMotor.currentPosition);
        this.tiltRotation = THREE.MathUtils.degToRad(this.tiltMotor.currentPosition);
    }

    applyToObject(object) {
        object.rotation.y = this.panRotation;
        object.rotation.x = this.tiltRotation;
    }

    onPropertyChange(propertyName, value) {
        switch (propertyName) {
            case 'panMotor':
            case 'tiltMotor':
                this[propertyName].onPropertyChange(value.property, value.value);
                return true;
            default:
                return false;
        }
    }

    getProperties() {
        return {
            panMotor: this.panMotor.getProperties(),
            tiltMotor: this.tiltMotor.getProperties(),
        };
    }

    getPanRotation() {
        return this.panRotation;
    }

    getTiltRotation() {
        return this.tiltRotation;
    }

    // Helper method to create a visual representation of pan/tilt limits
    createLimitVisual() {
        const geometry = new THREE.BufferGeometry();
        const material = new THREE.LineBasicMaterial({ color: 0xffff00 });

        const panRange = this.panMotor.maxLimit - this.panMotor.minLimit;
        const tiltRange = this.tiltMotor.maxLimit - this.tiltMotor.minLimit;

        const points = [];
        const segments = 32;

        for (let i = 0; i <= segments; i++) {
            const panAngle = (i / segments) * panRange + this.panMotor.minLimit;
            const x = Math.sin(THREE.MathUtils.degToRad(panAngle));
            const z = Math.cos(THREE.MathUtils.degToRad(panAngle));

            points.push(new THREE.Vector3(x, 0, z));
            points.push(new THREE.Vector3(x, Math.sin(THREE.MathUtils.degToRad(this.tiltMotor.maxLimit)), z));
        }

        for (let i = 0; i <= segments; i++) {
            const tiltAngle = (i / segments) * tiltRange + this.tiltMotor.minLimit;
            const y = Math.sin(THREE.MathUtils.degToRad(tiltAngle));

            const panMin = THREE.MathUtils.degToRad(this.panMotor.minLimit);
            const panMax = THREE.MathUtils.degToRad(this.panMotor.maxLimit);

            points.push(new THREE.Vector3(Math.sin(panMin), y, Math.cos(panMin)));
            points.push(new THREE.Vector3(Math.sin(panMax), y, Math.cos(panMax)));
        }

        geometry.setFromPoints(points);
        return new THREE.LineSegments(geometry, material);
    }
}

export default DmxPanTiltAbility;