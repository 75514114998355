import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo, logWarning, logError } from '../../utils/logger';

class ModelGroup extends BaseModel {
    constructor(modelData) {
        super(modelData);
        this.name = 'ModelGroup';
        this.models = [];
        this.parseModelData();
    }

    parseModelData() {
        const { models } = this.modelData;
        this.models = models || [];

        logInfo('ModelGroup parameters:', {
            modelCount: this.models.length,
        });
    }

    createMesh() {
        const group = new THREE.Group();

        this.models.forEach(modelData => {
            const modelMesh = this.createModelMesh(modelData);
            if (modelMesh) {
                group.add(modelMesh);
            }
        });

        return this.applyTransformations(group);
    }

    createModelMesh(modelData) {
        // This is a placeholder. In a real implementation, you would use
        // the ModelRenderer to create the appropriate model mesh.
        const geometry = new THREE.BoxGeometry(1, 1, 1);
        const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
        return new THREE.Mesh(geometry, material);
    }

    applyTransformations(object) {
        const { worldPos } = this.modelData.screenLocation;
        object.position.set(worldPos.x, worldPos.y, worldPos.z);
        return object;
    }

    getDimension() {
        // For a group, we might want to calculate the bounding box of all contained models
        // This is a simplified version
        return 1;
    }
}

export default ModelGroup;