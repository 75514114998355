import * as THREE from 'three';
import DmxSkull from './DmxSkull';
import DmxServo from './DmxServo';

class DmxSkulltronix extends DmxSkull {
    constructor(modelData) {
        super(modelData);
        this.displayAs = "DmxSkulltronix";

        // Additional features
        this.eyebrowServo = new DmxServo({
            channel: modelData.eyebrowChannel || 0,
            minAngle: -30,
            maxAngle: 30,
        });

        this.neckServo = new DmxServo({
            channel: modelData.neckChannel || 0,
            minAngle: -45,
            maxAngle: 45,
        });

        this.ledChannel = modelData.ledChannel || 0;
        this.ledColor = new THREE.Color(1, 1, 1);
        this.ledIntensity = 0;

        this.soundChannel = modelData.soundChannel || 0;
        this.currentSound = 0; // 0 = off, 1-255 for different sounds
    }

    createMesh() {
        const group = super.createMesh();

        // Add eyebrows
        const eyebrowGeometry = new THREE.BoxGeometry(0.3, 0.05, 0.05);
        const eyebrowMaterial = new THREE.MeshPhongMaterial({ color: 0x333333 });
        this.leftEyebrowMesh = new THREE.Mesh(eyebrowGeometry, eyebrowMaterial);
        this.rightEyebrowMesh = new THREE.Mesh(eyebrowGeometry, eyebrowMaterial);
        this.leftEyebrowMesh.position.set(-0.2, 0.3, 0.4);
        this.rightEyebrowMesh.position.set(0.2, 0.3, 0.4);
        group.add(this.leftEyebrowMesh);
        group.add(this.rightEyebrowMesh);

        // Add LED strips
        const ledGeometry = new THREE.TorusGeometry(0.45, 0.02, 16, 100);
        const ledMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, emissive: 0xffffff });
        this.ledMesh = new THREE.Mesh(ledGeometry, ledMaterial);
        this.ledMesh.rotation.x = Math.PI / 2;
        group.add(this.ledMesh);

        // Modify the group to allow for neck movement
        this.neckGroup = new THREE.Group();
        this.neckGroup.add(group);

        return this.neckGroup;
    }

    updateFromDmxValues(dmxValues) {
        super.updateFromDmxValues(dmxValues);

        // Update eyebrows
        this.eyebrowServo.updateFromDmxValues(dmxValues);

        // Update neck
        this.neckServo.updateFromDmxValues(dmxValues);

        // Update LED strips
        if (this.ledChannel > 0 && this.ledChannel <= dmxValues.length) {
            const ledDmxValue = dmxValues[this.ledChannel - 1];
            this.updateLEDs(ledDmxValue);
        }

        // Update sound
        if (this.soundChannel > 0 && this.soundChannel <= dmxValues.length) {
            this.currentSound = dmxValues[this.soundChannel - 1];
            this.playSound();
        }

        this.updateAdvancedMesh();
    }

    updateLEDs(dmxValue) {
        this.ledIntensity = dmxValue / 255;

        // Change LED color based on DMX value (example: full spectrum)
        const hue = (dmxValue / 255);
        this.ledColor.setHSL(hue, 1, 0.5);
    }

    playSound() {
        // This method would trigger sound effects based on this.currentSound
        // Implementation would depend on the audio system being used
        console.log(`Playing sound effect: ${this.currentSound}`);
    }

    updateAdvancedMesh() {
        super.updateMesh();

        if (this.mesh) {
            // Update eyebrow position
            const eyebrowRotation = THREE.MathUtils.degToRad(this.eyebrowServo.currentAngle);
            this.leftEyebrowMesh.rotation.x = eyebrowRotation;
            this.rightEyebrowMesh.rotation.x = eyebrowRotation;

            // Update neck position
            const neckRotation = THREE.MathUtils.degToRad(this.neckServo.currentAngle);
            this.neckGroup.rotation.y = neckRotation;

            // Update LED strips
            const ledEmissiveIntensity = this.ledIntensity;
            this.ledMesh.material.emissive.copy(this.ledColor).multiplyScalar(ledEmissiveIntensity);
        }
    }

    onPropertyChange(propertyName, value) {
        switch (propertyName) {
            case 'eyebrowChannel':
                this.eyebrowServo.onPropertyChange('channel', value);
                return true;
            case 'neckChannel':
                this.neckServo.onPropertyChange('channel', value);
                return true;
            case 'ledChannel':
            case 'soundChannel':
                this[propertyName] = parseInt(value);
                return true;
            default:
                return super.onPropertyChange(propertyName, value);
        }
    }

    getProperties() {
        return {
            ...super.getProperties(),
            eyebrowChannel: this.eyebrowServo.channel,
            neckChannel: this.neckServo.channel,
            ledChannel: this.ledChannel,
            soundChannel: this.soundChannel,
        };
    }
}

export default DmxSkulltronix;