import * as THREE from 'three';
import DmxModel from '../DmxModel';

class DmxColorAbility extends DmxModel {
    constructor(modelData) {
        super(modelData);
        this.color = new THREE.Color(1, 1, 1); // Default to white
    }

    createMesh() {
        // Create a circular disc to represent the color filter
        const geometry = new THREE.CircleGeometry(0.5, 32);
        const material = new THREE.MeshBasicMaterial({ color: this.color, side: THREE.DoubleSide });
        const mesh = new THREE.Mesh(geometry, material);

        return mesh;
    }

    updateColor(r, g, b) {
        this.color.setRGB(r, g, b);
        if (this.mesh) {
            this.mesh.material.color = this.color;
        }
    }

    // Method to update the color based on DMX values
    updateFromDmxValues(dmxValues) {
        // Assume dmxValues is an array of DMX channel values (0-255)
        // For simplicity, let's assume the first 3 channels control R, G, B
        const r = dmxValues[0] / 255;
        const g = dmxValues[1] / 255;
        const b = dmxValues[2] / 255;
        this.updateColor(r, g, b);
    }
}

export default DmxColorAbility;