import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo, logWarning, logError } from '../../utils/logger';

class PolyLineModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.name = 'PolyLineModel';
    this.worldScale = 1;
    logInfo('PolyLineModel constructor - Raw model data:', JSON.stringify(modelData));
    this.parseModelData();
  }

  parseModelData() {
    try {
      this.pointData = this.modelData.pointData || '';
      this.cPointData = this.modelData.cPointData || '';
      this.pixelSize = parseFloat(this.modelData.pixelSize) || 2;
      this.parm1 = Math.max(1, parseInt(this.modelData.parm1) || 1);
      this.parm2 = Math.max(1, parseInt(this.modelData.parm2) || 50);
      this.parm3 = Math.max(1, parseInt(this.modelData.parm3) || 1);
      this.indivSegs = Array.isArray(this.modelData.segments) &&
        this.modelData.segments.length > 0 &&
        this.modelData.segments.every(seg => Number.isInteger(seg) && seg > 0);
      this.segments = this.indivSegs ? this.modelData.segments : [];
      this.polyLineSizes = [];
      this.polyLineSegDropSizes = [];
      this.polyLeadOffset = [];
      this.polyTrailOffset = [];
      this.polyGapSize = [];
      this.dropPattern = this.modelData.dropPattern ? this.modelData.dropPattern.split(',').map(Number) : [1];
      this.numDropPoints = 0;
      this.worldPos = this.modelData.screenLocation?.worldPos || { x: 0, y: 0, z: 0 };
      this.rotation = this.modelData.rotation || { x: 0, y: 0, z: 0 };
      this.scale = {
        x: parseFloat(this.modelData.screenLocation?.width) || 1,
        y: parseFloat(this.modelData.screenLocation?.height) || 1,
        z: parseFloat(this.modelData.screenLocation?.depth) || 1
      };
      this.height = parseFloat(this.modelData.ModelHeight) || 1.0;
      this.alternateNodes = this.modelData.AlternateNodes === "true";
      this.individualStartChannels = this.modelData.advanced === true;
      this.strings = Math.max(1, parseInt(this.modelData.PolyStrings) || 1);

      this.points = this.getPoints();
      this.numPoints = this.points.length;
      this.num_segments = Math.max(0, this.numPoints - 1);

      this.initializeSegments();
      this.initializeOffsets();

      this.curves = this.getCurves();
      this.calculateSegmentDistribution();

      if (this.individualStartChannels) {
        this.stringStartChannels = this.parseStringStartChannels();
      }

      this.validateModelData();

      logInfo('PolyLineModel parsed data:', {
        pointData: this.pointData,
        cPointData: this.cPointData,
        numPoints: this.numPoints,
        parm1: this.parm1,
        parm2: this.parm2,
        parm3: this.parm3,
        indivSegs: this.indivSegs,
        segments: this.segments,
        dropPattern: this.dropPattern,
        polyLineSizes: this.polyLineSizes,
        polyLineSegDropSizes: this.polyLineSegDropSizes,
        numDropPoints: this.numDropPoints,
        worldPos: this.worldPos,
        rotation: this.rotation,
        scale: this.scale,
        alternateNodes: this.alternateNodes,
        individualStartChannels: this.individualStartChannels,
        strings: this.strings
      });
    } catch (error) {
      logError('Error in parseModelData:', error);
      throw new Error('Failed to parse model data: ' + error.message);
    }
  }

  initializeSegments() {
    if (this.indivSegs) {
      this.polyLineSizes = this.segments.map(size => size * this.parm3);
    } else {
      const totalLights = this.parm2 * this.parm3;
      const avgSegmentSize = Math.floor(totalLights / this.num_segments);
      this.segments = Array(this.num_segments).fill(avgSegmentSize);
      let remainingLights = totalLights - (avgSegmentSize * this.num_segments);
      for (let i = 0; i < remainingLights; i++) {
        this.segments[i % this.num_segments]++;
      }
      this.polyLineSizes = this.segments;
    }
  }

  initializeOffsets() {
    for (let x = 0; x <= this.num_segments; x++) {
      const cornerAttr = this.modelData[`Corner${x + 1}`] || "Neither";
      if (x === 0) {
        this.polyLeadOffset.push(cornerAttr === "Leading Segment" ? 1.0 : cornerAttr === "Trailing Segment" ? 0.0 : 0.5);
      } else if (x === this.num_segments) {
        this.polyTrailOffset.push(cornerAttr === "Leading Segment" ? 0.0 : cornerAttr === "Trailing Segment" ? 1.0 : 0.5);
      } else {
        this.polyTrailOffset.push(cornerAttr === "Leading Segment" ? 0.0 : cornerAttr === "Trailing Segment" ? 1.0 : 0.5);
        this.polyLeadOffset.push(cornerAttr === "Leading Segment" ? 1.0 : cornerAttr === "Trailing Segment" ? 0.0 : 0.5);
      }
    }
  }

  validateModelData() {
    if (this.numPoints < 2) {
      throw new Error('Invalid number of points. At least 2 points are required.');
    }
    if (this.parm2 < 1) {
      throw new Error('Invalid parm2 value. Must be at least 1.');
    }
    if (this.parm3 < 1) {
      throw new Error('Invalid parm3 value. Must be at least 1.');
    }
    if (this.strings < 1) {
      throw new Error('Invalid number of strings. Must be at least 1.');
    }
  }

  parseStringStartChannels() {
    const startChannels = [];
    for (let i = 0; i < this.strings; i++) {
      const channelAttr = `PolyString${i + 1}`;
      const startChannel = Math.max(1, parseInt(this.modelData[channelAttr]) || 1);
      startChannels.push(startChannel);
    }
    return startChannels;
  }

  calculateSegmentDistribution() {
    try {
      let numLights = 0;
      this.numDropPoints = 0;
      const lights_per_node = this.parm3;
      let drop_index = 0;

      if (this.indivSegs) {
        for (let x = 0; x < this.segments.length; x++) {
          const num_drop_points_this_segment = this.polyLineSizes[x];
          let drop_lights_this_segment = 0;
          for (let z = 0; z < num_drop_points_this_segment; z++) {
            drop_lights_this_segment += Math.abs(this.dropPattern[drop_index++]);
            drop_index %= this.dropPattern.length;
          }
          numLights += drop_lights_this_segment;
          this.numDropPoints += num_drop_points_this_segment;
          this.polyLineSegDropSizes[x] = drop_lights_this_segment;
        }
        this.parm2 = numLights;
      } else {
        let lights = this.parm2 * this.parm3;
        while (lights > 0) {
          const lights_this_drop = Math.abs(this.dropPattern[drop_index++]);
          numLights += lights_this_drop;
          drop_index %= this.dropPattern.length;
          this.numDropPoints++;
          lights -= lights_this_drop;
        }
        if (numLights !== this.parm2 * this.parm3) {
          this.parm2 = Math.ceil(numLights / this.parm3);
        }
      }

      this.totalLights = numLights;

      // Calculate polyGapSize
      for (let x = 0; x < this.num_segments; x++) {
        const num_gaps = this.polyLeadOffset[x] + this.polyTrailOffset[x] + this.polyLineSizes[x] - 1.0;
        this.polyGapSize[x] = this.polyLineSizes[x] / num_gaps;
      }
    } catch (error) {
      logError('Error in calculateSegmentDistribution:', error);
      throw new Error('Failed to calculate segment distribution: ' + error.message);
    }
  }

  getPoints() {
    if (!this.pointData) {
      logError('No point data available');
      return [];
    }

    const rawPoints = this.pointData.split(',').map(val => parseFloat(val.trim()));
    const points = [];
    for (let i = 0; i < rawPoints.length; i += 3) {
      const x = rawPoints[i];
      const y = rawPoints[i + 1];
      const z = rawPoints[i + 2];

      if (!isNaN(x) && !isNaN(y) && !isNaN(z)) {
        points.push(new THREE.Vector3(x, y, z));
      } else {
        logError(`Invalid point data at index ${i}: ${rawPoints[i]}, ${rawPoints[i + 1]}, ${rawPoints[i + 2]}`);
      }
    }

    return points;
  }

  getCurves() {
    if (!this.cPointData) {
      return [];
    }

    const rawCurveData = this.cPointData.split(',').map(val => parseFloat(val.trim()));
    const curves = [];

    for (let i = 0; i < rawCurveData.length; i += 7) {
      const segmentIndex = Math.floor(rawCurveData[i]);
      const cp0 = new THREE.Vector3(rawCurveData[i + 1], rawCurveData[i + 2], rawCurveData[i + 3]);
      const cp1 = new THREE.Vector3(rawCurveData[i + 4], rawCurveData[i + 5], rawCurveData[i + 6]);

      curves.push({
        segmentIndex,
        cp0,
        cp1
      });
    }

    return curves;
  }

  createGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    if (this.points.length < 2) {
      logError('Not enough valid points to create geometry');
      return geometry;
    }

    if (this.indivSegs) {
      this.distributeLightsAcrossIndivSegments(positions, colors);
    } else {
      this.distributeLightsEvenly(positions, colors);
    }

    if (positions.length === 0) {
      logError('No valid positions generated');
      return geometry;
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    // Log the first and last few color values
    logInfo('First 3 colors:', colors.slice(0, 9));
    logInfo('Last 3 colors:', colors.slice(-9));

    return geometry;
  }

  distributeLightsEvenly(positions, colors) {
    const totalLength = this.calculateTotalLength();
    const totalLights = this.parm2 * this.parm3;
    const avgDistance = totalLength / (this.parm2 - 1);
    let currentDistance = 0;
    let segmentIndex = 0;
    let segmentDistance = this.getSegmentLength(0);

    for (let i = 0; i < this.parm2; i++) {
      while (currentDistance > segmentDistance && segmentIndex < this.num_segments - 1) {
        currentDistance -= segmentDistance;
        segmentIndex++;
        segmentDistance = this.getSegmentLength(segmentIndex);
      }

      const t = segmentDistance > 0 ? currentDistance / segmentDistance : 0;
      const position = this.getPointOnSegment(segmentIndex, t);

      this.addNodeToGeometry(position, positions, colors, i, this.parm2);

      currentDistance += avgDistance;
    }
  }

  distributeLightsAcrossIndivSegments(positions, colors) {
    let currentLight = 0;
    const totalLights = this.parm2 * this.parm3;

    // Add the first point
    this.addNodeToGeometry(this.points[0], positions, colors, 0, this.parm2);
    currentLight += this.parm3;

    for (let i = 0; i < this.segments.length; i++) {
      const startPoint = this.points[i];
      const endPoint = this.points[i + 1];
      const lightsInThisSegment = this.segments[i] * this.parm3;

      for (let j = 1; j < lightsInThisSegment; j++) {
        const t = j / lightsInThisSegment;
        const position = new THREE.Vector3().lerpVectors(startPoint, endPoint, t);
        this.addLightToGeometry(position, positions, colors, currentLight, totalLights);
        currentLight++;
      }

      // Add the end point of the segment (which is the start point of the next segment)
      if (i < this.segments.length - 1) {
        this.addNodeToGeometry(endPoint, positions, colors, currentLight / this.parm3, this.parm2);
        currentLight += this.parm3;
      }
    }

    // Ensure we've created exactly parm2 * parm3 lights
    while (currentLight < totalLights) {
      this.addLightToGeometry(this.points[this.points.length - 1], positions, colors, currentLight, totalLights);
      currentLight++;
    }
  }

  addNodeToGeometry(position, positions, colors, nodeIndex, totalNodes) {
    for (let i = 0; i < this.parm3; i++) {
      this.addLightToGeometry(position, positions, colors, nodeIndex * this.parm3 + i, totalNodes * this.parm3);
    }
  }

  addLightToGeometry(position, positions, colors, lightIndex, totalLights) {
    const color = this.getLightColor(lightIndex, totalLights);
    positions.push(position.x, position.y, position.z);
    colors.push(color.r, color.g, color.b);

    // Log only for the first and last few lights
    if (lightIndex < 3 || lightIndex >= totalLights - 3) {
      logInfo(`Light ${lightIndex}: position (${position.x}, ${position.y}, ${position.z}), color (${color.r}, ${color.g}, ${color.b})`);
    }
  }

  getLightColor(lightIndex, totalLights) {
    if (lightIndex < this.parm3) {
      return new THREE.Color(0, 1, 1); // Cyan color for the first parm3 lights
    } else if (lightIndex >= totalLights - this.parm3) {
      return new THREE.Color(1, 0, 0); // Bright Red for the last parm3 lights
    } else {
      return new THREE.Color(1, 1, 1); // White for all other lights
    }
  }


  getPointOnCurve(p0, p1, cp0, cp1, t) {
    const t2 = t * t;
    const t3 = t2 * t;
    const mt = 1 - t;
    const mt2 = mt * mt;
    const mt3 = mt2 * mt;

    return new THREE.Vector3(
      p0.x * mt3 + 3 * cp0.x * mt2 * t + 3 * cp1.x * mt * t2 + p1.x * t3,
      p0.y * mt3 + 3 * cp0.y * mt2 * t + 3 * cp1.y * mt * t2 + p1.y * t3,
      p0.z * mt3 + 3 * cp0.z * mt2 * t + 3 * cp1.z * mt * t2 + p1.z * t3
    );
  }

  getSegmentLength(index) {
    const curve = this.curves.find(c => c.segmentIndex === index);
    if (curve) {
      // Approximate curve length (you may want to implement a more accurate method)
      const steps = 10;
      let length = 0;
      let prevPoint = this.points[index];
      for (let i = 1; i <= steps; i++) {
        const t = i / steps;
        const point = this.getPointOnCurve(
          this.points[index],
          this.points[index + 1],
          curve.cp0,
          curve.cp1,
          t
        );
        length += prevPoint.distanceTo(point);
        prevPoint = point;
      }
      return length;
    } else {
      return this.points[index].distanceTo(this.points[index + 1]);
    }
  }

  calculateTotalLength() {
    let totalLength = 0;
    for (let i = 0; i < this.num_segments; i++) {
      totalLength += this.getSegmentLength(i);
    }
    return totalLength;
  }

  getPointOnSegment(segmentIndex, t) {
    const curve = this.curves.find(c => c.segmentIndex === segmentIndex);
    if (curve) {
      return this.getPointOnCurve(
        this.points[segmentIndex],
        this.points[segmentIndex + 1],
        curve.cp0,
        curve.cp1,
        t
      );
    } else {
      return new THREE.Vector3().lerpVectors(
        this.points[segmentIndex],
        this.points[segmentIndex + 1],
        t
      );
    }
  }

  createMesh() {
    const geometry = this.createGeometry();
    const material = new THREE.PointsMaterial({
      size: this.pixelSize,
      vertexColors: true,
      sizeAttenuation: false,
    });

    const points = new THREE.Points(geometry, material);
    return this.applyTransformations(points);
  }

  applyTransformations(object) {
    object.position.set(
      this.worldPos.x * this.worldScale,
      this.worldPos.y * this.worldScale,
      this.worldPos.z * this.worldScale
    );

    object.rotation.set(
      THREE.MathUtils.degToRad(this.rotation.x),
      THREE.MathUtils.degToRad(this.rotation.y),
      THREE.MathUtils.degToRad(this.rotation.z)
    );

    object.scale.set(
      this.scale.x * this.worldScale,
      this.scale.y * this.worldScale,
      this.scale.z * this.worldScale
    );

    return object;
  }
}

export default PolyLineModel;