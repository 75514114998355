class DmxShutterAbility {
    constructor(modelData) {
        this.channel = modelData.channel || 0;
        this.openThreshold = modelData.openThreshold || 128; // DMX value above which the shutter is considered open
        this.strobeThreshold = modelData.strobeThreshold || 200; // DMX value above which strobe effect starts
        this.isOpen = false;
        this.isStrobing = false;
        this.strobeSpeed = 0;
    }

    updateFromDmxValues(dmxValues) {
        if (this.channel > 0 && this.channel <= dmxValues.length) {
            const dmxValue = dmxValues[this.channel - 1];
            this.updateShutterState(dmxValue);
        }
    }

    updateShutterState(dmxValue) {
        if (dmxValue >= this.strobeThreshold) {
            this.isOpen = true;
            this.isStrobing = true;
            this.strobeSpeed = (dmxValue - this.strobeThreshold) / (255 - this.strobeThreshold);
        } else if (dmxValue >= this.openThreshold) {
            this.isOpen = true;
            this.isStrobing = false;
        } else {
            this.isOpen = false;
            this.isStrobing = false;
        }
    }

    getShutterState() {
        return {
            isOpen: this.isOpen,
            isStrobing: this.isStrobing,
            strobeSpeed: this.strobeSpeed
        };
    }

    onPropertyChange(propertyName, value) {
        switch (propertyName) {
            case 'channel':
                this.channel = parseInt(value);
                return true;
            case 'openThreshold':
                this.openThreshold = parseInt(value);
                return true;
            case 'strobeThreshold':
                this.strobeThreshold = parseInt(value);
                return true;
            default:
                return false;
        }
    }

    getProperties() {
        return {
            channel: this.channel,
            openThreshold: this.openThreshold,
            strobeThreshold: this.strobeThreshold
        };
    }

    // Method to be called by the renderer to determine if the light should be visible
    shouldRender(time) {
        if (!this.isOpen) {
            return false;
        }
        if (this.isStrobing) {
            // Simple strobe effect: alternate visibility based on time and strobe speed
            const strobeFrequency = 5 + this.strobeSpeed * 20; // Range from 5 to 25 Hz
            return Math.sin(time * Math.PI * strobeFrequency) > 0;
        }
        return true;
    }
}

export default DmxShutterAbility;