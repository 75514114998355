export const parseMatrixModel = (modelData) => {
  const { parm1, parm2, parm3, displayAs } = modelData;
  const strings = parseInt(parm1) || 1;
  const lightsPerString = parseInt(parm2) || 50;
  const strands = parseInt(parm3) || 1;
  const points = {};
  let nodeNumber = 1;
  const isVertical = displayAs === 'Vert Matrix';
  for (let string = 0; string < strings; string++) {
    for (let light = 0; light < lightsPerString; light++) {
      const x = isVertical ? string : light;
      const y = isVertical ? light : string;
      if (!points[nodeNumber]) {
        points[nodeNumber] = [];
      }
      points[nodeNumber].push({ x, y });
      nodeNumber++;
    }
  }
  return {
    rows: isVertical ? lightsPerString : strings,
    cols: isVertical ? strings : lightsPerString,
    points,
  };
};
