// GridlinesObject.js
import * as THREE from 'three';

class GridlinesObject {
    constructor(modelData) {
        this.modelData = modelData;
        this.line_spacing = parseInt(modelData.GridLineSpacing) || 25;
        this.width = parseInt(modelData.GridWidth) || 3000;
        this.height = parseInt(modelData.GridHeight) || 1000;
        this.gridColor = new THREE.Color(modelData.Active === '1' ? '#AAAAAA' : '#888888');
        this.grid = null;
    }

    createGrid(scene) {
        if (this.grid) {
            scene.remove(this.grid);
        }

        const gridHelper = new THREE.GridHelper(this.width, this.width / this.line_spacing, this.gridColor, this.gridColor);

        // Adjust the position based on the worldPos from modelData
        gridHelper.position.set(
            this.modelData.worldPos.x,
            this.modelData.worldPos.y,
            this.modelData.worldPos.z
        );

        // Apply rotation
        gridHelper.rotation.set(
            THREE.MathUtils.degToRad(this.modelData.rotate.x),
            THREE.MathUtils.degToRad(this.modelData.rotate.y),
            THREE.MathUtils.degToRad(this.modelData.rotate.z)
        );

        // Apply scale
        gridHelper.scale.set(
            this.modelData.scale.x,
            this.modelData.scale.y,
            this.modelData.scale.z
        );

        this.grid = gridHelper;
        scene.add(this.grid);
    }

    updateGrid(scene) {
        this.createGrid(scene);
    }
}

export default GridlinesObject;