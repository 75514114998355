import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo, logWarning, logError } from '../../utils/logger';

class TreeModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.parseModelData();
  }

  parseModelData() {
    const {
      parm1,
      parm2,
      parm3,
      displayAs,
      treeRotation,
      treeSpiralRotations,
      treeBottomTopRatio,
      treePerspective,
      dir,
      startSide,
    } = this.modelData;

    this.strings = parseInt(parm1) || 1;
    this.lightsPerString = parseInt(parm2) || 50;
    this.strandsPerString = parseInt(parm3) || 1;
    this.lightsPerStrand = Math.floor(this.lightsPerString / this.strandsPerString);

    const displayMatch = displayAs ? displayAs.match(/Tree\s+(\d+|Flat|Ribbon)/) : null;
    this.treeType = displayMatch ? displayMatch[1] : (displayAs && displayAs.includes('Tree') ? '360' : 'Unknown');

    if (this.treeType === 'Flat') {
      this.degrees = 0;
    } else if (this.treeType === 'Ribbon') {
      this.degrees = -1;
    } else {
      this.degrees = Math.min(parseInt(this.treeType) || 360, 360);
    }

    this.rotation = parseFloat(treeRotation) || 0;
    this.spiralRotations = parseFloat(treeSpiralRotations) || 0;

    // Handle TreeBottomTopRatio, including the case where it's 0
    this.botTopRatio = treeBottomTopRatio !== undefined && treeBottomTopRatio !== '' ? parseFloat(treeBottomTopRatio) : 6.0;

    this.perspective = parseFloat(treePerspective) || 0.2;
    this.isLtoR = dir !== 'R';
    this.isBotToTop = startSide === 'B';

    this.totalLights = this.strings * this.lightsPerString;

    logInfo('Tree parameters:', {
      name: this.modelData.name,
      type: this.treeType,
      strings: this.strings,
      lightsPerString: this.lightsPerString,
      strandsPerString: this.strandsPerString,
      lightsPerStrand: this.lightsPerStrand,
      degrees: this.degrees,
      botTopRatio: this.botTopRatio,
      spiralRotations: this.spiralRotations,
      rotation: this.rotation,
      perspective: this.perspective,
      totalLights: this.totalLights,
    });
  }

  createGeometry() {
    try {
      let geometry;
      if (this.degrees === 0) {
        geometry = this.createFlatTreeGeometry();
      } else if (this.degrees === -1) {
        geometry = this.createRibbonTreeGeometry();
      } else {
        geometry = this.createRoundTreeGeometry();
      }

      this.validateGeometry(geometry);
      return geometry;
    } catch (error) {
      logError(`Error creating geometry for ${this.modelData.name}:`, error);
      return this.createFallbackGeometry();
    }
  }

  validateGeometry(geometry) {
    const positions = geometry.attributes.position.array;
    for (let i = 0; i < positions.length; i++) {
      if (isNaN(positions[i])) {
        logError(`NaN value found in position array for ${this.modelData.name} at index ${i}`);
        throw new Error('Invalid geometry: NaN values in position array');
      }
    }
  }

  createRoundTreeGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    const totalStrands = this.strings * this.strandsPerString;
    const renderHt = this.lightsPerStrand * 3;
    const renderWi = renderHt / 1.8;

    const radians = THREE.MathUtils.degToRad(this.degrees);
    const radius = renderWi / 2.0;
    let topRadius = radius;
    if (this.botTopRatio !== 0) {
      topRadius = radius / Math.abs(this.botTopRatio);
    }
    if (this.botTopRatio < 0) {
      [topRadius, radius] = [radius, topRadius];
    }

    let startAngle = -radians / 2.0;
    const angleIncr = totalStrands > 0
      ? (this.degrees < 350 && totalStrands > 1
        ? radians / (totalStrands - 1)
        : radians / totalStrands)
      : 0;

    startAngle += THREE.MathUtils.degToRad(this.rotation);

    const yPos = this.calculateYPositions();

    const ytop = renderHt;
    const ybot = 0;

    let lightCount = 0;

    for (let s = 0; s < totalStrands; s++) {
      const angle = startAngle + s * angleIncr;
      for (let l = 0; l < this.lightsPerStrand; l++) {
        const posOnString = yPos[l] / (this.lightsPerStrand - 1);
        const r = radius + (topRadius - radius) * posOnString;

        let finalAngle = angle;
        if (this.spiralRotations !== 0) {
          finalAngle += posOnString * this.spiralRotations * Math.PI * 2;
        }

        const x = r * Math.sin(finalAngle);
        const z = r * Math.cos(finalAngle);
        const y = ybot + (ytop - ybot) * posOnString - renderHt / 2.0;

        if (isNaN(x) || isNaN(y) || isNaN(z)) {
          logError(`NaN value calculated for ${this.modelData.name}: x=${x}, y=${y}, z=${z}`);
          logError(`Parameters: r=${r}, finalAngle=${finalAngle}, posOnString=${posOnString}`);
          throw new Error('NaN values calculated for position');
        }

        positions.push(x, y, z);

        if (lightCount === 0) {
          colors.push(0, 1, 1); // Teal color for the first light
        } else if (lightCount === this.totalLights - 1) {
          colors.push(0.5, 0, 0); // Dull red color for the last light
        } else {
          colors.push(1, 1, 1); // White color for all other lights
        }

        lightCount++;
      }
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    return geometry;
  }

  createFlatTreeGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    const renderHt = this.lightsPerString * 2.0;
    const botWid = this.strings * 4;
    const topWid = botWid * this.topWidth;

    let lightCount = 0;

    for (let s = 0; s < this.strings; s++) {
      for (let l = 0; l < this.lightsPerString; l++) {
        const posOnString = l / (this.lightsPerString - 1);
        const x = (botWid + (topWid - botWid) * posOnString) * (s / (this.strings - 1) - 0.5);
        const y = renderHt * posOnString - renderHt / 2;

        positions.push(x, y, 0);

        if (lightCount === 0) {
          colors.push(0, 1, 1); // Teal color for the first light
        } else if (lightCount === this.totalLights - 1) {
          colors.push(0.5, 0, 0); // Dull red color for the last light
        } else {
          colors.push(1, 1, 1); // White color for all other lights
        }

        lightCount++;
      }
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    return geometry;
  }

  createRibbonTreeGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    const renderHt = this.lightsPerString * 2.0;
    const botWid = this.strings * 5;
    const topWid = botWid * this.topWidth;

    let lightCount = 0;

    for (let s = 0; s < this.strings; s++) {
      for (let l = 0; l < this.lightsPerString; l++) {
        const posOnString = l / (this.lightsPerString - 1);
        const x = (botWid + (topWid - botWid) * posOnString) * (s / (this.strings - 1) - 0.5);
        const y = renderHt * posOnString - renderHt / 2;

        const h = Math.sqrt(renderHt * renderHt + x * x);
        const newh = renderHt * posOnString;
        const z = (renderHt * newh) / h - renderHt / 2;

        positions.push(x, y, z);

        if (lightCount === 0) {
          colors.push(0, 1, 1); // Teal color for the first light
        } else if (lightCount === this.totalLights - 1) {
          colors.push(0.5, 0, 0); // Dull red color for the last light
        } else {
          colors.push(1, 1, 1); // White color for all other lights
        }

        lightCount++;
      }
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    return geometry;
  }

  calculateYPositions() {
    const yPos = new Array(this.lightsPerStrand).fill(0);

    if (this.spiralRotations === 0) {
      for (let i = 0; i < this.lightsPerStrand; i++) {
        yPos[i] = i;
      }
    } else {
      const segments = 10;
      const lengths = new Array(segments).fill(0);
      const radius = 0.5;
      const topRadius = radius / Math.abs(this.botTopRatio);
      const rgap = (radius - topRadius) / segments;
      let total = 0;

      for (let x = 0; x < segments; x++) {
        lengths[x] = 2.0 * Math.PI * (radius - rgap * x);
        lengths[x] *= this.spiralRotations / segments;
        lengths[x] = Math.sqrt(
          lengths[x] * lengths[x] + Math.pow(this.lightsPerStrand / segments, 2)
        );
        total += lengths[x];
      }

      let curSeg = 0;
      let lightsInSeg = Math.round((lengths[0] * this.lightsPerStrand) / total);
      let curLightInSeg = 0;

      for (let x = 0; x < this.lightsPerStrand; x++) {
        if (curLightInSeg >= lightsInSeg) {
          curSeg++;
          curLightInSeg = 0;
          if (curSeg === segments - 1) {
            lightsInSeg = this.lightsPerStrand - x;
          } else {
            lightsInSeg = Math.round((lengths[curSeg] * this.lightsPerStrand) / total);
          }
        }
        yPos[x] = x;
        curLightInSeg++;
      }
    }

    return yPos;
  }

  createMesh() {
    try {
      const geometry = this.createGeometry();
      const material = new THREE.PointsMaterial({
        size: parseFloat(this.modelData.pixelSize) || 2,
        sizeAttenuation: false,
        vertexColors: true,
      });

      const points = new THREE.Points(geometry, material);

      const box = new THREE.Box3().setFromObject(points);
      const size = box.getSize(new THREE.Vector3());

      if (size.x === 0 && size.y === 0 && size.z === 0) {
        logWarning(`Invalid mesh size for ${this.modelData.name}. Using fallback.`);
        return this.createFallbackMesh();
      }

      return this.applyTransformations(points);
    } catch (error) {
      logError(`Error creating mesh for ${this.modelData.name}:`, error);
      return this.createFallbackMesh();
    }
  }

  applyTransformations(object) {
    const { worldPos } = this.modelData.screenLocation;
    object.position.set(
      worldPos.x * this.worldScale,
      worldPos.y * this.worldScale,
      worldPos.z * this.worldScale
    );

    if (this.modelData.rotation) {
      object.rotation.set(
        THREE.MathUtils.degToRad(this.modelData.rotation.x),
        THREE.MathUtils.degToRad(this.modelData.rotation.y),
        THREE.MathUtils.degToRad(this.modelData.rotation.z)
      );
    }

    const { width, height, depth } = this.modelData.screenLocation;
    object.scale.set(width * this.worldScale, height * this.worldScale, depth * this.worldScale);

    logInfo('Created Tree mesh:', {
      name: this.modelData.name,
      type: this.treeType,
      degrees: this.degrees,
      spiralRotations: this.spiralRotations,
      totalStrands: this.strings * this.strandsPerString,
      lightsPerStrand: this.lightsPerStrand,
      vertices: object.geometry.attributes.position.count,
      position: object.position,
      scale: object.scale,
      rotation: object.rotation,
    });

    return object;
  }

  createFallbackGeometry() {
    logWarning(`Creating fallback geometry for ${this.modelData.name}`);
    return new THREE.SphereGeometry(1, 32, 32);
  }

  createFallbackMesh() {
    logWarning(`Creating fallback mesh for ${this.modelData.name}`);
    const geometry = this.createFallbackGeometry();
    const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    return new THREE.Mesh(geometry, material);
  }
}

export default TreeModel;
