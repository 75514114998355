import * as THREE from 'three';
import DmxFloodlight from './DmxFloodlight';

class DmxFloodArea extends DmxFloodlight {
    constructor(modelData) {
        super(modelData);
        this.displayAs = "DmxFloodArea";
    }

    createMesh() {
        const group = new THREE.Group();

        // Create the base of the flood area light
        const baseGeometry = new THREE.CylinderGeometry(0.2, 0.3, 0.1, 32);
        const baseMaterial = new THREE.MeshPhongMaterial({ color: 0x888888 });
        const baseMesh = new THREE.Mesh(baseGeometry, baseMaterial);
        group.add(baseMesh);

        // Create the light cone
        const coneGeometry = new THREE.ConeGeometry(0.5, 1, 32);
        const coneMaterial = new THREE.MeshPhongMaterial({
            color: 0xffffff,
            transparent: true,
            opacity: 0.5,
            side: THREE.DoubleSide
        });
        const coneMesh = new THREE.Mesh(coneGeometry, coneMaterial);
        coneMesh.position.y = 0.5;
        coneMesh.rotation.x = Math.PI;
        group.add(coneMesh);

        // Add a point light to simulate the light output
        const light = new THREE.PointLight(0xffffff, 1, 2);
        light.position.set(0, 0, 0);
        group.add(light);

        this.coneMesh = coneMesh;
        this.light = light;

        return group;
    }

    updateFromDmxValues(dmxValues) {
        super.updateFromDmxValues(dmxValues);

        // Update the color and intensity of the light cone and point light
        if (this.coneMesh && this.light) {
            const color = new THREE.Color(this.color.r, this.color.g, this.color.b);
            this.coneMesh.material.color = color;
            this.light.color = color;
            this.light.intensity = this.intensity;
        }
    }

    drawModel(center, edge, beamRatio) {
        if (this.mesh) {
            const beamLength = beamRatio / 2;

            // Update the cone geometry
            const newConeGeometry = new THREE.ConeGeometry(0.5, beamLength, 32);
            this.coneMesh.geometry.dispose();
            this.coneMesh.geometry = newConeGeometry;

            // Update materials
            const centerColor = new THREE.Color(center.r / 255, center.g / 255, center.b / 255);
            const edgeColor = new THREE.Color(edge.r / 255, edge.g / 255, edge.b / 255);

            this.coneMesh.material.color = centerColor;
            this.light.color = centerColor;

            // Create a custom shader material for gradient effect
            const gradientMaterial = new THREE.ShaderMaterial({
                uniforms: {
                    centerColor: { value: centerColor },
                    edgeColor: { value: edgeColor }
                },
                vertexShader: `
                    varying vec2 vUv;
                    void main() {
                        vUv = uv;
                        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                    }
                `,
                fragmentShader: `
                    uniform vec3 centerColor;
                    uniform vec3 edgeColor;
                    varying vec2 vUv;
                    void main() {
                        float t = length(vUv - vec2(0.5, 0.5)) * 2.0;
                        gl_FragColor = vec4(mix(centerColor, edgeColor, t), 1.0 - t);
                    }
                `,
                transparent: true,
                side: THREE.DoubleSide
            });

            this.coneMesh.material = gradientMaterial;
        }
    }
}

export default DmxFloodArea;