import * as THREE from 'three';
import DmxModel from '../DmxModel';

class DmxColorAbilityWheel extends DmxModel {
    constructor(modelData) {
        super(modelData);
        this.wheelChannel = modelData.wheelChannel || 0;
        this.dimmerChannel = modelData.dimmerChannel || 0;
        this.wheelDelay = modelData.wheelDelay || 0;
        this.colors = modelData.colors || [
            { color: new THREE.Color(1, 1, 1), dmxValue: 10 },
            { color: new THREE.Color(1, 0, 0), dmxValue: 30 },
            { color: new THREE.Color(0, 1, 0), dmxValue: 50 },
            { color: new THREE.Color(0, 0, 1), dmxValue: 70 }
        ];
        this.currentColor = new THREE.Color(1, 1, 1);
    }

    createMesh() {
        const geometry = new THREE.CircleGeometry(0.5, 32);
        const material = new THREE.MeshBasicMaterial({
            color: this.currentColor,
            side: THREE.DoubleSide
        });
        const mesh = new THREE.Mesh(geometry, material);

        // Create color wheel segments
        const wheelGroup = new THREE.Group();
        const segmentCount = this.colors.length;
        const segmentAngle = (Math.PI * 2) / segmentCount;

        this.colors.forEach((colorData, index) => {
            const segmentGeometry = new THREE.CircleGeometry(0.5, 32, index * segmentAngle, segmentAngle);
            const segmentMaterial = new THREE.MeshBasicMaterial({
                color: colorData.color,
                side: THREE.DoubleSide
            });
            const segment = new THREE.Mesh(segmentGeometry, segmentMaterial);
            wheelGroup.add(segment);
        });

        wheelGroup.position.z = 0.01; // Slightly in front of the main disc
        mesh.add(wheelGroup);

        return mesh;
    }

    updateFromDmxValues(dmxValues) {
        if (this.wheelChannel > 0) {
            const wheelValue = dmxValues[this.wheelChannel - 1];
            this.currentColor = this.getWheelColorFromDMXValue(wheelValue);
        }

        if (this.dimmerChannel > 0) {
            const dimmerValue = dmxValues[this.dimmerChannel - 1] / 255;
            this.currentColor.multiplyScalar(dimmerValue);
        }

        if (this.mesh) {
            this.mesh.material.color = this.currentColor;
        }
    }

    getWheelColorFromDMXValue(dmxValue) {
        let selectedColor = this.colors[0].color;
        let minDiff = 255;

        for (const colorData of this.colors) {
            const diff = Math.abs(colorData.dmxValue - dmxValue);
            if (diff < minDiff) {
                minDiff = diff;
                selectedColor = colorData.color;
            }
        }

        return selectedColor;
    }

    getBeamColor() {
        return this.currentColor;
    }

    isColorChannel(channel) {
        return channel === this.wheelChannel || channel === this.dimmerChannel;
    }

    getNumChannels() {
        return [this.wheelChannel, this.dimmerChannel].filter(channel => channel > 0).length;
    }

    setColors(colors) {
        this.colors = colors;
        this.updateWheelSegments();
    }

    updateWheelSegments() {
        if (this.mesh) {
            // Remove old segments
            while (this.mesh.children.length > 0) {
                this.mesh.remove(this.mesh.children[0]);
            }

            // Create new segments
            const wheelGroup = new THREE.Group();
            const segmentCount = this.colors.length;
            const segmentAngle = (Math.PI * 2) / segmentCount;

            this.colors.forEach((colorData, index) => {
                const segmentGeometry = new THREE.CircleGeometry(0.5, 32, index * segmentAngle, segmentAngle);
                const segmentMaterial = new THREE.MeshBasicMaterial({
                    color: colorData.color,
                    side: THREE.DoubleSide
                });
                const segment = new THREE.Mesh(segmentGeometry, segmentMaterial);
                wheelGroup.add(segment);
            });

            wheelGroup.position.z = 0.01;
            this.mesh.add(wheelGroup);
        }
    }
}

export default DmxColorAbilityWheel;