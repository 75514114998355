import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo, logWarning, logError } from '../../utils/logger';

class CircleModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.name = 'CircleModel';
    this.firstPixelPosition = null;
    logInfo('CircleModel constructor called', modelData);
    this.parseModelData();
  }

  parseModelData() {
    const {
      parm1, parm2, parm3, Dir, StringType, StartSide, InsideOut,
      WorldPosX, WorldPosY, WorldPosZ,
      ScaleX, ScaleY, ScaleZ,
      RotateX, RotateY, RotateZ,
      PixelSize
    } = this.modelData;

    this.numStrings = parseInt(parm1) || 1;
    this.lightsPerString = parseInt(parm2) || 50;
    this.centerPercent = parseInt(parm3) || 50;
    this.isLtoR = Dir !== 'R';
    this.isBotToTop = StartSide === 'B';
    this.stringType = StringType || 'RGB Nodes';
    this.insideOut = InsideOut === '1';
    this.singleNode = this.stringType === 'Single Color';
    this.totalLights = this.numStrings * this.lightsPerString;

    this.worldPos = new THREE.Vector3(
      parseFloat(WorldPosX) || 0,
      parseFloat(WorldPosY) || 0,
      parseFloat(WorldPosZ) || 0
    );
    this.scale = new THREE.Vector3(
      parseFloat(ScaleX) || 1,
      parseFloat(ScaleY) || 1,
      parseFloat(ScaleZ) || 1
    );
    this.rotation = new THREE.Euler(
      THREE.MathUtils.degToRad(parseFloat(RotateX) || 0),
      THREE.MathUtils.degToRad(parseFloat(RotateY) || 0),
      THREE.MathUtils.degToRad(parseFloat(RotateZ) || 0)
    );
    this.pixelSize = parseFloat(PixelSize) || 2;

    logInfo('Parsed Circle parameters:', {
      numStrings: this.numStrings,
      lightsPerString: this.lightsPerString,
      centerPercent: this.centerPercent,
      isLtoR: this.isLtoR,
      isBotToTop: this.isBotToTop,
      worldPos: this.worldPos.toArray(),
      scale: this.scale.toArray(),
      rotation: this.rotation.toArray(),
      pixelSize: this.pixelSize
    });
  }

  createGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];
    const sizes = [];

    const maxLights = this.lightsPerString;
    const maxRadius = maxLights / 2;
    const minRadius = (this.centerPercent / 100) * maxRadius;

    let node = 0;
    for (let circle = 0; circle < this.numStrings; circle++) {
      const radius = (this.numStrings === 1) ? maxRadius :
        this.insideOut ?
          minRadius + (maxRadius - minRadius) * (1 - (this.numStrings - circle - 1) / (this.numStrings - 1)) :
          minRadius + (maxRadius - minRadius) * (1 - circle / (this.numStrings - 1));

      for (let n = 0; n < this.lightsPerString; n++) {
        const angle = (this.isBotToTop ? -Math.PI : 0) + Math.PI * (n / this.lightsPerString) * 2;
        const adjustedAngle = this.isLtoR ? angle : -angle;

        const x = Math.sin(adjustedAngle) * radius;
        const y = -Math.cos(adjustedAngle) * radius;

        positions.push(x, y, 0);

        if (node === 0) {
          colors.push(0, 1, 1); // Teal color for the first light
          this.firstPixelPosition = { x, y };
        } else if (node === this.totalLights - 1) {
          colors.push(0.5, 0, 0); // Dull red color for the last light
        } else {
          colors.push(1, 1, 1); // White color for all other lights
        }

        sizes.push(this.pixelSize);
        node++;
      }
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1));

    logInfo('Total nodes created:', this.totalLights);
    logInfo('Geometry bounding box:', geometry.computeBoundingBox());
    return geometry;
  }

  createMesh() {
    try {
      const geometry = this.createGeometry();
      const material = new THREE.PointsMaterial({
        size: this.pixelSize,
        sizeAttenuation: false,
        vertexColors: true,
        transparent: true,
        opacity: 1,
        alphaTest: 0.5,
        depthWrite: false,
      });

      const points = new THREE.Points(geometry, material);
      const group = new THREE.Group();
      group.add(points);

      this.applyTransformations(group);

      logInfo('Mesh created successfully:', {
        position: group.position.toArray(),
        rotation: group.rotation.toArray(),
        scale: group.scale.toArray(),
        boundingBox: new THREE.Box3().setFromObject(group)
      });
      return group;
    } catch (error) {
      logError('Error creating mesh:', error);
      return null;
    }
  }

  applyTransformations(object) {
    object.position.copy(this.worldPos);
    object.rotation.copy(this.rotation);
    object.scale.copy(this.scale);

    object.updateMatrixWorld(true);

    logInfo('Transformations applied:', {
      position: object.position.toArray(),
      rotation: object.rotation.toArray(),
      scale: object.scale.toArray(),
      worldMatrix: object.matrixWorld.elements
    });
  }

  static testCircleModel(xmlData) {
    logInfo('Testing CircleModel with XML data:', xmlData);
    const modelData = CircleModel.parseXMLToModelData(xmlData);
    const model = new CircleModel(modelData);
    const mesh = model.createMesh();
    if (mesh) {
      logInfo('CircleModel test successful');
      return mesh;
    } else {
      logError('CircleModel test failed');
      return null;
    }
  }

  static parseXMLToModelData(xmlString) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");
    const modelElement = xmlDoc.getElementsByTagName("model")[0];

    const modelData = {};
    for (let i = 0; i < modelElement.attributes.length; i++) {
      const attr = modelElement.attributes[i];
      modelData[attr.name] = attr.value;
    }

    // Parse ControllerConnection
    const controllerConnection = modelElement.getElementsByTagName("ControllerConnection")[0];
    if (controllerConnection) {
      modelData.controllerConnection = {};
      for (let i = 0; i < controllerConnection.attributes.length; i++) {
        const attr = controllerConnection.attributes[i];
        modelData.controllerConnection[attr.name] = attr.value;
      }
    }

    return modelData;
  }
}

export default CircleModel;
