import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const PrivacyPolicy = () => (
  <Container maxWidth="md">
    <Box my={4}>
      <Typography variant="h4" component="h1" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography paragraph>Last updated: August 1, 2023</Typography>
      <Typography paragraph>
        At xLights.rock, we value your trust and are committed to protecting your privacy. This
        policy explains how we collect, use, and safeguard your information.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        Information We Collect
      </Typography>
      <Typography paragraph>
        We collect information you provide directly, such as when you create an account or use our
        services. This may include your name, email address, and profile information. We also gather
        data about your device and how you use our platform, including:
      </Typography>
      <ul>
        <li>Device information (hardware model, operating system, unique device identifiers)</li>
        <li>Log data (search queries, IP address, browser type, crash reports)</li>
        <li>Cookies and similar technologies for improving user experience and service quality</li>
      </ul>

      <Typography variant="h6" component="h2" gutterBottom>
        How We Use Your Information
      </Typography>
      <Typography paragraph>
        We use the information we collect to provide, maintain, and improve our services. This
        includes:
      </Typography>
      <ul>
        <li>Personalizing your experience and content</li>
        <li>Analyzing usage patterns to enhance our platform</li>
        <li>Communicating with you about our services</li>
        <li>Protecting against fraud and abuse</li>
      </ul>

      <Typography variant="h6" component="h2" gutterBottom>
        Information Sharing
      </Typography>
      <Typography paragraph>
        We do not sell your personal information. We may share information in the following
        circumstances:
      </Typography>
      <ul>
        <li>With your consent</li>
        <li>For legal reasons, if required by law</li>
        <li>With service providers who assist in our operations</li>
      </ul>

      <Typography variant="h6" component="h2" gutterBottom>
        Your Choices
      </Typography>
      <Typography paragraph>You have the right to:</Typography>
      <ul>
        <li>Access and update your personal information</li>
        <li>Delete your account and associated data</li>
        <li>Opt-out of certain data collection and use</li>
      </ul>

      <Typography variant="h6" component="h2" gutterBottom>
        Data Security
      </Typography>
      <Typography paragraph>
        We implement robust security measures to protect your information, including encryption and
        secure server storage. However, no method of transmission over the Internet is 100% secure,
        and we cannot guarantee absolute security.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        Children's Privacy
      </Typography>
      <Typography paragraph>
        Our services are not intended for children under 13. We do not knowingly collect personal
        information from children under 13. If you believe we have inadvertently collected such
        information, please contact us to have it removed.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        Changes to This Policy
      </Typography>
      <Typography paragraph>
        We may update this policy periodically. We'll notify you of significant changes and keep
        previous versions available for review. Your continued use of our services after such
        changes constitutes acceptance of the updated policy.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        Contact Us
      </Typography>
      <Typography paragraph>
        If you have any questions about this Privacy Policy, please contact us at
        privacy@xlights.rock.
      </Typography>
    </Box>
  </Container>
);

export default PrivacyPolicy;
