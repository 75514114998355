import * as THREE from 'three';
import { logInfo, logError } from '../../utils/logger';

export const parseStarModel = (modelData) => {
  logInfo('Parsing Star model:', modelData);
  const {
    parm1,
    parm2,
    parm3,
    starRatio,
    starLayerSizes,
    starStartLocation,
    starCenterPercent,
    rotation,
  } = modelData;
  const numStrings = parseInt(parm1) || 1;
  const totalLights = parseInt(parm2) || 20;
  const numPoints = Math.max(parseInt(parm3) || 5, 2);
  const ratio = parseFloat(starRatio) || 2.618034;
  const innerPercent = parseInt(starCenterPercent) || -1;
  const points = {};

  if (!numPoints || !totalLights || !numStrings) {
    logError('Invalid star model parameters:', {
      numPoints,
      totalLights,
      numStrings,
    });
    return { rows: 0, cols: 0, points: {} };
  }

  const layerSizes = Array.isArray(starLayerSizes)
    ? starLayerSizes.map(Number)
    : typeof starLayerSizes === 'string'
      ? starLayerSizes.split(',').map(Number)
      : [totalLights];
  const layerCount = layerSizes.length;

  // Reverse the layer sizes to match the StarModel.js implementation
  layerSizes.reverse();

  const outerRadius = 1; // Normalize to unit circle
  const pointAngleGap = (Math.PI * 2.0) / numPoints;
  const directionUnit = starStartLocation && starStartLocation.includes('-CCW') ? -1.0 : 1.0;
  const startAngle = getStartAngle(starStartLocation);
  const starSegments = 2 * numPoints;

  let layerRadiusDelta = 0;
  if (layerCount > 1) {
    const actualInnerPercent = innerPercent === -1 ? 100.0 / layerCount : innerPercent;
    layerRadiusDelta = (outerRadius * (100.0 - actualInnerPercent)) / (100.0 * (layerCount - 1.0));
  }

  let currentNode = 0;

  // Calculate the bounding box of the star
  let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

  // Process layers from outer to inner
  for (let layer = layerCount - 1; layer >= 0; layer--) {
    const layerLights = layerSizes[layer];
    const layerRadius = outerRadius - (layerCount - 1 - layer) * layerRadiusDelta;
    const innerRadius = layerRadius / ratio;

    let curAngle = startAngle;
    let startOuter = !starStartLocation || !starStartLocation.includes('Bottom Ctr');

    const totalSegmentLength =
      starSegments * getSegmentLength(layerRadius, innerRadius, pointAngleGap);
    const coordGap = totalSegmentLength / layerLights;

    for (let lightIndex = 0; lightIndex < layerLights; lightIndex++) {
      const segmentIndex = Math.floor((lightIndex * starSegments) / layerLights);
      const segmentT = ((lightIndex * starSegments) / layerLights) % 1;

      const isOuter = (segmentIndex % 2 === 0) === startOuter;
      const radius = isOuter ? layerRadius : innerRadius;
      const nextRadius = isOuter ? innerRadius : layerRadius;

      const angleStart = curAngle + (segmentIndex * pointAngleGap * directionUnit) / 2.0;
      const angleEnd = angleStart + (pointAngleGap * directionUnit) / 2.0;

      const start = getPointOnCircle(radius, angleStart);
      const end = getPointOnCircle(nextRadius, angleEnd);

      const point = getPositionOnLine(start, end, segmentT);

      // Apply rotation
      const rotatedPoint = applyRotation(point, rotation);

      // Update bounding box
      minX = Math.min(minX, rotatedPoint.x);
      minY = Math.min(minY, rotatedPoint.y);
      maxX = Math.max(maxX, rotatedPoint.x);
      maxY = Math.max(maxY, rotatedPoint.y);

      if (!points[currentNode + 1]) {
        points[currentNode + 1] = [];
      }
      points[currentNode + 1].push(rotatedPoint);

      currentNode++;
    }
  }

  // Normalize all points to [0, 1] range
  const width = maxX - minX;
  const height = maxY - minY;
  const size = Math.max(width, height);

  for (const node in points) {
    points[node] = points[node].map(point => ({
      x: (point.x - minX) / size,
      y: (point.y - minY) / size,
    }));
  }

  logInfo('Parsed star model:', {
    numPoints,
    totalLights,
    pointCount: Object.keys(points).length,
  });
  return { rows: 1, cols: 1, points };
};

// Helper functions
const getStartAngle = (starStartLocation) => {
  if (starStartLocation && starStartLocation.includes('Top')) {
    return Math.PI / 2;
  } else if (starStartLocation && starStartLocation.includes('Bottom')) {
    return -Math.PI / 2;
  } else if (starStartLocation && starStartLocation.includes('Left')) {
    return Math.PI;
  } else {
    return 0;
  }
};

const getPointOnCircle = (radius, angle) => ({
  x: radius * Math.cos(angle),
  y: radius * Math.sin(angle),
});

const getPositionOnLine = (start, end, t) => ({
  x: start.x + (end.x - start.x) * t,
  y: start.y + (end.y - start.y) * t,
});

const getSegmentLength = (outerRadius, innerRadius, angle) => {
  const start = getPointOnCircle(outerRadius, 0);
  const end = getPointOnCircle(innerRadius, angle / 2);
  return Math.sqrt(Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2));
};

const applyRotation = (point, rotation) => {
  if (!rotation) return point;
  const { x, y, z } = rotation;
  const radX = THREE.MathUtils.degToRad(x);
  const radY = THREE.MathUtils.degToRad(y);
  const radZ = THREE.MathUtils.degToRad(z);

  const rotatedPoint = new THREE.Vector3(point.x, point.y, 0);
  rotatedPoint.applyEuler(new THREE.Euler(radX, radY, radZ, 'XYZ'));

  return { x: rotatedPoint.x, y: rotatedPoint.y };
};
