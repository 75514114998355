export const parseSpinnerModel = (modelData) => {
  const { parm1, parm2, parm3, Hollow, StartAngle, Arc, Dir, ZigZag, Alternate } = modelData;

  const numStrings = parseInt(parm1) || 1;
  const nodesPerArm = parseInt(parm2) || 10;
  const armsPerString = parseInt(parm3) || 5;
  const hollow = parseInt(Hollow) || 20;
  const startAngle = parseFloat(StartAngle) || 0;
  const arc = parseInt(Arc) || 360;
  const isLtoR = Dir !== 'R';
  const zigzag = ZigZag === 'true';
  const alternate = Alternate === 'true';

  const armCount = numStrings * armsPerString;
  const angleIncrement = (Math.PI * 2 * arc) / (armCount * 360);
  let angle = (Math.PI * 2 * (270 + startAngle)) / 360;

  const points = {};
  let nodeNumber = 1;

  const calculateRadius = (t) => {
    const hollowRadius = (hollow / 100) * 2 * nodesPerArm;
    const totalRadius = 2 * nodesPerArm;
    return hollowRadius + (totalRadius - hollowRadius) * t;
  };

  for (let arm = 0; arm < armCount; arm++) {
    const cosAngle = Math.cos(angle);
    const sinAngle = Math.sin(angle);

    for (let node = 0; node < nodesPerArm; node++) {
      let nodePosition = nodesPerArm - node - 1; // Reverse node order

      if (zigzag && arm % 2 !== 0) {
        nodePosition = node;
      }

      const t = nodePosition / (nodesPerArm - 1);
      const radius = calculateRadius(t);

      const x = radius * cosAngle;
      const y = radius * sinAngle;

      if (!points[nodeNumber]) {
        points[nodeNumber] = [];
      }
      points[nodeNumber].push({ x: Math.round(x), y: Math.round(y) });
      nodeNumber++;
    }

    if (isLtoR) {
      angle += angleIncrement;
    } else {
      angle -= angleIncrement;
    }
  }

  const maxRadius = calculateRadius(1);
  const size = Math.ceil(maxRadius * 2);

  return { rows: size, cols: size, points };
};
