import * as THREE from 'three';
import BaseModel from './BaseModel';

class ChannelBlockModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.name = 'ChannelBlockModel';
    this.parseModelData();
  }

  parseModelData() {
    const { parm1 } = this.modelData;
    this.numChannels = parseInt(parm1) || 1;
    this.channelColors = [];

    for (let i = 0; i < this.numChannels; i++) {
      const colorAttr = `ChannelProperties.Channel ${i + 1} Color`;
      const colorValue = this.modelData[colorAttr] || 'white';
      this.channelColors.push(new THREE.Color(colorValue));
    }

    console.log('ChannelBlock parameters:', {
      numChannels: this.numChannels,
      channelColors: this.channelColors,
    });
  }

  createGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    for (let i = 0; i < this.numChannels; i++) {
      positions.push(i, 0, 0);
      if (i === 0) {
        // First pixel is teal
        colors.push(0, 1, 1);
      } else if (i === this.numChannels - 1) {
        // Last pixel is dull red
        colors.push(0.5, 0, 0);
      } else {
        const color = this.channelColors[i];
        colors.push(color.r, color.g, color.b);
      }
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    return geometry;
  }

  createMesh() {
    const geometry = this.createGeometry();
    const material = new THREE.PointsMaterial({
      size: parseFloat(this.modelData.PixelSize) || 5,
      sizeAttenuation: false,
      vertexColors: true,
    });

    const points = new THREE.Points(geometry, material);
    return this.applyTransformations(points);
  }

  applyTransformations(object) {
    const { worldPos } = this.modelData.screenLocation;
    object.position.set(worldPos.x, worldPos.y, worldPos.z);
    return object;
  }
}

export default ChannelBlockModel;
