import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo, logWarning, logError } from '../../utils/logger';

class BoxedScreenLocation extends BaseModel {
    constructor(modelData) {
        super(modelData);
        this.name = 'BoxedScreenLocation';
        this.parseModelData();
    }

    parseModelData() {
        const { Width, Height, Depth } = this.modelData;
        this.width = parseFloat(Width) || 1;
        this.height = parseFloat(Height) || 1;
        this.depth = parseFloat(Depth) || 1;

        logInfo('BoxedScreenLocation parameters:', {
            width: this.width,
            height: this.height,
            depth: this.depth,
        });
    }

    createGeometry() {
        const geometry = new THREE.BoxGeometry(this.width, this.height, this.depth);
        return geometry;
    }

    createMesh() {
        const geometry = this.createGeometry();
        const material = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            wireframe: true,
        });

        const mesh = new THREE.Mesh(geometry, material);
        return this.applyTransformations(mesh);
    }

    applyTransformations(object) {
        const { worldPos } = this.modelData.screenLocation;
        object.position.set(worldPos.x, worldPos.y, worldPos.z);
        return object;
    }

    getDimension() {
        return Math.max(this.width, this.height, this.depth);
    }
}

export default BoxedScreenLocation;