export const parseArchesModel = (modelData) => {
  const { parm1, parm2, parm3, arc } = modelData;
  const numArches = parseInt(parm1) || 1;
  const lightsPerArch = parseInt(parm2) || 50;
  const segments = parseInt(parm3) || 1;
  const archArc = (parseInt(arc) || 180) * (Math.PI / 180); // Convert to radians

  const points = {};
  let nodeNumber = 1;

  // Calculate the radius based on the number of lights
  const radius = (lightsPerArch - 1) / 2;

  // Increase the number of points for smoother curves
  const pointsPerArch = Math.max(lightsPerArch, 100);

  for (let arch = 0; arch < numArches; arch++) {
    for (let i = 0; i <= pointsPerArch; i++) {
      const angle = (archArc * i) / pointsPerArch;

      // Use parametric equations for a circle
      const x = radius * Math.cos(Math.PI - angle); // Subtract from π to start from the left
      const y = radius * Math.sin(Math.PI - angle);

      if (!points[nodeNumber]) {
        points[nodeNumber] = [];
      }

      // Adjust x position for multiple arches and scale coordinates
      const adjustedX = (x + radius) + arch * (radius * 2 + 1);
      const adjustedY = radius + y; // Flip the arch upside down

      points[nodeNumber].push({ x: adjustedX, y: adjustedY });

      // Only increment the node number for actual lights, not interpolated points
      if (i % (pointsPerArch / lightsPerArch) === 0) {
        nodeNumber++;
      }
    }
  }

  return {
    rows: radius * 2 + 1, // Height of the arch
    cols: (radius * 2 + 1) * numArches, // Width of all arches
    points,
  };
};
