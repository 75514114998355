import * as THREE from 'three';
import DmxModel from '../DmxModel';

class DmxColorAbilityRGB extends DmxModel {
    constructor(modelData) {
        super(modelData);
        this.redChannel = modelData.redChannel || 1;
        this.greenChannel = modelData.greenChannel || 2;
        this.blueChannel = modelData.blueChannel || 3;
        this.whiteChannel = modelData.whiteChannel || 0;

        this.redBrightness = modelData.redBrightness || 100;
        this.greenBrightness = modelData.greenBrightness || 100;
        this.blueBrightness = modelData.blueBrightness || 100;
        this.whiteBrightness = modelData.whiteBrightness || 100;

        this.redGamma = modelData.redGamma || 1.0;
        this.greenGamma = modelData.greenGamma || 1.0;
        this.blueGamma = modelData.blueGamma || 1.0;
        this.whiteGamma = modelData.whiteGamma || 1.0;

        this.color = new THREE.Color(1, 1, 1); // Default to white
    }

    createMesh() {
        // Create a circular disc to represent the RGB color output
        const geometry = new THREE.CircleGeometry(0.5, 32);
        const material = new THREE.MeshBasicMaterial({ color: this.color, side: THREE.DoubleSide });
        const mesh = new THREE.Mesh(geometry, material);

        return mesh;
    }

    updateColor(r, g, b, w = 0) {
        if (w > 0) {
            this.color.setRGB(w, w, w);
        } else {
            this.color.setRGB(r, g, b);
        }

        if (this.mesh) {
            this.mesh.material.color = this.color;
        }
    }

    applyBrightnessAndGamma(value, brightness, gamma) {
        const normalizedValue = value / 255;
        const brightnessAdjusted = normalizedValue * (brightness / 100);
        return Math.pow(brightnessAdjusted, 1 / gamma);
    }

    // Method to update the color based on DMX values
    updateFromDmxValues(dmxValues) {
        const r = this.applyBrightnessAndGamma(dmxValues[this.redChannel - 1], this.redBrightness, this.redGamma);
        const g = this.applyBrightnessAndGamma(dmxValues[this.greenChannel - 1], this.greenBrightness, this.greenGamma);
        const b = this.applyBrightnessAndGamma(dmxValues[this.blueChannel - 1], this.blueBrightness, this.blueGamma);
        const w = this.whiteChannel > 0 ? this.applyBrightnessAndGamma(dmxValues[this.whiteChannel - 1], this.whiteBrightness, this.whiteGamma) : 0;

        this.updateColor(r, g, b, w);
    }

    getBeamColor() {
        return this.color;
    }

    isColorChannel(channel) {
        return channel === this.redChannel ||
            channel === this.greenChannel ||
            channel === this.blueChannel ||
            channel === this.whiteChannel;
    }

    getNumChannels() {
        return [this.redChannel, this.greenChannel, this.blueChannel, this.whiteChannel]
            .filter(channel => channel > 0).length;
    }

    setChannelProperty(property, value) {
        if (this[property] !== undefined) {
            this[property] = value;
        }
    }

    getChannelProperties() {
        return {
            redChannel: this.redChannel,
            greenChannel: this.greenChannel,
            blueChannel: this.blueChannel,
            whiteChannel: this.whiteChannel,
            redBrightness: this.redBrightness,
            greenBrightness: this.greenBrightness,
            blueBrightness: this.blueBrightness,
            whiteBrightness: this.whiteBrightness,
            redGamma: this.redGamma,
            greenGamma: this.greenGamma,
            blueGamma: this.blueGamma,
            whiteGamma: this.whiteGamma
        };
    }
}

export default DmxColorAbilityRGB;