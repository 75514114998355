import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import CircleModel from './models/CircleModel';
import { logInfo } from '../utils/logger';

const CircleModelTest = () => {
    const mountRef = useRef(null);
    const rendererRef = useRef(null);
    const sceneRef = useRef(null);
    const cameraRef = useRef(null);

    useEffect(() => {
        console.log("CircleModelTest component mounted");

        const testCases = [
            {
                name: "Basic Circle",
                xml: `
          <model DisplayAs="Circle" StringType="RGB Nodes" StartSide="B" Dir="L" Antialias="1" PixelSize="2"
            Transparency="0" parm1="1" parm2="50" parm3="50" name="Circle" InsideOut="0"
            WorldPosX="0" WorldPosY="0" WorldPosZ="0"
            ScaleX="1" ScaleY="1" ScaleZ="1"
            RotateX="0" RotateY="0" RotateZ="0">
            <ControllerConnection Protocol="ws2811" Port="1"/>
          </model>
        `
            },
            {
                name: "Multi-Layer Circle",
                xml: `
          <model DisplayAs="Circle" StringType="RGB Nodes" StartSide="T" Dir="R" Antialias="1" PixelSize="2"
            Transparency="0" parm1="3" parm2="50" parm3="25" name="Circle" InsideOut="1"
            WorldPosX="2" WorldPosY="2" WorldPosZ="0"
            ScaleX="1.5" ScaleY="1.5" ScaleZ="1"
            RotateX="0" RotateY="0" RotateZ="45">
            <ControllerConnection Protocol="ws2811" Port="1"/>
          </model>
        `
            }
        ];

        const scene = new THREE.Scene();
        sceneRef.current = scene;
        console.log("Scene created:", scene);

        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        cameraRef.current = camera;
        console.log("Camera created:", camera);

        const renderer = new THREE.WebGLRenderer();
        rendererRef.current = renderer;
        renderer.setSize(window.innerWidth, window.innerHeight);
        console.log("Renderer created:", renderer);

        if (mountRef.current) {
            mountRef.current.appendChild(renderer.domElement);
            console.log("Renderer appended to DOM");
        }

        const controls = new OrbitControls(camera, renderer.domElement);
        console.log("OrbitControls created:", controls);

        // Add axes helper
        const axesHelper = new THREE.AxesHelper(5);
        scene.add(axesHelper);
        console.log("AxesHelper added to scene");

        // Position camera
        camera.position.set(0, 0, 100);
        camera.lookAt(0, 0, 0);
        console.log("Camera positioned");

        // Create and add circle models
        testCases.forEach((testCase, index) => {
            console.log(`Processing test case: ${testCase.name}`);
            const circleModel = CircleModel.testCircleModel(testCase.xml);
            if (circleModel) {
                circleModel.position.x = index * 30; // Increase offset between models
                scene.add(circleModel);
                console.log(`${testCase.name} added to scene:`, circleModel);

                // Add wireframe box
                const box = new THREE.Box3().setFromObject(circleModel);
                const helper = new THREE.Box3Helper(box, 0xffff00);
                scene.add(helper);
                console.log(`Wireframe box added for ${testCase.name}`);

                // Add text label
                const loader = new FontLoader();
                loader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', function (font) {
                    const textGeometry = new TextGeometry(testCase.name, {
                        font: font,
                        size: 2,
                        height: 0.1,
                    });
                    const textMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
                    const textMesh = new THREE.Mesh(textGeometry, textMaterial);
                    textMesh.position.set(circleModel.position.x, box.max.y + 5, 0);
                    scene.add(textMesh);
                    console.log(`Text label added for ${testCase.name}`);
                });
            } else {
                console.error(`Failed to create circle model for ${testCase.name}`);
            }
        });

        // Add a small sphere at the origin for reference
        const sphereGeometry = new THREE.SphereGeometry(0.5, 32, 32);
        const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
        scene.add(sphere);
        console.log("Reference sphere added to scene");

        // Animation loop
        const animate = () => {
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
        };

        console.log("Starting animation loop");
        animate();

        // Handle window resize
        const handleResize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);
            console.log("Window resized, updated renderer and camera");
        };

        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => {
            console.log("Cleaning up CircleModelTest component");
            window.removeEventListener('resize', handleResize);
            if (rendererRef.current && mountRef.current) {
                mountRef.current.removeChild(rendererRef.current.domElement);
            }
            if (rendererRef.current) {
                rendererRef.current.dispose();
            }
        };
    }, []);

    return <div ref={mountRef} style={{ width: '100%', height: '100vh' }}></div>;
};

export default CircleModelTest;