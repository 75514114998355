import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo } from '../../utils/logger';

class ArchesModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.name = 'ArchesModel';
    this.arc = parseInt(modelData.arc) || 180;
    this.hollow = parseInt(modelData.Hollow) || 70;
    this.zigzag = modelData.ZigZag === 'true';
    this.gap = parseInt(modelData.Gap) || 0;
    this.parseModelData();
    this.scaleFactor = 0.54;
  }

  parseModelData() {
    const { parm1, parm2, parm3, Dir, StringType } = this.modelData;
    this.numArches = parseInt(parm1) || 1;
    this.segmentsPerArch = parseInt(parm2) || 1;
    this.lightsPerSegment = parseInt(parm3) || 1;
    this.isLtoR = Dir !== 'R';
    this.isBotToTop = this.modelData.StartSide !== 'B';
    this.stringType = StringType || 'RGB';
    this.layerSizes = this.modelData.LayerSizes
      ? this.modelData.LayerSizes.split(',').map(Number)
      : [];

    logInfo('Arches parameters:', {
      numArches: this.numArches,
      segmentsPerArch: this.segmentsPerArch,
      lightsPerSegment: this.lightsPerSegment,
      isLtoR: this.isLtoR,
      isBotToTop: this.isBotToTop,
      stringType: this.stringType,
      arc: this.arc,
      hollow: this.hollow,
      zigzag: this.zigzag,
      gap: this.gap,
      layerSizes: this.layerSizes,
      worldScale: this.worldScale,
      scaleFactor: this.scaleFactor,
    });
  }

  createGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    if (this.layerSizes.length === 0) {
      this.createStandardArchesGeometry(positions, colors);
    } else {
      this.createLayeredArchesGeometry(positions, colors);
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    return geometry;
  }

  createStandardArchesGeometry(positions, colors) {
    const totalLights = this.numArches * this.segmentsPerArch * this.lightsPerSegment;
    const coords = this.SetArchCoord();

    for (let i = 0; i < coords.length; i++) {
      const { x, y } = coords[i];
      positions.push(x, y, 0);

      if (i === 0) {
        colors.push(0, 1, 1); // Teal color for the first light
      } else if (i === totalLights - 1) {
        colors.push(0.5, 0, 0); // Dull red color for the last light
      } else {
        colors.push(1, 1, 1); // White color for all other lights
      }
    }

    // Update the render size in the screen location
    this.modelData.screenLocation.width = this.renderWidth;
    this.modelData.screenLocation.height = this.renderHeight;
  }

  createLayeredArchesGeometry(positions, colors) {
    const totalLights = this.layerSizes.reduce((sum, size) => sum + size, 0);
    const coords = this.SetLayerdArchCoord();

    for (let i = 0; i < coords.length; i++) {
      const { x, y } = coords[i];
      positions.push(x, y, 0);

      if (i === 0) {
        colors.push(0, 1, 1); // Teal color for the first light
      } else if (i === totalLights - 1) {
        colors.push(0.5, 0, 0); // Dull red color for the last light
      } else {
        colors.push(1, 1, 1); // White color for all other lights
      }
    }

    // Update the render size in the screen location
    this.modelData.screenLocation.width = this.renderWidth;
    this.modelData.screenLocation.height = this.renderHeight;
  }

  SetArchCoord() {
    const coords = [];
    const midpt = (this.segmentsPerArch * this.lightsPerSegment - 1) / 2;
    const total = THREE.MathUtils.degToRad(this.arc);
    const start = (Math.PI - total) / 2;
    const skewAngle = THREE.MathUtils.degToRad(this.modelData.Angle || 0);

    const angle = -Math.PI / 2 + start;
    let x = midpt * Math.sin(angle) * 2 + this.segmentsPerArch * this.lightsPerSegment;
    const width = this.segmentsPerArch * this.lightsPerSegment * 2 - x;

    let minY = Infinity;
    let gaps = 0;

    for (let n = 0; n < this.numArches * this.segmentsPerArch * this.lightsPerSegment; n++) {
      const archIndex = Math.floor(n / (this.segmentsPerArch * this.lightsPerSegment));
      const xoffset = archIndex * width;
      const angle2 = -Math.PI / 2 + start + total * (n % (this.segmentsPerArch * this.lightsPerSegment)) / midpt / 2;

      x = xoffset + midpt * Math.sin(angle2) * 2 + this.segmentsPerArch * this.lightsPerSegment + gaps * this.gap;
      let y = (this.segmentsPerArch * this.lightsPerSegment) * Math.cos(angle2);

      // Apply skew
      const rotatedX = x * Math.cos(skewAngle) - y * Math.sin(skewAngle);
      const rotatedY = x * Math.sin(skewAngle) + y * Math.cos(skewAngle);

      coords.push({ x: rotatedX * this.scaleFactor * this.worldScale, y: rotatedY * this.scaleFactor * this.worldScale });
      minY = Math.min(minY, rotatedY);

      if ((n + 1) % (this.segmentsPerArch * this.lightsPerSegment) === 0) {
        gaps++;
      }
    }

    this.renderHeight = (this.segmentsPerArch * this.lightsPerSegment - minY) * this.scaleFactor * this.worldScale;
    this.renderWidth = (width * this.numArches + (this.numArches - 1) * this.gap) * this.scaleFactor * this.worldScale;

    return coords;
  }

  SetLayerdArchCoord() {
    const coords = [];
    const maxLen = Math.max(...this.layerSizes);
    const midpt = (maxLen * this.lightsPerSegment - 1) / 2;
    const total = THREE.MathUtils.degToRad(this.arc);
    const start = (Math.PI - total) / 2;
    const skewAngle = THREE.MathUtils.degToRad(this.modelData.Angle || 0);

    const angle = -Math.PI / 2 + start;
    let x = midpt * Math.sin(angle) * 2 + maxLen * this.lightsPerSegment;
    const width = maxLen * this.lightsPerSegment * 2 - x;

    const archgap = this.layerSizes.length > 1 ? (1 - this.hollow / 100) / (this.layerSizes.length - 1) : 0;

    let minY = Infinity;
    let idx = 0;
    let dir = this.isLtoR;
    let inOut = this.isBotToTop;

    for (let layer = 0; layer < this.layerSizes.length; layer++) {
      const layerSize = this.layerSizes[inOut ? this.layerSizes.length - layer - 1 : layer];
      const adj = 1 - archgap * (this.layerSizes.length - 1 - layer);

      for (let segment = 0; segment < layerSize; segment++) {
        for (let light = 0; light < this.lightsPerSegment; light++) {
          const angle2 = -Math.PI / 2 + start + total * ((segment * this.lightsPerSegment + light) / midpt / 2);

          x = midpt * Math.sin(angle2) * 2 * adj + maxLen * this.lightsPerSegment;
          let y = (maxLen * this.lightsPerSegment) * Math.cos(angle2) * adj;

          if (!dir) {
            x = maxLen * this.lightsPerSegment * 2 - x;
          }

          // Apply skew
          const rotatedX = x * Math.cos(skewAngle) - y * Math.sin(skewAngle);
          const rotatedY = x * Math.sin(skewAngle) + y * Math.cos(skewAngle);

          coords.push({ x: rotatedX * this.scaleFactor * this.worldScale, y: rotatedY * this.scaleFactor * this.worldScale });
          minY = Math.min(minY, rotatedY);

          idx++;
        }
      }

      if (this.zigzag) {
        dir = !dir;
      }
    }

    this.renderHeight = (maxLen * this.lightsPerSegment - minY) * this.scaleFactor * this.worldScale;
    this.renderWidth = width * this.scaleFactor * this.worldScale;

    return coords;
  }

  createMesh() {
    const geometry = this.createGeometry();
    const material = new THREE.PointsMaterial({
      size: (parseFloat(this.modelData.PixelSize) || 2) * this.scaleFactor,
      sizeAttenuation: false,
      vertexColors: true,
    });

    const points = new THREE.Points(geometry, material);
    return this.applyTransformations(points);
  }

  applyTransformations(object) {
    const { worldPos } = this.modelData.screenLocation;
    object.position.set(
      worldPos.x * this.worldScale,
      worldPos.y * this.worldScale,
      worldPos.z * this.worldScale
    );

    if (this.modelData.rotation) {
      object.rotation.set(
        THREE.MathUtils.degToRad(this.modelData.rotation.x),
        THREE.MathUtils.degToRad(this.modelData.rotation.y),
        THREE.MathUtils.degToRad(this.modelData.rotation.z)
      );
    }

    return object;
  }
}

export default ArchesModel;