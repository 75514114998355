import * as THREE from 'three';
import DmxMovingHead from './DmxMovingHead';
import DmxMotor from '../DmxMotor';

class DmxMovingHeadAdv extends DmxMovingHead {
    constructor(modelData) {
        super(modelData);
        this.displayAs = "DmxMovingHeadAdv";

        this.zoomMotor = new DmxMotor({
            ...modelData.zoomMotor,
            channelCoarse: modelData.zoomChannel,
        });

        this.prismEnabled = false;
        this.prismRotation = 0;
        this.frostEnabled = false;
        this.goboIndex = 0;

        this.prismChannel = modelData.prismChannel || 0;
        this.prismRotationChannel = modelData.prismRotationChannel || 0;
        this.frostChannel = modelData.frostChannel || 0;
        this.goboChannel = modelData.goboChannel || 0;

        this.gobos = modelData.gobos || ['circle', 'star', 'dots', 'lines'];
    }

    createMesh() {
        const group = super.createMesh();

        // Add zoom lens representation
        const zoomGeometry = new THREE.CylinderGeometry(0.08, 0.12, 0.1, 32);
        const zoomMaterial = new THREE.MeshPhongMaterial({ color: 0xcccccc });
        this.zoomMesh = new THREE.Mesh(zoomGeometry, zoomMaterial);
        this.zoomMesh.rotation.x = Math.PI / 2;
        this.zoomMesh.position.z = 0.2;
        this.headMesh.add(this.zoomMesh);

        // Add prism representation
        const prismGeometry = new THREE.ConeGeometry(0.05, 0.1, 3);
        const prismMaterial = new THREE.MeshPhongMaterial({ color: 0x88ccff, transparent: true, opacity: 0.6 });
        this.prismMesh = new THREE.Mesh(prismGeometry, prismMaterial);
        this.prismMesh.rotation.x = Math.PI / 2;
        this.prismMesh.position.z = 0.25;
        this.prismMesh.visible = false;
        this.headMesh.add(this.prismMesh);

        // Add frost filter representation
        const frostGeometry = new THREE.CircleGeometry(0.1, 32);
        const frostMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0.3 });
        this.frostMesh = new THREE.Mesh(frostGeometry, frostMaterial);
        this.frostMesh.position.z = 0.176;
        this.frostMesh.visible = false;
        this.headMesh.add(this.frostMesh);

        // Add gobo representation
        const goboGeometry = new THREE.PlaneGeometry(0.15, 0.15);
        const goboMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, transparent: true });
        this.goboMesh = new THREE.Mesh(goboGeometry, goboMaterial);
        this.goboMesh.position.z = 0.177;
        this.headMesh.add(this.goboMesh);

        return group;
    }

    updateFromDmxValues(dmxValues) {
        super.updateFromDmxValues(dmxValues);

        this.zoomMotor.updateFromDmxValues(dmxValues);

        if (this.prismChannel > 0 && this.prismChannel <= dmxValues.length) {
            this.prismEnabled = dmxValues[this.prismChannel - 1] > 127;
        }

        if (this.prismRotationChannel > 0 && this.prismRotationChannel <= dmxValues.length) {
            this.prismRotation = (dmxValues[this.prismRotationChannel - 1] / 255) * Math.PI * 2;
        }

        if (this.frostChannel > 0 && this.frostChannel <= dmxValues.length) {
            this.frostEnabled = dmxValues[this.frostChannel - 1] > 127;
        }

        if (this.goboChannel > 0 && this.goboChannel <= dmxValues.length) {
            this.goboIndex = Math.floor((dmxValues[this.goboChannel - 1] / 255) * this.gobos.length);
        }

        this.updateAdvancedMesh();
    }

    updateAdvancedMesh() {
        super.updateMesh();

        if (this.mesh) {
            // Update zoom
            const zoomScale = 1 + (this.zoomMotor.currentPosition / this.zoomMotor.rangeOfMotion) * 0.5;
            this.beamMesh.scale.set(zoomScale, zoomScale, 1);

            // Update prism
            this.prismMesh.visible = this.prismEnabled;
            this.prismMesh.rotation.z = this.prismRotation;

            // Update frost
            this.frostMesh.visible = this.frostEnabled;

            // Update gobo
            this.updateGobo();
        }
    }

    updateGobo() {
        const goboPattern = this.gobos[this.goboIndex];
        const canvas = document.createElement('canvas');
        canvas.width = 128;
        canvas.height = 128;
        const ctx = canvas.getContext('2d');

        ctx.fillStyle = 'black';
        ctx.fillRect(0, 0, 128, 128);
        ctx.fillStyle = 'white';

        switch (goboPattern) {
            case 'circle':
                ctx.beginPath();
                ctx.arc(64, 64, 32, 0, Math.PI * 2);
                ctx.fill();
                break;
            case 'star':
                this.drawStar(ctx, 64, 64, 5, 30, 15);
                break;
            case 'dots':
                for (let i = 0; i < 5; i++) {
                    for (let j = 0; j < 5; j++) {
                        ctx.beginPath();
                        ctx.arc(16 + i * 24, 16 + j * 24, 8, 0, Math.PI * 2);
                        ctx.fill();
                    }
                }
                break;
            case 'lines':
                for (let i = 0; i < 5; i++) {
                    ctx.fillRect(0, i * 24, 128, 12);
                }
                break;
        }

        const texture = new THREE.CanvasTexture(canvas);
        this.goboMesh.material.map = texture;
        this.goboMesh.material.needsUpdate = true;
    }

    drawStar(ctx, cx, cy, spikes, outerRadius, innerRadius) {
        let rot = Math.PI / 2 * 3;
        let x = cx;
        let y = cy;
        const step = Math.PI / spikes;

        ctx.beginPath();
        ctx.moveTo(cx, cy - outerRadius);
        for (let i = 0; i < spikes; i++) {
            x = cx + Math.cos(rot) * outerRadius;
            y = cy + Math.sin(rot) * outerRadius;
            ctx.lineTo(x, y);
            rot += step;

            x = cx + Math.cos(rot) * innerRadius;
            y = cy + Math.sin(rot) * innerRadius;
            ctx.lineTo(x, y);
            rot += step;
        }
        ctx.lineTo(cx, cy - outerRadius);
        ctx.closePath();
        ctx.fill();
    }

    onPropertyChange(propertyName, value) {
        switch (propertyName) {
            case 'zoomMotor':
                this.zoomMotor.onPropertyChange(value.property, value.value);
                this.updateAdvancedMesh();
                return true;
            case 'prismChannel':
            case 'prismRotationChannel':
            case 'frostChannel':
            case 'goboChannel':
                this[propertyName] = value;
                return true;
            default:
                return super.onPropertyChange(propertyName, value);
        }
    }

    getProperties() {
        return {
            ...super.getProperties(),
            zoomMotor: this.zoomMotor.getProperties(),
            prismChannel: this.prismChannel,
            prismRotationChannel: this.prismRotationChannel,
            frostChannel: this.frostChannel,
            goboChannel: this.goboChannel,
            gobos: this.gobos,
        };
    }
}

export default DmxMovingHeadAdv;