import React, { useEffect, useState } from 'react';
import { useParams, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import Visualizer from './visualizer';
import ControllerPortMapping from './ControllerPortMapping';
import { useLayout } from './LayoutContext';
import { logInfo, logError } from '../utils/logger';

const SharedLayout = () => {
  const { layout, setLayout } = useLayout();
  const { shareId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    const fetchSharedLayout = async () => {
      if (shareId && !dataFetched) {
        try {
          setIsLoading(true);
          logInfo(`Fetching shared layout with shareId: ${shareId}`);
          const response = await axios.get(`/api/layout/share/${shareId}`);
          logInfo('Shared layout data received');
          setLayout(response.data);
          setDataFetched(true);
        } catch (error) {
          logError('Error fetching shared layout:', error);
          setError(
            error.response?.data?.error ||
            'Failed to load the shared layout. It may have expired or been deleted.'
          );
        } finally {
          setIsLoading(false);
        }
      } else if (!shareId) {
        setIsLoading(false);
      }
    };

    fetchSharedLayout();
  }, [shareId, setLayout, dataFetched]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!layout) {
    return <Typography>No layout data available.</Typography>;
  }

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            xLights Layout Visualizer (Shared View)
          </Typography>
          <Button color="inherit" component={Link} to={`/share/${shareId}`}>
            Visualize
          </Button>
          <Button color="inherit" component={Link} to={`/share/${shareId}/portmapping`}>
            Port Mapping
          </Button>
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path="/" element={<Visualizer layout={layout} isSharedLayout={true} />} />
        <Route path="/portmapping" element={<ControllerPortMapping isSharedLayout={true} />} />
      </Routes>
    </Box>
  );
};

export default SharedLayout;