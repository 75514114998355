import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo, logError } from '../../utils/logger';

class SpinnerModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.name = 'SpinnerModel';
    this.parseModelData();
  }

  parseModelData() {
    const { parm1, parm2, parm3, Hollow, StartAngle, Arc, Dir, ZigZag, AlternateNodes } =
      this.modelData;

    this.numStrings = parseInt(parm1) || 1;
    this.nodesPerArm = parseInt(parm2) || 10;
    this.armsPerString = parseInt(parm3) || 5;
    this.hollow = parseInt(Hollow) || 20;
    this.startAngle = parseFloat(StartAngle) || 0;
    this.arc = parseInt(Arc) || 360;
    this.isLtoR = Dir !== 'R';
    this.zigzag = ZigZag === 'true';
    this.alternateNodes = AlternateNodes === 'true';

    logInfo('Parsed SpinnerModel data:', {
      numStrings: this.numStrings,
      nodesPerArm: this.nodesPerArm,
      armsPerString: this.armsPerString,
      hollow: this.hollow,
      startAngle: this.startAngle,
      arc: this.arc,
      isLtoR: this.isLtoR,
      zigzag: this.zigzag,
      alternateNodes: this.alternateNodes
    });
  }

  calculateRadius(t) {
    const hollowRadius = (this.hollow / 100) * 2 * this.nodesPerArm;
    const totalRadius = 2 * this.nodesPerArm;
    return hollowRadius + (totalRadius - hollowRadius) * t;
  }

  createGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    const armCount = this.numStrings * this.armsPerString;
    const angleIncrement = (this.arc * Math.PI) / 180 / armCount;
    let angle = (Math.PI * 2 * (270 + this.startAngle)) / 360;

    for (let arm = 0; arm < armCount; arm++) {
      for (let node = 0; node < this.nodesPerArm; node++) {
        let nodePosition = node; // Not reversed for counterclockwise

        if (this.zigzag && arm % 2 !== 0) {
          nodePosition = this.nodesPerArm - node - 1;
        }

        const t = nodePosition / (this.nodesPerArm - 1);
        const radius = this.calculateRadius(t);

        const x = radius * Math.cos(angle);
        const y = radius * Math.sin(angle);
        positions.push(x, y, 0);

        if (arm === 0 && node === this.nodesPerArm - 1) {
          colors.push(0, 1, 1); // Teal color for the start node (end of the first arm)
        } else if (arm === armCount - 1 && node === 0) {
          colors.push(1, 0, 0); // Red color for the end node (start of the last arm)
        } else {
          colors.push(1, 1, 1); // White color for all other nodes
        }
      }

      // For counterclockwise direction (Dir="L"), we add to the angle
      angle += angleIncrement;
    }

    if (positions.length === 0) {
      logError('No positions generated for SpinnerModel');
      return null;
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    logInfo('SpinnerModel geometry created', {
      vertices: positions.length / 3,
      arms: armCount
    });

    return geometry;
  }

  createMesh() {
    const geometry = this.createGeometry();
    if (!geometry) {
      logError('Failed to create geometry for SpinnerModel');
      return null;
    }

    const material = new THREE.PointsMaterial({
      size: parseFloat(this.modelData.PixelSize) || 2,
      sizeAttenuation: false,
      vertexColors: true,
    });

    const points = new THREE.Points(geometry, material);
    return this.applyTransformations(points);
  }
}

export default SpinnerModel;
