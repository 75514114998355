import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  IconButton,
  Chip,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  Tooltip,
  Paper,
  Snackbar,
  Alert,
  LinearProgress,
  Divider,
} from '@mui/material';
import { ExpandMore, Close as CloseIcon, Print as PrintIcon } from '@mui/icons-material';
import { generatePDF, generateVisualizerPDF } from '../utils/PDFGenerator';
import PrintDialog from './PrintDialog';
import { useLayout } from './LayoutContext';
import { useTheme, useMediaQuery } from '@mui/material';
import { logInfo, logError } from '../utils/logger';

const ModelChip = ({ model }) => {
  const label = `${model.name} (${model.pixelCount})`;
  return (
    <Tooltip
      title={`Type: ${model.displayAs}, Pixels on this port: ${model.pixelsOnThisPort}, Total pixels: ${model.pixelCount}, Smart Receiver: ${model.controllerConnection?.smartRemote ? String.fromCharCode(64 + parseInt(model.controllerConnection.smartRemote)) : 'N/A'}`}
    >
      <Chip
        label={label}
        size="small"
        sx={{ margin: '2px', maxWidth: '275px' }}
      />
    </Tooltip>
  );
};

const PortRow = ({ port, models }) => {
  const totalPixels = models.reduce(
    (sum, model) => sum + (model.pixelsOnThisPort || model.pixelCount || 0),
    0
  );

  // Sort models by SmartRemote
  const sortedModels = models.sort((a, b) => {
    const smartRemoteA = a.controllerConnection?.smartRemote || '0';
    const smartRemoteB = b.controllerConnection?.smartRemote || '0';
    return parseInt(smartRemoteA) - parseInt(smartRemoteB);
  });

  let currentSmartRemote = null;

  return (
    <TableRow>
      <TableCell sx={{ width: '10%' }}>{port}</TableCell>
      <TableCell sx={{ width: '15%' }}>{totalPixels}</TableCell>
      <TableCell sx={{ width: '75%' }}>
        <Box display="flex" flexDirection="column">
          {sortedModels.map((model, index) => {
            const smartRemote = model.controllerConnection?.smartRemote || '0';
            const showDivider = smartRemote !== currentSmartRemote;
            currentSmartRemote = smartRemote;

            return (
              <React.Fragment key={index}>
                {showDivider && smartRemote !== '0' && (
                  <Divider
                    sx={{
                      my: 1,
                      '&::before, &::after': {
                        borderColor: 'secondary.main',
                      },
                    }}
                  >
                    <Chip
                      label={<Typography variant="body2" fontWeight="bold">{`Smart Receiver ${String.fromCharCode(64 + parseInt(smartRemote))}`}</Typography>}
                      color="secondary"
                      size="small"
                    />
                  </Divider>
                )}
                <ModelChip model={model} />
              </React.Fragment>
            );
          })}
        </Box>
      </TableCell>
    </TableRow>
  );
};

const ControllerCard = ({ controller, onExpand }) => {
  const displayName = controller.name.replace(/_/g, '.');
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Card
      onClick={onExpand}
      sx={{
        height: '210px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        backgroundColor: theme.palette.background.paper,
        color: isDarkMode ? '#ffffff' : '#000000',
      }}
    >
      <CardContent sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" noWrap sx={{ color: 'inherit' }}>
            {displayName}
          </Typography>
          <ExpandMore sx={{ color: 'inherit' }} />
        </Box>
        <Typography variant="body2" sx={{ color: 'inherit', opacity: 0.7 }} noWrap>
          {controller.vendor} {controller.model} - {controller.variant}
        </Typography>
        <Typography variant="body2" sx={{ color: 'inherit', opacity: 0.7 }} noWrap>
          IP: {controller.ip}
        </Typography>
        <Typography variant="body2" sx={{ color: 'inherit', opacity: 0.7 }} noWrap>
          Universe: {controller.universeRange}
        </Typography>
        <Typography variant="body2" sx={{ color: 'inherit', opacity: 0.7 }} noWrap>
          Active State: {controller.activeState}
        </Typography>
      </CardContent>
    </Card>
  );
};

const ControllerPortMapping = ({ layout: propLayout, isSharedLayout, scene, camera }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { layout: contextLayout, setLayout: setContextLayout } = useLayout();
  const [localLayout, setLocalLayout] = useState(propLayout || contextLayout);
  const [printDialogOpen, setPrintDialogOpen] = useState(false);
  const [expandedController, setExpandedController] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const { shareId } = useParams();

  useEffect(() => {
    const fetchLayout = async () => {
      if (isSharedLayout && shareId) {
        try {
          setIsLoading(true);
          logInfo(`Fetching shared layout for port mapping with shareId: ${shareId}`);
          const response = await axios.get(`/api/layout/share/${shareId}`);
          logInfo('Shared layout data received for port mapping');
          setLocalLayout(response.data);
          setContextLayout(response.data);
        } catch (error) {
          logError('Error fetching shared layout for port mapping:', error);
          setError('Failed to load the shared layout. It may have expired or been deleted.');
        } finally {
          setIsLoading(false);
        }
      } else if (propLayout) {
        setLocalLayout(propLayout);
        setIsLoading(false);
      } else if (contextLayout) {
        setLocalLayout(contextLayout);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    fetchLayout();
  }, [isSharedLayout, shareId, propLayout, contextLayout, setContextLayout]);

  const handlePrint = async (selectedControllers, includeDXF, savePath) => {
    try {
      setIsLoading(true);
      setProgress(0);
      const filteredLayout = {
        ...localLayout,
        controllers: localLayout.controllers.filter((c) => selectedControllers.includes(c.name)),
        portMapping: Object.fromEntries(
          Object.entries(localLayout.portMapping).filter(([key]) => selectedControllers.includes(key))
        ),
      };

      const updateProgress = (value) => {
        setProgress(value);
      };

      if (includeDXF) {
        logInfo('Starting DXF PDF generation');
        await generateVisualizerPDF(scene, camera, filteredLayout, true, updateProgress, savePath);
        logInfo('DXF PDF generation completed');
      } else {
        logInfo('Starting regular PDF generation');
        await generatePDF(filteredLayout, false, updateProgress, savePath);
        logInfo('Regular PDF generation completed');
      }
    } catch (error) {
      logError('Error during PDF generation:', error);
      setError(`Failed to generate PDF: ${error.message}`);
    } finally {
      setIsLoading(false);
      setProgress(0);
    }
  };

  const handleExpandController = (controller) => {
    setExpandedController(controller);
  };

  const handleCloseExpanded = () => {
    setExpandedController(null);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ marginTop: '20px', color: theme.palette.text.primary }}>
          Loading controller data...
        </Typography>
      </Box>
    );
  }

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      }}
    >
      {!localLayout || !localLayout.controllers || !localLayout.portMapping ? (
        <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
          No layout data available. Please make sure the layout has been shared correctly.
        </Typography>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h4" sx={{ color: theme.palette.text.primary }}>
              Controller Port Mapping
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PrintIcon />}
              onClick={() => setPrintDialogOpen(true)}
            >
              Print/Export Layout
            </Button>
          </Box>
          <Grid container spacing={3}>
            {localLayout.controllers.map((controller) => (
              <Grid item xs={12} sm={6} md={3} key={controller.name}>
                <ControllerCard
                  controller={controller}
                  onExpand={() => handleExpandController(controller)}
                />
              </Grid>
            ))}
          </Grid>
          <Dialog fullScreen open={expandedController !== null} onClose={handleCloseExpanded}>
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseExpanded}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" sx={{ marginLeft: 2, flex: 1 }}>
                  {expandedController?.name.replace(/_/g, '.')}
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>
              {expandedController && (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: '10%' }}>Port</TableCell>
                      <TableCell sx={{ width: '15%' }}>Pixels</TableCell>
                      <TableCell sx={{ width: '75%' }}>Models</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(localLayout.portMapping[expandedController.name]).map(
                      ([port, models]) => (
                        <PortRow key={port} port={port} models={models} />
                      )
                    )}
                  </TableBody>
                </Table>
              )}
            </DialogContent>
          </Dialog>
          <PrintDialog
            open={printDialogOpen}
            onClose={() => setPrintDialogOpen(false)}
            controllers={localLayout.controllers}
            onPrint={handlePrint}
            layout={localLayout}
            scene={scene}
            camera={camera}
          />
          {isLoading && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress variant="determinate" value={progress} />
              <Typography variant="body2" color="text.secondary" align="center">
                {`${Math.round(progress)}%`}
              </Typography>
            </Box>
          )}
          <Snackbar open={error !== null} autoHideDuration={6000} onClose={() => setError(null)}>
            <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        </>
      )}
    </Paper>
  );
};

export default ControllerPortMapping;