import { logInfo, logError } from '../../utils/logger';

export const parseCustomModel = (modelData) => {
  logInfo('Parsing custom model:', modelData);
  const customModelData = modelData.customModel;
  if (!customModelData) {
    logError('Custom model data not found:', modelData);
    return { rows: 0, cols: 0, points: {} };
  }
  const rows = customModelData.split(';');
  const rowCount = rows.length;
  const colCount = Math.max(...rows.map((row) => row.split(',').length));
  let nodeCount = 0;
  const points = {};
  rows.forEach((row, y) => {
    const values = row.split(',');
    values.forEach((value, x) => {
      if (value.trim() !== '') {
        const nodeNumber = parseInt(value.trim());
        if (!points[nodeNumber]) {
          points[nodeNumber] = [];
        }
        points[nodeNumber].push({ x, y: rowCount - y - 1 });
        nodeCount++;
      }
    });
  });
  logInfo('Parsed custom model:', {
    rows: rowCount,
    cols: colCount,
    nodeCount,
  });
  return { rows: rowCount, cols: colCount, points };
};
