export const parseIciclesModel = (modelData) => {
    const {
        parm1,
        parm2,
        Dir,
        DropPattern,
        AlternateNodes,
    } = modelData;

    const numStrings = parseInt(parm1) || 1;
    const lightsPerString = parseInt(parm2) || 50;
    const isLtoR = Dir !== 'R';
    const dropPattern = DropPattern ? DropPattern.split(',').map(Number) : [3, 4, 5, 4];
    const alternateNodes = AlternateNodes === 'true';
    const totalLights = numStrings * lightsPerString;

    const points = {};
    let nodeNumber = 1;

    const maxH = Math.max(...dropPattern);
    const totalWidth = numStrings + dropPattern.length - 1;

    for (let x = 0; x < numStrings; x++) {
        let lights = lightsPerString;
        let y = 0;
        let curDrop = 0;
        let width = x + curDrop;

        while (lights > 0) {
            while (y >= dropPattern[curDrop]) {
                width++;
                y = 0;
                curDrop = (curDrop + 1) % dropPattern.length;
            }

            let bufX = isLtoR ? width : totalWidth - width - 1;
            let bufY;
            if (alternateNodes) {
                let nodesInDrop = dropPattern[curDrop];
                if (y + 1 <= (nodesInDrop + 1) / 2) {
                    bufY = maxH - 1 - 2 * y;
                } else {
                    bufY = maxH - 1 - ((nodesInDrop - (y + 1)) * 2 + 1);
                }
            } else {
                bufY = maxH - y - 1;
            }

            // Normalize coordinates to [0, 1] range
            let normalizedX = bufX / totalWidth;
            let normalizedY = bufY / maxH;

            if (!points[nodeNumber]) {
                points[nodeNumber] = [];
            }
            points[nodeNumber].push({ x: normalizedX, y: normalizedY });

            lights--;
            y++;
            nodeNumber++;
        }
    }

    return {
        rows: maxH,
        cols: totalWidth,
        points,
    };
};