import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo, logWarning } from '../../utils/logger';

class CustomModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.name = 'CustomModel';
    this.parseModelData();
  }

  parseModelData() {
    const { customModel, parm1, parm2 } = this.modelData;
    this.width = parseInt(parm1) || 1;
    this.height = parseInt(parm2) || 1;
    this.customModelData = customModel;

    logInfo('CustomModel parsed data:', {
      name: this.modelData.name,
      width: this.width,
      height: this.height,
      hasCustomModelData: !!this.customModelData,
    });
  }

  createGeometry() {
    if (!this.customModelData) {
      logWarning('Custom model data is missing for model:', this.modelData.name);
      return new THREE.BufferGeometry();
    }

    const rows = this.customModelData.split(';');
    const numRows = rows.length;
    const numCols = Math.max(...rows.map((row) => row.split(',').length));

    const positions = [];
    const colors = [];
    const pixelOrder = [];

    // First pass: collect pixel positions and their order
    rows.forEach((row, y) => {
      const values = row.split(',');
      values.forEach((value, x) => {
        if (value.trim() !== '') {
          const xPos = (x / (numCols - 1)) * this.width - this.width / 2;
          const yPos = ((numRows - 1 - y) / (numRows - 1)) * this.height - this.height / 2;
          positions.push(xPos, yPos, 0);
          pixelOrder.push(parseInt(value.trim()));
        }
      });
    });

    // Second pass: assign colors based on pixel order
    const lastPixelOrder = Math.max(...pixelOrder);
    pixelOrder.forEach((order, index) => {
      if (order === 1) {
        // First pixel (order 1) is teal
        colors.push(0, 1, 1);
      } else if (order === lastPixelOrder) {
        // Last pixel is dull red
        colors.push(0.5, 0, 0);
      } else {
        // All other pixels are white
        colors.push(1, 1, 1);
      }
    });

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    geometry.computeBoundingBox();
    geometry.computeBoundingSphere();

    return geometry;
  }

  createMesh() {
    const geometry = this.createGeometry();
    const material = new THREE.PointsMaterial({
      size: parseFloat(this.modelData.pixelSize) || 2,
      sizeAttenuation: false,
      vertexColors: true,
    });
    const points = new THREE.Points(geometry, material);

    return this.applyTransformations(points);
  }

  applyTransformations(object) {
    const { worldPos } = this.modelData.screenLocation;
    object.position.set(
      worldPos.x * this.worldScale,
      worldPos.y * this.worldScale,
      worldPos.z * this.worldScale
    );

    if (this.modelData.rotation) {
      object.rotation.set(
        THREE.MathUtils.degToRad(this.modelData.rotation.x),
        THREE.MathUtils.degToRad(this.modelData.rotation.y),
        THREE.MathUtils.degToRad(this.modelData.rotation.z)
      );
    }

    const { width, height, depth } = this.modelData.screenLocation;
    object.scale.set(
      (width || 1) * this.worldScale,
      (height || 1) * this.worldScale,
      (depth || 1) * this.worldScale
    );

    logInfo('Applied transformations to CustomModel:', {
      name: this.modelData.name,
      position: object.position,
      rotation: object.rotation,
      scale: object.scale,
      worldScale: this.worldScale,
    });

    return object;
  }
}

export default CustomModel;
