class DmxPresetAbility {
    constructor(modelData) {
        this.presetChannel = modelData.presetChannel || 0;
        this.presets = modelData.presets || [];
        this.currentPreset = null;
    }

    updateFromDmxValues(dmxValues) {
        if (this.presetChannel > 0 && this.presetChannel <= dmxValues.length) {
            const presetValue = dmxValues[this.presetChannel - 1];
            this.selectPresetByDmxValue(presetValue);
        }
    }

    selectPresetByDmxValue(value) {
        const presetIndex = Math.floor((value / 255) * this.presets.length);
        this.currentPreset = this.presets[presetIndex] || null;
    }

    applyPreset(target) {
        if (this.currentPreset) {
            Object.keys(this.currentPreset).forEach(key => {
                if (typeof target[key] === 'function') {
                    target[key](this.currentPreset[key]);
                } else if (typeof target[key] === 'object' && target[key] !== null) {
                    this.applyPreset(target[key]);
                } else {
                    target[key] = this.currentPreset[key];
                }
            });
        }
    }

    addPreset(preset) {
        this.presets.push(preset);
    }

    removePreset(index) {
        if (index >= 0 && index < this.presets.length) {
            this.presets.splice(index, 1);
        }
    }

    onPropertyChange(propertyName, value) {
        switch (propertyName) {
            case 'presetChannel':
                this.presetChannel = value;
                return true;
            case 'presets':
                this.presets = value;
                return true;
            default:
                return false;
        }
    }

    getProperties() {
        return {
            presetChannel: this.presetChannel,
            presets: this.presets,
        };
    }

    getCurrentPreset() {
        return this.currentPreset;
    }
}

export default DmxPresetAbility;