import * as THREE from 'three';
import BaseModel from './BaseModel';

class SphereModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.name = 'SphereModel';
    this.parseModelData();
  }

  parseModelData() {
    const { parm1, parm2, parm3, StartLatitude, EndLatitude, Degrees } = this.modelData;

    this.width = parseInt(parm1) || 10;
    this.height = parseInt(parm2) || 10;
    this.startLatitude = parseFloat(StartLatitude) || -86;
    this.endLatitude = parseFloat(EndLatitude) || 86;
    this.sphereDegrees = parseInt(Degrees) || 360;
  }

  createGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    const renderHeight = this.height / 1.8;
    const renderWidth = renderHeight / 1.8;

    const hRadians = THREE.MathUtils.degToRad(360);
    const hRadius = renderWidth / 2;
    const vRadius = renderHeight / 2;

    const remove = THREE.MathUtils.degToRad(360 - this.sphereDegrees);
    const fudge = THREE.MathUtils.degToRad((360 - this.sphereDegrees) / this.width);
    const hStartAngle = hRadians / 4 + 0.003 - remove / 2;
    const hAngleIncr = (-hRadians + remove - fudge) / this.width;

    const vStartAngle = THREE.MathUtils.degToRad(this.startLatitude - 90);
    const vAngleIncr =
      (THREE.MathUtils.degToRad(-this.startLatitude) + THREE.MathUtils.degToRad(this.endLatitude)) /
      (this.height - 1);

    const totalPixels = this.width * this.height;

    for (let y = 0; y < this.height; y++) {
      for (let x = 0; x < this.width; x++) {
        const hAngle = hStartAngle + x * hAngleIncr;
        const vAngle = vStartAngle + y * vAngleIncr;

        const sv = Math.sin(vAngle);
        const px = hRadius * Math.cos(hAngle) * sv;
        const pz = hRadius * Math.sin(hAngle) * sv;
        const py = vRadius * Math.cos(vAngle);

        positions.push(px, py, pz);

        const index = y * this.width + x;
        if (index === 0) {
          colors.push(0, 1, 1); // Teal color for the first pixel
        } else if (index === totalPixels - 1) {
          colors.push(0.5, 0, 0); // Dull red color for the last pixel
        } else {
          colors.push(1, 1, 1); // White color for all other pixels
        }
      }
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    return geometry;
  }

  createMesh() {
    const geometry = this.createGeometry();
    const material = new THREE.PointsMaterial({
      size: parseFloat(this.modelData.PixelSize) || 2,
      sizeAttenuation: false,
      vertexColors: true,
    });

    const points = new THREE.Points(geometry, material);
    return this.applyTransformations(points);
  }
}

export default SphereModel;
