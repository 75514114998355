import React, { useState, useRef } from 'react';
import {
  Button,
  TextField,
  Box,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
  LinearProgress,
  Paper,
} from '@mui/material';
import { Alert } from '@mui/material';
import { CloudUpload, FileCopy } from '@mui/icons-material';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { createErrorHandler, AppError } from '../utils/errorHandler';
import { logInfo, logWarning, logError } from '../utils/logger';

function FileUpload({ onUploadSuccess, user }) {
  const [files, setFiles] = useState({ networks: null, rgbeffects: null });
  const [fileNames, setFileNames] = useState({ networks: '', rgbeffects: '' });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef({ networks: null, rgbeffects: null });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleError = createErrorHandler(setSnackbar);

  const handleFileUpload = (event, fileType) => {
    const file = event.target.files[0];
    if (file) {
      if (validateFileType(file, fileType)) {
        setFiles({ ...files, [fileType]: file });
        setFileNames({ ...fileNames, [fileType]: file.name });
        logInfo(`File selected for ${fileType}: ${file.name}`);
      } else {
        handleError(
          new AppError(`Invalid file type for ${fileType}. Please upload an XML file.`, 400)
        );
      }
    }
  };

  const validateFileType = (file, fileType) => {
    const validTypes = ['application/xml', 'text/xml'];
    const isValidType = validTypes.includes(file.type) || file.name.toLowerCase().endsWith('.xml');
    if (!isValidType) {
      logWarning(`Invalid file type attempted for ${fileType}: ${file.type}`);
    }
    return isValidType;
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('networks', files.networks);
    formData.append('rgbeffects', files.rgbeffects);

    try {
      const response = await axios.post('/api/upload', formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });
      onUploadSuccess(response.data);
      setSnackbar({ open: true, message: 'Files uploaded successfully!', severity: 'success' });
      setFiles({ networks: null, rgbeffects: null });
      setFileNames({ networks: '', rgbeffects: '' });
      setUploadProgress(0);
      logInfo('Files uploaded successfully', { layoutId: response.data.id });
    } catch (error) {
      logError('Upload error', error);
      let errorMessage = 'An error occurred during file upload. Please try again.';
      if (error.response) {
        if (error.response.data && error.response.data.error) {
          errorMessage = error.response.data.error;
        } else {
          errorMessage = `Server error: ${error.response.status} ${error.response.statusText}`;
        }
      } else if (error.request) {
        errorMessage =
          'No response received from the server. Please check your internet connection and try again.';
      }
      handleError(new AppError(errorMessage, error.response ? error.response.status : 500));
      setUploadProgress(0);
    }
  };

  const triggerFileInput = (fileType) => {
    fileInputRef.current[fileType].click();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    droppedFiles.forEach((file) => {
      if (file.name.includes('networks')) {
        handleFileUpload({ target: { files: [file] } }, 'networks');
      } else if (file.name.includes('rgbeffects')) {
        handleFileUpload({ target: { files: [file] } }, 'rgbeffects');
      } else {
        logWarning(`Unrecognized file dropped: ${file.name}`);
      }
    });
  };

  if (isMobile) {
    return null;
  }

  return (
    <Box>
      <Paper
        elevation={3}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        sx={{
          p: 3,
          border: `2px dashed ${isDragging ? theme.palette.primary.main : theme.palette.divider}`,
          backgroundColor: isDragging ? theme.palette.action.hover : 'transparent',
          transition: 'all 0.3s ease',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Drag and drop your files here or click to select
        </Typography>
        {user ? (
          <Typography variant="body2" gutterBottom>
            You have uploaded {user.layouts?.length || 0} out of {user.maxLayouts || 3} allowed
            layouts.
          </Typography>
        ) : (
          <Typography variant="body2" gutterBottom color="error">
            Note: Layouts uploaded without an account will expire after 24 hours.
          </Typography>
        )}
        <Box mb={2}>
          <Button
            variant="contained"
            onClick={() => triggerFileInput('networks')}
            startIcon={<CloudUpload />}
            fullWidth
          >
            Upload xlights_networks.xml
          </Button>
          <input
            type="file"
            hidden
            ref={(el) => (fileInputRef.current.networks = el)}
            onChange={(e) => handleFileUpload(e, 'networks')}
            accept=".xml"
          />
          <TextField
            value={fileNames.networks}
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
              startAdornment: fileNames.networks && <FileCopy color="action" />,
            }}
          />
        </Box>
        <Box mb={2}>
          <Button
            variant="contained"
            onClick={() => triggerFileInput('rgbeffects')}
            startIcon={<CloudUpload />}
            fullWidth
          >
            Upload xlights_rgbeffects.xml
          </Button>
          <input
            type="file"
            hidden
            ref={(el) => (fileInputRef.current.rgbeffects = el)}
            onChange={(e) => handleFileUpload(e, 'rgbeffects')}
            accept=".xml"
          />
          <TextField
            value={fileNames.rgbeffects}
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
              startAdornment: fileNames.rgbeffects && <FileCopy color="action" />,
            }}
          />
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={!files.networks || !files.rgbeffects}
            fullWidth
          >
            Upload Files
          </Button>
        </Box>
      </Paper>
      <AnimatePresence>
        {uploadProgress > 0 && uploadProgress < 100 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <Box mt={2}>
              <Typography variant="body2" gutterBottom>
                Upload Progress: {uploadProgress}%
              </Typography>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default FileUpload;
