import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Box, IconButton, Typography, Button, Dialog, AppBar, Toolbar, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import PrintIcon from '@mui/icons-material/Print';
import { parseStarModel } from './wiringModels/starModel';
import { parseTreeModel } from './wiringModels/treeModel';
import { parseArchesModel } from './wiringModels/archesModel';
import { parseCircleModel } from './wiringModels/circleModel';
import { parseCandyCanesModel } from './wiringModels/candyCanesModel';
import { parseSpinnerModel } from './wiringModels/spinnerModel';
import { parseMatrixModel } from './wiringModels/matrixModel';
import { parseCustomModel } from './wiringModels/customModel';
import { parseCubeModel } from './wiringModels/cubeModel';
import { parseIciclesModel } from './wiringModels/iciclesModel';
import { logInfo, logWarning, logError } from '../utils/logger';
import { generateWiringDiagramPDF } from '../utils/PDFGenerator';

const MIN_FONT_SIZE = 8;
const MAX_FONT_SIZE = 24;
const FONT_SIZE_INCREMENT = 2;

const FullScreenWiringDialog = ({ model, open, onClose, layoutName }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [isRearView, setIsRearView] = useState(true);
  const [zoom, setZoom] = useState(1);
  const [pan, setPan] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [fontSize, setFontSize] = useState(14);
  const isDraggingRef = useRef(false);
  const lastPointerPosRef = useRef({ x: 0, y: 0 });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [needsRender, setNeedsRender] = useState(false);

  const parseModelData = useCallback((modelData) => {
    if (!modelData || !modelData.model) {
      logError('Model data structure is invalid:', modelData);
      return { rows: 0, cols: 0, points: {} };
    }
    logInfo('Parsing model data:', modelData);

    const actualModel = modelData.model.modelData || modelData.model;
    logInfo('Actual model data:', actualModel);
    logInfo('Model display type:', actualModel.displayAs);

    let parsedData;
    if (actualModel.displayAs === 'Star') {
      parsedData = parseStarModel(actualModel);
    } else if (actualModel.displayAs && actualModel.displayAs.startsWith('Tree')) {
      parsedData = parseTreeModel(actualModel);
    } else if (actualModel.customModel) {
      parsedData = parseCustomModel(actualModel);
    } else {
      parsedData = parseOtherModelTypes(actualModel);
    }
    logInfo('Parsed model data:', parsedData);
    return parsedData;
  }, []);

  const parseOtherModelTypes = (modelData) => {
    switch (modelData.displayAs) {
      case 'Tree 360':
      case 'Tree 270':
      case 'Tree Flat':
        return parseTreeModel(modelData);
      case 'Arches':
        return parseArchesModel(modelData);
      case 'Circle':
        return parseCircleModel(modelData);
      case 'Candy Canes':
        return parseCandyCanesModel(modelData);
      case 'Spinner':
        return parseSpinnerModel(modelData);
      case 'Star':
        return parseStarModel(modelData);
      case 'Matrix':
      case 'Vert Matrix':
      case 'Horiz Matrix':
        return parseMatrixModel(modelData);
      case 'Cube':
        return parseCubeModel(modelData);
      case 'Icicles':
        return parseIciclesModel(modelData);
      default:
        logWarning('Unsupported model type:', modelData.displayAs);
        return { rows: 0, cols: 0, points: {} };
    }
  };

  const memoizedModelData = useMemo(() => parseModelData(model), [model, parseModelData]);

  const fitDiagramToCanvas = useCallback((modelData, canvasWidth, canvasHeight) => {
    const { rows, cols } = modelData;
    if (rows === 0 || cols === 0) return { zoom: 1, pan: { x: 0, y: 0 } };

    const modelAspectRatio = cols / rows;
    const canvasAspectRatio = canvasWidth / canvasHeight;

    let newZoom, viewWidth, viewHeight;

    if (modelAspectRatio > canvasAspectRatio) {
      viewWidth = canvasWidth * 0.9;
      newZoom = viewWidth / cols;
    } else {
      viewHeight = canvasHeight * 0.9;
      newZoom = viewHeight / rows;
    }

    const newPan = {
      x: (canvasWidth - cols * newZoom) / 2,
      y: (canvasHeight - rows * newZoom) / 2,
    };

    return { zoom: newZoom, pan: newPan };
  }, []);

  const renderWiringDiagram = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas || !model) {
      logInfo('Canvas or model not available, skipping render');
      return;
    }

    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;

    logInfo('Rendering wiring diagram', { width, height, zoom, pan, rotation });

    // Clear canvas
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, width, height);

    // Apply transformations
    ctx.save();
    ctx.translate(pan.x, pan.y);
    ctx.scale(zoom, zoom);
    ctx.rotate((rotation * Math.PI) / 180);

    // Draw wiring diagram
    const { rows, cols, points } = memoizedModelData;
    if (rows === 0 || cols === 0 || !points || Object.keys(points).length === 0) {
      logError('Invalid model data, unable to render', { rows, cols, pointsCount: Object.keys(points).length });
      return;
    }

    logInfo('Drawing wiring diagram', { rows, cols, pointsCount: Object.keys(points).length });

    ctx.strokeStyle = 'yellow';
    ctx.lineWidth = 1 / zoom;

    Object.entries(points).forEach(([nodeNumber, nodePoints], index, arr) => {
      nodePoints.forEach((point) => {
        const x = (isRearView ? cols - point.x - 1 : point.x);
        const y = rows - point.y - 1;

        ctx.beginPath();
        ctx.arc(x, y, 2 / zoom, 0, 2 * Math.PI);
        ctx.fillStyle = 'white';
        ctx.fill();

        ctx.fillStyle = 'white';
        ctx.font = `${fontSize / zoom}px Arial`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const verticalOffset = -0.85;
        ctx.fillText(nodeNumber, x, y + verticalOffset);
      });

      if (index < arr.length - 1) {
        const start = nodePoints[nodePoints.length - 1];
        const end = arr[index + 1][1][0];
        ctx.beginPath();
        ctx.moveTo(isRearView ? cols - start.x - 1 : start.x, rows - start.y - 1);
        ctx.lineTo(isRearView ? cols - end.x - 1 : end.x, rows - end.y - 1);
        ctx.stroke();
      }
    });

    ctx.restore();
    logInfo('Wiring diagram rendered successfully');
  }, [model, memoizedModelData, isRearView, zoom, pan, rotation, fontSize]);

  const handlePrint = useCallback(() => {
    const modelName = model.name || 'Unknown Model';
    const fileName = `${layoutName} - ${modelName}.pdf`;

    // Prepare the wiring data
    const wiringData = {
      width: memoizedModelData.cols,
      height: memoizedModelData.rows,
      nodes: Object.entries(memoizedModelData.points).map(([nodeNumber, points]) => ({
        number: parseInt(nodeNumber),
        x: points[0].x,
        y: points[0].y
      }))
    };

    generateWiringDiagramPDF(wiringData, fileName, isRearView, Number(rotation), modelName, layoutName)
      .then(() => {
        logInfo('PDF generated successfully');
        setSnackbarMessage('PDF generated successfully');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        logError('Error generating PDF:', error);
        setSnackbarMessage('Error generating PDF. Please try again.');
        setSnackbarOpen(true);
      });
  }, [model, memoizedModelData, isRearView, rotation, layoutName]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const centerDiagram = useCallback(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const { zoom: newZoom, pan: newPan } = fitDiagramToCanvas(
        memoizedModelData,
        canvas.width,
        canvas.height
      );
      setZoom(newZoom);
      setPan(newPan);
      logInfo('Diagram centered');
    }
  }, [memoizedModelData, fitDiagramToCanvas]);

  const handleResize = useCallback(() => {
    if (canvasRef.current && containerRef.current) {
      const canvas = canvasRef.current;
      canvas.width = containerRef.current.clientWidth;
      canvas.height = containerRef.current.clientHeight;
      logInfo('Canvas size set:', { width: canvas.width, height: canvas.height });
      centerDiagram();
    }
  }, [centerDiagram]);

  useEffect(() => {
    if (open) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, open]);

  useEffect(() => {
    if (open) {
      renderWiringDiagram();
    }
  }, [renderWiringDiagram, open]);

  useEffect(() => {
    if (needsRender) {
      renderWiringDiagram();
      setNeedsRender(false);
    }
  }, [needsRender, renderWiringDiagram]);

  const handleToggleView = useCallback(() => {
    setIsRearView(prev => !prev);
    setNeedsRender(true);
    logInfo('View toggled');
  }, []);

  const handleZoom = useCallback((delta) => {
    setZoom(prevZoom => {
      const newZoom = Math.max(0.1, prevZoom * (1 + delta));
      logInfo('Zoom changed:', newZoom);
      return newZoom;
    });
    setNeedsRender(true);  // Add this line to trigger a re-render
  }, []);

  const handleRotate = useCallback(() => {
    setRotation(prevRotation => (prevRotation + 90) % 360);
    setNeedsRender(true);
    logInfo('Rotation changed');
  }, []);

  const handleReset = useCallback(() => {
    centerDiagram();
    setRotation(0);
    setNeedsRender(true);
    logInfo('View reset');
  }, [centerDiagram]);

  const handleFontSize = useCallback((increase) => {
    setFontSize(prevSize => {
      const newSize = increase ? prevSize + FONT_SIZE_INCREMENT : prevSize - FONT_SIZE_INCREMENT;
      return Math.min(MAX_FONT_SIZE, Math.max(MIN_FONT_SIZE, newSize));
    });
    setNeedsRender(true);
    logInfo('Font size adjusted');
  }, []);

  const handlePointerDown = useCallback((event) => {
    event.preventDefault();
    isDraggingRef.current = true;
    const rect = canvasRef.current.getBoundingClientRect();
    lastPointerPosRef.current = {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top
    };
    logInfo('Pointer down:', lastPointerPosRef.current);
  }, []);

  const handlePointerMove = useCallback((event) => {
    if (isDraggingRef.current) {
      const rect = canvasRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const dx = (x - lastPointerPosRef.current.x) / zoom;
      const dy = (y - lastPointerPosRef.current.y) / zoom;
      setPan(prevPan => {
        const newPan = {
          x: prevPan.x + dx,
          y: prevPan.y + dy,
        };
        logInfo('Pan updated:', newPan);
        return newPan;
      });
      lastPointerPosRef.current = { x, y };
      setNeedsRender(true);
    }
  }, [zoom]);

  const handlePointerUp = useCallback(() => {
    isDraggingRef.current = false;
    logInfo('Pointer up');
  }, []);

  const handleWheel = useCallback((event) => {
    event.preventDefault();
    const rect = canvasRef.current.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const mouseY = event.clientY - rect.top;

    const delta = event.deltaY > 0 ? -0.1 : 0.1;
    const newZoom = Math.max(0.1, zoom * (1 + delta));

    // Adjust pan to keep the point under the mouse in the same position
    const panX = pan.x - (mouseX / zoom - mouseX / newZoom);
    const panY = pan.y - (mouseY / zoom - mouseY / newZoom);

    setZoom(newZoom);
    setPan({ x: panX, y: panY });
    setNeedsRender(true);
    logInfo('Wheel event:', { delta, newZoom, panX, panY });
  }, [zoom, pan]);

  const handleTouchStart = useCallback((event) => {
    if (event.touches.length === 2) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const distance = Math.hypot(touch1.clientX - touch2.clientX, touch1.clientY - touch2.clientY);
      lastPointerPosRef.current = { distance };
    }
  }, []);

  const handleTouchMove = useCallback((event) => {
    if (event.touches.length === 2) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const distance = Math.hypot(touch1.clientX - touch2.clientX, touch1.clientY - touch2.clientY);
      const delta = (distance - lastPointerPosRef.current.distance) / 100;
      handleZoom(delta);
      lastPointerPosRef.current = { distance };
    }
  }, [handleZoom]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.addEventListener('wheel', handleWheel, { passive: false });
      canvas.addEventListener('pointerdown', handlePointerDown);
      canvas.addEventListener('pointermove', handlePointerMove);
      canvas.addEventListener('pointerup', handlePointerUp);
      canvas.addEventListener('pointerleave', handlePointerUp);
      canvas.addEventListener('touchstart', handleTouchStart);
      canvas.addEventListener('touchmove', handleTouchMove);
      return () => {
        canvas.removeEventListener('wheel', handleWheel);
        canvas.removeEventListener('pointerdown', handlePointerDown);
        canvas.removeEventListener('pointermove', handlePointerMove);
        canvas.removeEventListener('pointerup', handlePointerUp);
        canvas.removeEventListener('pointerleave', handlePointerUp);
        canvas.removeEventListener('touchstart', handleTouchStart);
        canvas.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [handleWheel, handlePointerDown, handlePointerMove, handlePointerUp, handleTouchStart, handleTouchMove]);

  // ... (keep the rest of the component unchanged)

  return (
    <>
      <Dialog fullScreen open={open} onClose={onClose}>
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ marginLeft: 2, flex: 1 }}>
              Wiring Diagram: {layoutName} - {model.name}
            </Typography>
            <Button color="inherit" onClick={handleToggleView}>
              FLIP VIEW
            </Button>
            <Button color="inherit" onClick={() => handleZoom(0.1)}>
              <ZoomInIcon />
            </Button>
            <Button color="inherit" onClick={() => handleZoom(-0.1)}>
              <ZoomOutIcon />
            </Button>
            <Button color="inherit" onClick={() => handleFontSize(true)}>
              FONT +
            </Button>
            <Button color="inherit" onClick={() => handleFontSize(false)}>
              FONT -
            </Button>
            <Button color="inherit" onClick={handleRotate}>
              ROTATE
            </Button>
            <Button color="inherit" onClick={handleReset}>
              RESET VIEW
            </Button>
            <Button color="inherit" onClick={handlePrint} startIcon={<PrintIcon />}>
              PRINT PDF
            </Button>
          </Toolbar>
        </AppBar>
        <Box
          ref={containerRef}
          sx={{
            width: '100%',
            height: 'calc(100% - 64px)',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <canvas
            ref={canvasRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              touchAction: 'none',
            }}
          />
          <Typography
            sx={{
              position: 'absolute',
              top: 10,
              left: 10,
              color: 'white',
              fontSize: '14px',
              pointerEvents: 'none',
            }}
          >
            {isRearView ? 'CAUTION: Reverse view' : 'CAUTION: Front view'}
            <br />
            Layout: {layoutName}
            <br />
            Model: {model.name}
            <br />
            Rotation: {rotation}°
            <br />
            Pan: ({pan.x.toFixed(2)}, {pan.y.toFixed(2)})
            <br />
            Zoom: {zoom.toFixed(2)}
          </Typography>
        </Box>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </>
  );
};

export default FullScreenWiringDialog;
