import * as THREE from 'three';

class BaseModel {
  constructor(modelData) {
    this.modelData = modelData;
    this.worldScale = 1; // Global scaling factor
    this.layoutGroup = modelData.layoutGroup || 'Default';
    this.chainedTo = modelData.modelChain || null;
  }

  setWorldScale(scale) {
    this.worldScale = scale;
  }

  applyTransformations(object) {
    const { worldPos } = this.modelData.screenLocation || {};
    const { x: rotateX = 0, y: rotateY = 0, z: rotateZ = 0 } = this.modelData.rotation || {};

    // Apply position
    if (worldPos) {
      object.position.set(
        (worldPos.x || 0) * this.worldScale,
        (worldPos.y || 0) * this.worldScale,
        (worldPos.z || 0) * this.worldScale
      );
    }

    // Apply rotation in degrees
    object.rotation.set(
      THREE.MathUtils.degToRad(rotateX),
      THREE.MathUtils.degToRad(rotateY),
      THREE.MathUtils.degToRad(rotateZ)
    );

    // Apply scale
    const { width, height, depth } = this.modelData.screenLocation || {};
    object.scale.set(
      (width || 1) * this.worldScale,
      (height || 1) * this.worldScale,
      (depth || 1) * this.worldScale
    );

    // Debugging: log transformation matrix
    object.updateMatrixWorld(); // Ensure matrix is updated
    console.log(`Transformations applied for ${this.modelData.name}:`, object.matrixWorld);

    return object;
  }

  createGeometry() {
    // Default implementation for unknown model types
    console.warn(`Using default geometry for unknown model type: ${this.modelData.displayAs}`);
    return new THREE.BoxGeometry(1, 1, 1);
  }

  createBoundingBox(mesh) {
    const bbox = new THREE.Box3().setFromObject(mesh);
    const boxGeometry = new THREE.BoxGeometry(
      bbox.max.x - bbox.min.x,
      bbox.max.y - bbox.min.y,
      bbox.max.z - bbox.min.z
    );
    const boxMaterial = new THREE.MeshBasicMaterial({ visible: false });
    const boundingBox = new THREE.Mesh(boxGeometry, boxMaterial);
    boundingBox.position.copy(bbox.getCenter(new THREE.Vector3()));
    boundingBox.userData = mesh.userData;
    return boundingBox;
  }

  createMesh() {
    const geometry = this.createGeometry();
    const material = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      wireframe: true,
    });
    const mesh = new THREE.Mesh(geometry, material);

    return this.applyTransformations(mesh);
  }
}

export default BaseModel;
