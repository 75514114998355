import * as THREE from 'three';
import BaseModel from './BaseModel';

class IciclesModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.name = 'IciclesModel';
    this.parseModelData();
  }

  parseModelData() {
    const {
      parm1,
      parm2,
      Dir,
      DropPattern,
      AlternateNodes,
      PixelSize,
      screenLocation,
      rotation,
    } = this.modelData;

    this.numStrings = parseInt(parm1) || 1;
    this.lightsPerString = parseInt(parm2) || 50;
    this.isLtoR = Dir !== 'R';
    this.dropPattern = DropPattern ? DropPattern.split(',').map(Number) : [3, 4, 5, 4];
    this.alternateNodes = AlternateNodes === 'true';
    this.pixelSize = parseFloat(PixelSize) || 2;
    this.totalLights = this.numStrings * this.lightsPerString;

    this.worldPos = new THREE.Vector3(
      screenLocation.worldPos.x,
      screenLocation.worldPos.y,
      screenLocation.worldPos.z
    );
    this.endPos = new THREE.Vector3(
      screenLocation.endPos.x,
      screenLocation.endPos.y,
      screenLocation.endPos.z
    );
    this.width = Math.abs(this.endPos.x - this.worldPos.x) || screenLocation.width || 1;
    this.height = Math.abs(this.endPos.y - this.worldPos.y) || screenLocation.height || 1;
    this.depth = Math.abs(this.endPos.z - this.worldPos.z) || screenLocation.depth || 1;
    this.rotation = new THREE.Vector3(
      THREE.MathUtils.degToRad(rotation.x),
      THREE.MathUtils.degToRad(rotation.y),
      THREE.MathUtils.degToRad(rotation.z)
    );

    console.log('Icicles parameters:', {
      numStrings: this.numStrings,
      lightsPerString: this.lightsPerString,
      isLtoR: this.isLtoR,
      dropPattern: this.dropPattern,
      alternateNodes: this.alternateNodes,
      pixelSize: this.pixelSize,
      totalLights: this.totalLights,
      worldPos: this.worldPos,
      endPos: this.endPos,
      width: this.width,
      height: this.height,
      depth: this.depth,
      rotation: this.rotation,
    });
  }

  createGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    let maxH = Math.max(...this.dropPattern);
    let totalWidth = this.numStrings + this.dropPattern.length - 1;
    let curNode = 0;

    for (let x = 0; x < this.numStrings; x++) {
      let lights = this.lightsPerString;
      let y = 0;
      let curDrop = 0;
      let width = x + curDrop;

      while (lights > 0) {
        while (y >= this.dropPattern[curDrop]) {
          width++;
          y = 0;
          curDrop = (curDrop + 1) % this.dropPattern.length;
        }

        let bufX = this.isLtoR ? width : totalWidth - width - 1;
        let bufY;
        if (this.alternateNodes) {
          let nodesInDrop = this.dropPattern[curDrop];
          if (y + 1 <= (nodesInDrop + 1) / 2) {
            bufY = maxH - 1 - 2 * y;
          } else {
            bufY = maxH - 1 - ((nodesInDrop - (y + 1)) * 2 + 1);
          }
        } else {
          bufY = maxH - y - 1;
        }

        // Normalize coordinates to [-0.5, 0.5] range
        let normalizedX = (bufX / totalWidth) - 0.5;
        let normalizedY = (bufY / maxH) - 0.5;

        positions.push(normalizedX, normalizedY, 0);

        if (curNode === 0) {
          colors.push(0, 1, 1); // Teal color for the first light
        } else if (curNode === this.totalLights - 1) {
          colors.push(0.5, 0, 0); // Dull red color for the last light
        } else {
          colors.push(1, 1, 1); // White color for all other lights
        }

        lights--;
        y++;
        curNode++;
      }
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    return geometry;
  }

  createMesh() {
    const geometry = this.createGeometry();
    const material = new THREE.PointsMaterial({
      size: this.pixelSize,
      sizeAttenuation: false,
      vertexColors: true,
    });

    const points = new THREE.Points(geometry, material);
    return this.applyTransformations(points);
  }

  applyTransformations(object) {
    object.position.copy(this.worldPos);
    object.scale.set(this.width, this.height, this.depth);
    object.rotation.setFromVector3(this.rotation);

    console.log('Applied transformations:', {
      position: object.position,
      scale: object.scale,
      rotation: object.rotation,
    });

    return object;
  }
}

export default IciclesModel;
