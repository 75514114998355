import axios from 'axios';

export const getModels = () => axios.get('/api/models');
export const updateModelPosition = (modelName, x, y, z) =>
  axios.put(`/api/models/${modelName}`, { x, y, z });
export const getLayout = (layoutId) => axios.get(`/api/layout/${layoutId}`);

// Auth functions
export const login = async (email, password) => {
  const response = await axios.post('/auth/login', { email, password });
  return response.data;
};

export const register = async (userData) => {
  const response = await axios.post('/auth/register', userData);
  return response.data;
};

export const forgotPassword = async (email) => {
  const response = await axios.post('/auth/forgot-password', { email });
  return response.data;
};

export const resetPassword = async (token, password) => {
  const response = await axios.post(`/auth/reset-password/${token}`, { password });
  return response.data;
};

export const resendVerificationEmail = async (email) => {
  const response = await axios.post('/auth/resend-verification', { email });
  return response.data;
};
