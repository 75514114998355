import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Box,
  Snackbar,
  useMediaQuery,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  Paper,
} from '@mui/material';
import { Alert } from '@mui/material';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { motion, AnimatePresence } from 'framer-motion';

const BugReportForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    type: 'bug',
    description: '',
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [captchaToken, setCaptchaToken] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const savedForm = localStorage.getItem('bugReportForm');
    if (savedForm) {
      setFormData(JSON.parse(savedForm));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    localStorage.setItem('bugReportForm', JSON.stringify({ ...formData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaToken) {
      setSnackbar({ open: true, message: 'Please complete the reCAPTCHA', severity: 'error' });
      return;
    }
    try {
      await axios.post('/api/report', { ...formData, captchaToken });
      setSnackbar({ open: true, message: 'Report submitted successfully!', severity: 'success' });
      setFormData({ name: '', email: '', type: 'bug', description: '' });
      setCaptchaToken(null);
      localStorage.removeItem('bugReportForm');
    } catch (error) {
      console.error('Error submitting report:', error);
      setSnackbar({
        open: true,
        message: 'Error submitting report. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const steps = ['Personal Info', 'Report Type', 'Description', 'Verification'];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              size={isMobile ? 'small' : 'medium'}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              size={isMobile ? 'small' : 'medium'}
            />
          </>
        );
      case 1:
        return (
          <RadioGroup name="type" value={formData.type} onChange={handleChange}>
            <FormControlLabel value="bug" control={<Radio />} label="Bug Report" />
            <FormControlLabel value="feature" control={<Radio />} label="Feature Request" />
          </RadioGroup>
        );
      case 2:
        return (
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            name="description"
            multiline
            rows={isMobile ? 3 : 4}
            value={formData.description}
            onChange={handleChange}
            required
            size={isMobile ? 'small' : 'medium'}
          />
        );
      case 3:
        return (
          <ReCAPTCHA
            sitekey="6LfN_SAqAAAAADXeHABtwaDgDQe_Su0PdHl-Hmqr"
            onChange={handleCaptchaChange}
            size={isMobile ? 'compact' : 'normal'}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 64px)', // Adjust this value based on your app bar height
        mt: 4, // Add top margin
      }}
    >
      <Box maxWidth={isMobile ? '95%' : 500} width="100%" margin="auto">
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant={isMobile ? 'h5' : 'h4'} gutterBottom align="center">
            Submit a Bug Report or Feature Request
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box my={3}>
            <AnimatePresence mode="wait">
              <motion.div
                key={activeStep}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{ duration: 0.3 }}
              >
                {getStepContent(activeStep)}
              </motion.div>
            </AnimatePresence>
          </Box>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
              disabled={activeStep === steps.length - 1 && !captchaToken}
            >
              {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
            </Button>
          </Box>
        </Paper>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BugReportForm;
