import * as THREE from 'three';
import DmxModel from './DmxModel';
import DmxServo from './DmxServo';

class DmxSkull extends DmxModel {
    constructor(modelData) {
        super(modelData);
        this.displayAs = "DmxSkull";

        this.jawServo = new DmxServo({
            channel: modelData.jawChannel || 0,
            minAngle: 0,
            maxAngle: 45,
            reverse: true, // Assuming 0 is closed and 45 is open
        });

        this.eyeChannel = modelData.eyeChannel || 0;
        this.eyeColor = new THREE.Color(1, 1, 1); // Default white
        this.eyeIntensity = 0;
    }

    createMesh() {
        const group = new THREE.Group();

        // Create skull
        const skullGeometry = new THREE.SphereGeometry(0.5, 32, 32, 0, Math.PI * 2, 0, Math.PI * 0.8);
        const skullMaterial = new THREE.MeshPhongMaterial({ color: 0xf0f0f0 });
        const skullMesh = new THREE.Mesh(skullGeometry, skullMaterial);
        group.add(skullMesh);

        // Create jaw
        const jawGeometry = new THREE.SphereGeometry(0.4, 32, 16, 0, Math.PI * 2, Math.PI * 0.6, Math.PI * 0.3);
        const jawMaterial = new THREE.MeshPhongMaterial({ color: 0xe0e0e0 });
        this.jawMesh = new THREE.Mesh(jawGeometry, jawMaterial);
        this.jawMesh.position.y = -0.2;
        group.add(this.jawMesh);

        // Create eyes
        const eyeGeometry = new THREE.SphereGeometry(0.1, 16, 16);
        const eyeMaterial = new THREE.MeshPhongMaterial({ color: 0xffffff, emissive: 0xffffff });
        this.leftEyeMesh = new THREE.Mesh(eyeGeometry, eyeMaterial.clone());
        this.rightEyeMesh = new THREE.Mesh(eyeGeometry, eyeMaterial.clone());
        this.leftEyeMesh.position.set(-0.2, 0.1, 0.4);
        this.rightEyeMesh.position.set(0.2, 0.1, 0.4);
        group.add(this.leftEyeMesh);
        group.add(this.rightEyeMesh);

        return group;
    }

    updateFromDmxValues(dmxValues) {
        super.updateFromDmxValues(dmxValues);

        // Update jaw
        this.jawServo.updateFromDmxValues(dmxValues);

        // Update eyes
        if (this.eyeChannel > 0 && this.eyeChannel <= dmxValues.length) {
            const eyeDmxValue = dmxValues[this.eyeChannel - 1];
            this.updateEyes(eyeDmxValue);
        }

        this.updateMesh();
    }

    updateEyes(dmxValue) {
        // Example eye effect: change color and intensity based on DMX value
        this.eyeIntensity = dmxValue / 255;

        // Change color from red (low values) to blue (high values)
        const hue = (dmxValue / 255) * 0.7; // 0.7 is the hue for blue in THREE.js
        this.eyeColor.setHSL(hue, 1, 0.5);
    }

    updateMesh() {
        if (this.mesh) {
            // Update jaw position
            const jawRotation = THREE.MathUtils.degToRad(this.jawServo.currentAngle);
            this.jawMesh.rotation.x = jawRotation;

            // Update eye color and intensity
            const eyeEmissiveIntensity = this.eyeIntensity * 0.5; // Adjust for desired brightness
            this.leftEyeMesh.material.emissive.copy(this.eyeColor).multiplyScalar(eyeEmissiveIntensity);
            this.rightEyeMesh.material.emissive.copy(this.eyeColor).multiplyScalar(eyeEmissiveIntensity);
        }
    }

    onPropertyChange(propertyName, value) {
        switch (propertyName) {
            case 'jawChannel':
                this.jawServo.onPropertyChange('channel', value);
                return true;
            case 'eyeChannel':
                this.eyeChannel = parseInt(value);
                return true;
            default:
                return super.onPropertyChange(propertyName, value);
        }
    }

    getProperties() {
        return {
            ...super.getProperties(),
            jawChannel: this.jawServo.channel,
            eyeChannel: this.eyeChannel,
        };
    }
}

export default DmxSkull;