import React, { createContext, useState, useContext } from 'react';

const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
  const [layout, setLayout] = useState(null);

  const setLayoutWithLog = (newLayout) => {
    console.log('Setting new layout:', newLayout);
    setLayout(newLayout);
  };

  return (
    <LayoutContext.Provider value={{ layout, setLayout: setLayoutWithLog }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};
