import * as THREE from 'three';
import DmxModel from './DmxModel';

class DmxImage extends DmxModel {
    constructor(modelData) {
        super(modelData);
        this.displayAs = "DmxImage";
        this.imageFile = modelData.imageFile || "";
        this.width = 1;
        this.height = 1;
        this.offsetX = modelData.offsetX || 0;
        this.offsetY = modelData.offsetY || 0;
        this.offsetZ = modelData.offsetZ || 0;
        this.scaleX = modelData.scaleX || 1;
        this.scaleY = modelData.scaleY || 1;
        this.scaleZ = modelData.scaleZ || 1;
        this.rotateX = modelData.rotateX || 0;
        this.rotateY = modelData.rotateY || 0;
        this.rotateZ = modelData.rotateZ || 0;
        this.texture = null;
    }

    async loadImage() {
        return new Promise((resolve, reject) => {
            new THREE.TextureLoader().load(
                this.imageFile,
                (texture) => {
                    this.texture = texture;
                    this.width = texture.image.width;
                    this.height = texture.image.height;
                    resolve(texture);
                },
                undefined,
                (error) => reject(error)
            );
        });
    }

    async createMesh() {
        if (!this.texture) {
            await this.loadImage();
        }

        const geometry = new THREE.PlaneGeometry(1, 1);
        const material = new THREE.MeshBasicMaterial({
            map: this.texture,
            transparent: true,
            side: THREE.DoubleSide
        });
        const mesh = new THREE.Mesh(geometry, material);

        // Apply transformations
        mesh.position.set(this.offsetX, this.offsetY, this.offsetZ);
        mesh.scale.set(this.scaleX, this.scaleY, this.scaleZ);
        mesh.rotation.set(
            THREE.MathUtils.degToRad(this.rotateX),
            THREE.MathUtils.degToRad(this.rotateY),
            THREE.MathUtils.degToRad(this.rotateZ)
        );

        return mesh;
    }

    updateFromDmxValues(dmxValues) {
        super.updateFromDmxValues(dmxValues);
        // Implement DMX control for image properties if needed
    }

    setImageFile(file) {
        this.imageFile = file;
        this.loadImage().then(() => {
            if (this.mesh) {
                this.mesh.material.map = this.texture;
                this.mesh.material.needsUpdate = true;
            }
        });
    }

    setOffset(x, y, z) {
        this.offsetX = x;
        this.offsetY = y;
        this.offsetZ = z;
        if (this.mesh) {
            this.mesh.position.set(x, y, z);
        }
    }

    setScale(x, y, z) {
        this.scaleX = x;
        this.scaleY = y;
        this.scaleZ = z;
        if (this.mesh) {
            this.mesh.scale.set(x, y, z);
        }
    }

    setRotation(x, y, z) {
        this.rotateX = x;
        this.rotateY = y;
        this.rotateZ = z;
        if (this.mesh) {
            this.mesh.rotation.set(
                THREE.MathUtils.degToRad(x),
                THREE.MathUtils.degToRad(y),
                THREE.MathUtils.degToRad(z)
            );
        }
    }

    onPropertyChange(propertyName, value) {
        switch (propertyName) {
            case 'imageFile':
                this.setImageFile(value);
                return true;
            case 'offsetX':
            case 'offsetY':
            case 'offsetZ':
                this.setOffset(
                    propertyName === 'offsetX' ? value : this.offsetX,
                    propertyName === 'offsetY' ? value : this.offsetY,
                    propertyName === 'offsetZ' ? value : this.offsetZ
                );
                return true;
            case 'scaleX':
            case 'scaleY':
            case 'scaleZ':
                this.setScale(
                    propertyName === 'scaleX' ? value : this.scaleX,
                    propertyName === 'scaleY' ? value : this.scaleY,
                    propertyName === 'scaleZ' ? value : this.scaleZ
                );
                return true;
            case 'rotateX':
            case 'rotateY':
            case 'rotateZ':
                this.setRotation(
                    propertyName === 'rotateX' ? value : this.rotateX,
                    propertyName === 'rotateY' ? value : this.rotateY,
                    propertyName === 'rotateZ' ? value : this.rotateZ
                );
                return true;
            default:
                return super.onPropertyChange(propertyName, value);
        }
    }

    getProperties() {
        return {
            ...super.getProperties(),
            imageFile: this.imageFile,
            offsetX: this.offsetX,
            offsetY: this.offsetY,
            offsetZ: this.offsetZ,
            scaleX: this.scaleX,
            scaleY: this.scaleY,
            scaleZ: this.scaleZ,
            rotateX: this.rotateX,
            rotateY: this.rotateY,
            rotateZ: this.rotateZ
        };
    }
}

export default DmxImage;