import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Snackbar,
  Alert,
  Tooltip,
  LinearProgress,
  TextField,
  Box,
  Link,
} from '@mui/material';
import { generatePDF, generateVisualizerPDF } from '../utils/PDFGenerator';
import { logError, logInfo } from '../utils/logger';

const PrintDialog = ({ open, onClose, controllers, onPrint, layout, scene, camera }) => {
  const [selectedControllers, setSelectedControllers] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [includeDXF, setIncludeDXF] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState('');
  const [fileName, setFileName] = useState('layout.pdf');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const initialState = controllers.reduce((acc, controller) => {
      acc[controller.name] = false;
      return acc;
    }, {});
    setSelectedControllers(initialState);
  }, [controllers]);

  const handleToggle = (controllerName) => {
    setSelectedControllers((prev) => ({
      ...prev,
      [controllerName]: !prev[controllerName],
    }));
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedControllers(
      Object.keys(selectedControllers).reduce((acc, key) => {
        acc[key] = newSelectAll;
        return acc;
      }, {})
    );
  };

  const updateProgress = (value, message) => {
    setProgress(value);
    setProgressMessage(message);
  };

  const validateFileName = (name) => {
    if (!name) {
      return 'File name is required';
    }
    if (!name.toLowerCase().endsWith('.pdf')) {
      return 'File name must end with .pdf';
    }
    return '';
  };

  const handlePreview = async () => {
    try {
      setIsLoading(true);
      setError(null);
      setProgress(0);
      setProgressMessage('');
      const controllersToPrint = Object.keys(selectedControllers).filter(
        (key) => selectedControllers[key]
      );
      const filteredLayout = {
        ...layout,
        controllers: layout.controllers.filter((c) => controllersToPrint.includes(c.name)),
        portMapping: Object.fromEntries(
          Object.entries(layout.portMapping).filter(([key]) => controllersToPrint.includes(key))
        ),
      };
      if (includeDXF) {
        logInfo('Starting DXF PDF generation for preview');
        await generateVisualizerPDF(scene, camera, filteredLayout, true, updateProgress, fileName);
        logInfo('DXF PDF generation completed for preview');
      } else {
        logInfo('Starting regular PDF generation for preview');
        const pdfBlob = await generatePDF(filteredLayout, true, updateProgress);
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
        logInfo('Regular PDF generation completed for preview');
      }
    } catch (error) {
      logError('Error generating PDF for preview:', error);
      setError(`Failed to generate PDF: ${error.message}`);
    } finally {
      setIsLoading(false);
      setProgress(0);
      setProgressMessage('');
    }
  };

  const handlePrint = async () => {
    const fileNameError = validateFileName(fileName);
    if (fileNameError) {
      setError(fileNameError);
      return;
    }
    try {
      setIsLoading(true);
      setError(null);
      setProgress(0);
      setProgressMessage('');
      const controllersToPrint = Object.keys(selectedControllers).filter(
        (key) => selectedControllers[key]
      );
      const filteredLayout = {
        ...layout,
        controllers: layout.controllers.filter((c) => controllersToPrint.includes(c.name)),
        portMapping: Object.fromEntries(
          Object.entries(layout.portMapping).filter(([key]) => controllersToPrint.includes(key))
        ),
      };
      if (includeDXF) {
        logInfo('Starting DXF PDF generation for export');
        await generateVisualizerPDF(scene, camera, filteredLayout, includeDXF, updateProgress, fileName);
        logInfo('DXF PDF generation completed for export');
      } else {
        logInfo('Starting regular PDF generation for print');
        await generatePDF(filteredLayout, false, updateProgress, fileName);
        logInfo('Regular PDF generation completed for print');
      }
      onClose();
    } catch (error) {
      logError('Error generating PDF for print/export:', error);
      if (includeDXF && error.message.includes('DXF')) {
        setError(`Failed to generate DXF: ${error.message}. Please try adjusting your view or simplifying the layout.`);
      } else {
        setError(`Failed to generate PDF: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
      setProgress(0);
      setProgressMessage('');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          bgcolor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        },
      }}
    >
      <DialogTitle sx={{ bgcolor: theme.palette.background.paper, color: theme.palette.text.primary }}>
        Select Controllers to Print
      </DialogTitle>
      <DialogContent sx={{ bgcolor: theme.palette.background.paper, color: theme.palette.text.primary }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAll}
              sx={{
                color: theme.palette.primary.main,
                '&.Mui-checked': {
                  color: theme.palette.primary.main,
                },
              }}
            />
          }
          label="Select All"
        />
        {isMobile ? (
          <List>
            {controllers.map((controller) => (
              <ListItem
                key={controller.name}
                dense
                button
                onClick={() => handleToggle(controller.name)}
              >
                <Checkbox
                  edge="start"
                  checked={selectedControllers[controller.name] || false}
                  tabIndex={-1}
                  disableRipple
                  sx={{
                    color: theme.palette.primary.main,
                    '&.Mui-checked': {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
                <ListItemText primary={controller.name} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Grid container spacing={2}>
            {controllers.map((controller) => (
              <Grid item xs={12} sm={6} md={3} key={controller.name}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedControllers[controller.name] || false}
                      onChange={() => handleToggle(controller.name)}
                      sx={{
                        color: theme.palette.primary.main,
                        '&.Mui-checked': {
                          color: theme.palette.primary.main,
                        },
                      }}
                    />
                  }
                  label={controller.name}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Tooltip title="Export layout as DXF format. This feature is experimental and may not work perfectly for all layouts.">
          <FormControlLabel
            control={
              <Checkbox
                checked={includeDXF}
                onChange={(e) => setIncludeDXF(e.target.checked)}
                sx={{
                  color: theme.palette.primary.main,
                  '&.Mui-checked': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
            }
            label="Include DXF data (Experimental)"
          />
        </Tooltip>
        {includeDXF && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Note: The DXF export will be scaled to fit on an A4 size paper for easy printing.
          </Typography>
        )}
        <Box mt={2}>
          <Typography variant="body2">
            Learn more about DXF export in our{' '}
            <Link
              href="https://github.com/yourusername/your-repo-name/blob/main/docs/DXFExportGuide.md"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: theme.palette.primary.main }}
            >
              DXF Export Guide
            </Link>
            .
          </Typography>
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            margin="normal"
            label="File Name"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            error={!!validateFileName(fileName)}
            helperText={validateFileName(fileName) || "File will be saved in your Downloads folder"}
            placeholder="Enter file name (e.g., layout.pdf)"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: theme.palette.primary.main,
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.light,
                },
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.primary.dark,
                },
              },
              '& .MuiInputLabel-root': {
                color: theme.palette.text.secondary,
              },
            }}
          />
        </Box>
        {isLoading && (
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress variant="determinate" value={progress} sx={{ bgcolor: theme.palette.primary.light }} />
            <Typography variant="body2" color="text.secondary" align="center">
              {`${Math.round(progress)}%`}
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
              {progressMessage}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ bgcolor: theme.palette.background.paper, p: theme.spacing(2) }}>
        <Button
          onClick={onClose}
          color="primary"
          disabled={isLoading}
          sx={{
            color: theme.palette.primary.main,
            '&:hover': {
              bgcolor: theme.palette.action.hover,
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handlePreview}
          color="primary"
          disabled={isLoading}
          sx={{
            color: theme.palette.primary.main,
            '&:hover': {
              bgcolor: theme.palette.action.hover,
            },
          }}
        >
          Preview
        </Button>
        <Button
          onClick={handlePrint}
          color="primary"
          disabled={isLoading || !!validateFileName(fileName)}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
              bgcolor: theme.palette.primary.dark,
            },
            '&:disabled': {
              bgcolor: theme.palette.action.disabledBackground,
              color: theme.palette.action.disabled,
            },
          }}
        >
          {isLoading ? <CircularProgress size={24} /> : includeDXF ? 'Export' : 'Print'}
        </Button>
      </DialogActions>
      <Snackbar open={error !== null} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert
          onClose={() => setError(null)}
          severity="error"
          sx={{
            width: '100%',
            bgcolor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
          }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default PrintDialog;
