import * as THREE from 'three';
import DmxModel from '../DmxModel';
import DmxMotor from '../DmxMotor';
import DmxColorAbilityRGB from './DmxColorAbilityRGB';
import DmxDimmerAbility from '../DmxDimmerAbility';
import DmxShutterAbility from '../DmxShutterAbility';

class DmxMovingHead extends DmxModel {
    constructor(modelData) {
        super(modelData);
        this.displayAs = "DmxMovingHead";

        this.panMotor = new DmxMotor({
            ...modelData.panMotor,
            channelCoarse: modelData.panChannelCoarse,
            channelFine: modelData.panChannelFine,
        });

        this.tiltMotor = new DmxMotor({
            ...modelData.tiltMotor,
            channelCoarse: modelData.tiltChannelCoarse,
            channelFine: modelData.tiltChannelFine,
        });

        this.colorAbility = new DmxColorAbilityRGB(modelData.colorAbility);
        this.dimmerAbility = new DmxDimmerAbility(modelData.dimmerAbility);
        this.shutterAbility = new DmxShutterAbility(modelData.shutterAbility);
    }

    createMesh() {
        const group = new THREE.Group();

        // Create base
        const baseGeometry = new THREE.CylinderGeometry(0.3, 0.4, 0.1, 32);
        const baseMaterial = new THREE.MeshPhongMaterial({ color: 0x888888 });
        const baseMesh = new THREE.Mesh(baseGeometry, baseMaterial);
        group.add(baseMesh);

        // Create yoke
        const yokeGeometry = new THREE.TorusGeometry(0.4, 0.05, 16, 100, Math.PI);
        const yokeMaterial = new THREE.MeshPhongMaterial({ color: 0x444444 });
        this.yokeMesh = new THREE.Mesh(yokeGeometry, yokeMaterial);
        this.yokeMesh.rotation.x = Math.PI / 2;
        this.yokeMesh.position.y = 0.3;
        group.add(this.yokeMesh);

        // Create head
        const headGeometry = new THREE.BoxGeometry(0.3, 0.4, 0.3);
        const headMaterial = new THREE.MeshPhongMaterial({ color: 0x222222 });
        this.headMesh = new THREE.Mesh(headGeometry, headMaterial);
        this.headMesh.position.y = 0.5;
        this.yokeMesh.add(this.headMesh);

        // Create lens
        const lensGeometry = new THREE.CylinderGeometry(0.1, 0.15, 0.05, 32);
        const lensMaterial = new THREE.MeshPhongMaterial({ color: 0xffffff, transparent: true, opacity: 0.8 });
        this.lensMesh = new THREE.Mesh(lensGeometry, lensMaterial);
        this.lensMesh.rotation.x = Math.PI / 2;
        this.lensMesh.position.z = 0.175;
        this.headMesh.add(this.lensMesh);

        // Create light beam
        const beamGeometry = new THREE.ConeGeometry(0.3, 1, 32);
        const beamMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0.2 });
        this.beamMesh = new THREE.Mesh(beamGeometry, beamMaterial);
        this.beamMesh.rotation.x = Math.PI / 2;
        this.beamMesh.position.z = 0.7;
        this.headMesh.add(this.beamMesh);

        return group;
    }

    updateFromDmxValues(dmxValues) {
        super.updateFromDmxValues(dmxValues);

        this.panMotor.updateFromDmxValues(dmxValues);
        this.tiltMotor.updateFromDmxValues(dmxValues);
        this.colorAbility.updateFromDmxValues(dmxValues);
        this.dimmerAbility.updateFromDmxValues(dmxValues);
        this.shutterAbility.updateFromDmxValues(dmxValues);

        this.updateMesh();
    }

    updateMesh() {
        if (this.mesh) {
            // Update pan
            this.yokeMesh.rotation.y = THREE.MathUtils.degToRad(this.panMotor.currentPosition);

            // Update tilt
            this.headMesh.rotation.x = THREE.MathUtils.degToRad(this.tiltMotor.currentPosition);

            // Update color
            const color = this.colorAbility.getBeamColor();
            this.lensMesh.material.color.setRGB(color.r, color.g, color.b);
            this.beamMesh.material.color.setRGB(color.r, color.g, color.b);

            // Update dimmer
            const intensity = this.dimmerAbility.getIntensity();
            this.beamMesh.material.opacity = 0.2 * intensity;

            // Update shutter
            this.beamMesh.visible = this.shutterAbility.isOpen();
        }
    }

    onPropertyChange(propertyName, value) {
        switch (propertyName) {
            case 'panMotor':
            case 'tiltMotor':
            case 'colorAbility':
            case 'dimmerAbility':
            case 'shutterAbility':
                this[propertyName].onPropertyChange(value.property, value.value);
                this.updateMesh();
                return true;
            default:
                return super.onPropertyChange(propertyName, value);
        }
    }

    getProperties() {
        return {
            ...super.getProperties(),
            panMotor: this.panMotor.getProperties(),
            tiltMotor: this.tiltMotor.getProperties(),
            colorAbility: this.colorAbility.getProperties(),
            dimmerAbility: this.dimmerAbility.getProperties(),
            shutterAbility: this.shutterAbility.getProperties(),
        };
    }
}

export default DmxMovingHead;