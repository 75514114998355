import { logInfo, logError } from '../../utils/logger';

const degToRad = (degrees) => degrees * (Math.PI / 180);

export const parseCircleModel = (modelData) => {
  logInfo('Parsing Circle model:', modelData);
  const {
    parm1,
    parm2,
    parm3,
    InsideOut,
    rotation,
    Dir,
    StartSide,
    WorldPosX,
    WorldPosY,
    WorldPosZ,
    ScaleX,
    ScaleY,
    ScaleZ
  } = modelData;

  const numStrings = parseInt(parm1) || 1;
  const lightsPerString = parseInt(parm2) || 50;
  const centerPercent = parseInt(parm3) || 50;
  const insideOut = InsideOut === '1';
  const isLtoR = Dir !== 'R';
  const isBotToTop = StartSide === 'B';

  const points = {};
  const totalLights = numStrings * lightsPerString;

  if (!lightsPerString || !numStrings) {
    logError('Invalid circle model parameters:', { lightsPerString, numStrings });
    return { rows: 0, cols: 0, points: {} };
  }

  const maxRadius = lightsPerString / 2;
  const minRadius = (centerPercent / 100) * maxRadius;

  let currentNode = 0;

  for (let circle = 0; circle < numStrings; circle++) {
    const radius = (numStrings === 1) ? maxRadius :
      insideOut ?
        minRadius + (maxRadius - minRadius) * (1 - (numStrings - circle - 1) / (numStrings - 1)) :
        minRadius + (maxRadius - minRadius) * (1 - circle / (numStrings - 1));

    for (let light = 0; light < lightsPerString; light++) {
      const angle = (isBotToTop ? -Math.PI : 0) + Math.PI * (light / lightsPerString) * 2;
      const adjustedAngle = isLtoR ? angle : -angle;

      const x = -Math.sin(adjustedAngle) * radius;
      const y = -Math.cos(adjustedAngle) * radius;

      if (!points[currentNode + 1]) {
        points[currentNode + 1] = [];
      }
      points[currentNode + 1].push({ x, y });

      currentNode++;
    }
  }

  // Apply transformations
  const worldPos = {
    x: parseFloat(WorldPosX) || 0,
    y: parseFloat(WorldPosY) || 0,
    z: parseFloat(WorldPosZ) || 0
  };
  const scale = {
    x: parseFloat(ScaleX) || 1,
    y: parseFloat(ScaleY) || 1,
    z: parseFloat(ScaleZ) || 1
  };

  const transformedPoints = {};
  for (const node in points) {
    transformedPoints[node] = points[node].map(point => {
      const rotatedPoint = applyRotation(point, rotation);
      const scaledPoint = {
        x: rotatedPoint.x * scale.x,
        y: rotatedPoint.y * scale.y
      };
      return {
        x: scaledPoint.x + worldPos.x + maxRadius,
        y: scaledPoint.y + worldPos.y + maxRadius
      };
    });
  }

  logInfo('Parsed circle model:', {
    numStrings,
    lightsPerString,
    totalLights,
    pointCount: Object.keys(transformedPoints).length,
  });

  return {
    rows: maxRadius * 2,
    cols: maxRadius * 2,
    points: transformedPoints
  };
};

const applyRotation = (point, rotation) => {
  if (!rotation) return point;
  const { x: rotX, y: rotY, z: rotZ } = rotation;
  const cosX = Math.cos(degToRad(rotX));
  const sinX = Math.sin(degToRad(rotX));
  const cosY = Math.cos(degToRad(rotY));
  const sinY = Math.sin(degToRad(rotY));
  const cosZ = Math.cos(degToRad(rotZ));
  const sinZ = Math.sin(degToRad(rotZ));

  // Apply rotation around X axis
  let newY = point.y * cosX - 0 * sinX;  // Assuming point.z is 0
  let newZ = point.y * sinX + 0 * cosX;  // Assuming point.z is 0

  // Apply rotation around Y axis
  let newX = point.x * cosY + newZ * sinY;
  newZ = -point.x * sinY + newZ * cosY;

  // Apply rotation around Z axis
  const finalX = newX * cosZ - newY * sinZ;
  const finalY = newX * sinZ + newY * cosZ;

  return { x: finalX, y: finalY };
};
