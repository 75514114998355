import * as THREE from 'three';
import BaseModel from '../BaseModel';

class DmxModel extends BaseModel {
    constructor(modelData) {
        super(modelData);
        this.displayAs = "DmxModel";
    }

    createGeometry() {
        // Create a simple square geometry as a fallback
        return new THREE.PlaneGeometry(1, 1);
    }

    createMesh() {
        const geometry = this.createGeometry();
        const material = new THREE.MeshBasicMaterial({ color: 0xff0000, side: THREE.DoubleSide });
        const mesh = new THREE.Mesh(geometry, material);

        // Apply transformations from BaseModel
        return this.applyTransformations(mesh);
    }

    // Override other methods as needed
    updateFromDmxValues(dmxValues) {
        // Implement DMX value update logic here
    }

    onPropertyChange(propertyName, value) {
        // Implement property change logic here
        return super.onPropertyChange(propertyName, value);
    }

    getProperties() {
        return {
            ...super.getProperties(),
            // Add DMX-specific properties here
        };
    }
}

export default DmxModel;