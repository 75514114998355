import * as THREE from 'three';
import DmxModel from './DmxModel';

class DmxServo extends DmxModel {
    constructor(modelData) {
        super(modelData);
        this.displayAs = "DmxServo";
        this.channel = modelData.channel || 0;
        this.minAngle = modelData.minAngle || 0;
        this.maxAngle = modelData.maxAngle || 180;
        this.currentAngle = 0;
        this.reverse = modelData.reverse || false;
    }

    createMesh() {
        const group = new THREE.Group();

        // Create servo body
        const bodyGeometry = new THREE.BoxGeometry(0.4, 0.2, 0.4);
        const bodyMaterial = new THREE.MeshPhongMaterial({ color: 0x888888 });
        const bodyMesh = new THREE.Mesh(bodyGeometry, bodyMaterial);
        group.add(bodyMesh);

        // Create servo horn
        const hornGeometry = new THREE.BoxGeometry(0.3, 0.05, 0.05);
        const hornMaterial = new THREE.MeshPhongMaterial({ color: 0x444444 });
        this.hornMesh = new THREE.Mesh(hornGeometry, hornMaterial);
        this.hornMesh.position.set(0, 0.125, 0);
        group.add(this.hornMesh);

        return group;
    }

    updateFromDmxValues(dmxValues) {
        super.updateFromDmxValues(dmxValues);

        if (this.channel > 0 && this.channel <= dmxValues.length) {
            const dmxValue = dmxValues[this.channel - 1];
            this.setAngle(this.dmxToAngle(dmxValue));
        }
    }

    setAngle(angle) {
        this.currentAngle = Math.max(this.minAngle, Math.min(this.maxAngle, angle));
        this.updateMesh();
    }

    dmxToAngle(dmxValue) {
        const normalizedValue = dmxValue / 255;
        const angle = this.minAngle + normalizedValue * (this.maxAngle - this.minAngle);
        return this.reverse ? this.maxAngle - angle + this.minAngle : angle;
    }

    updateMesh() {
        if (this.hornMesh) {
            const rotation = THREE.MathUtils.degToRad(this.currentAngle);
            this.hornMesh.rotation.z = rotation;
        }
    }

    onPropertyChange(propertyName, value) {
        switch (propertyName) {
            case 'channel':
                this.channel = parseInt(value);
                return true;
            case 'minAngle':
                this.minAngle = parseFloat(value);
                this.setAngle(this.currentAngle); // Reapply current angle to ensure it's within new limits
                return true;
            case 'maxAngle':
                this.maxAngle = parseFloat(value);
                this.setAngle(this.currentAngle); // Reapply current angle to ensure it's within new limits
                return true;
            case 'reverse':
                this.reverse = Boolean(value);
                this.setAngle(this.currentAngle); // Reapply current angle to account for reverse change
                return true;
            default:
                return super.onPropertyChange(propertyName, value);
        }
    }

    getProperties() {
        return {
            ...super.getProperties(),
            channel: this.channel,
            minAngle: this.minAngle,
            maxAngle: this.maxAngle,
            reverse: this.reverse,
        };
    }
}

export default DmxServo;