export const parseCubeModel = (modelData) => {
    const {
        parm1,
        parm2,
        parm3,
        Start,
        Style,
        StrandPerLine,
        StrandPerLayer,
    } = modelData;

    const width = parseInt(parm1) || 1;
    const height = parseInt(parm2) || 1;
    const depth = parseInt(parm3) || 1;
    const startLocation = Start || 'Front Bottom Left';
    const stringDirection = Style || 'Vertical Front/Back';
    const strandStyle = StrandPerLine || 'Zig Zag';
    const strandPerLayer = StrandPerLayer === 'TRUE';

    const totalNodes = width * height * depth;
    const points = {};

    const TOP_BOT_LEFT_RIGHT = [
        'Front Bottom Left', 'Front Bottom Right', 'Front Top Left', 'Front Top Right',
        'Back Bottom Left', 'Back Bottom Right', 'Back Top Left', 'Back Top Right'
    ];

    const CUBE_STYLES = [
        'Vertical Front/Back', 'Vertical Left/Right',
        'Horizontal Front/Back', 'Horizontal Left/Right',
        'Stacked Front/Back', 'Stacked Left/Right'
    ];

    const transformations = [
        [1, 0, -1, 0], [0, 0, -1, 1], [0, -1, 0, 1], [0, 0, 0, 0], [-1, 2, 0, 1], [-1, -1, 0, 0],
        [1, 0, -1, 1], [0, 0, -1, 0], [0, -1, 0, 0], [0, 0, 0, 1], [-1, 2, 0, 0], [-1, -1, 0, 1],
        [1, 0, 1, 1], [0, 0, 1, 0], [0, -1, 2, 0], [0, 0, 2, 1], [-1, 2, 2, 0], [-1, -1, 2, 1],
        [1, 0, 1, 0], [0, 0, 1, 1], [0, -1, 2, 1], [0, 0, 2, 0], [-1, 2, 2, 1], [-1, -1, 2, 0],
        [-1, 0, -1, 1], [0, 2, 1, 0], [0, 1, 0, 0], [0, 2, 0, 1], [-1, 0, 0, 0], [-1, 1, 0, 1],
        [-1, 0, -1, 0], [0, 2, 1, 1], [0, 1, 0, 1], [0, 2, 0, 0], [-1, 0, 0, 1], [-1, 1, 0, 0],
        [-1, 0, 1, 0], [0, 2, -1, 1], [0, -1, 2, 0], [2, 0, 0, 0], [-1, 2, 2, 0], [1, -1, 0, 0],
        [-1, 0, 1, 1], [0, 2, -1, 0], [0, -1, 2, 1], [2, 0, 0, 1], [-1, 2, 2, 1], [1, -1, 0, 1]
    ];

    const startIndex = TOP_BOT_LEFT_RIGHT.indexOf(startLocation);
    const styleIndex = CUBE_STYLES.indexOf(stringDirection);
    const transformationIndex = startIndex * CUBE_STYLES.length + styleIndex;
    const transformation = transformations[transformationIndex % transformations.length];

    const [xr, yr, zr, xf] = transformation;

    const applyRotations = (node, w, h, d) => {
        let [x, y, z] = node;
        const rotateX = (pt, by) => {
            for (let i = 0; i < Math.abs(by); i++) {
                if (by > 0) [pt[1], pt[2]] = [d - pt[2] - 1, pt[1]];
                else[pt[1], pt[2]] = [pt[2], h - pt[1] - 1];
                [h, d] = [d, h];
            }
        };
        const rotateY = (pt, by) => {
            for (let i = 0; i < Math.abs(by); i++) {
                if (by > 0) [pt[0], pt[2]] = [pt[2], w - pt[0] - 1];
                else[pt[0], pt[2]] = [d - pt[2] - 1, pt[0]];
                [w, d] = [d, w];
            }
        };
        const rotateZ = (pt, by) => {
            for (let i = 0; i < Math.abs(by); i++) {
                if (by > 0) [pt[0], pt[1]] = [pt[1], w - pt[0] - 1];
                else[pt[0], pt[1]] = [h - pt[1] - 1, pt[0]];
                [w, h] = [h, w];
            }
        };
        rotateX([x, y, z], xr);
        rotateY([x, y, z], yr);
        rotateZ([x, y, z], zr);
        return [x, y, z];
    };

    let nodeNumber = 1;
    for (let i = 0; i < totalNodes; i++) {
        let z = Math.floor(i / (width * height));
        let baseLayer = i % (width * height);
        let y = Math.floor(baseLayer / width);
        let x;

        if (strandStyle === 'No Zig Zag' || y % 2 === 0) {
            x = baseLayer % width;
        } else if (strandStyle === 'Alternate Pixel') {
            let pos = baseLayer % width + 1;
            x = pos <= (width + 1) / 2 ? 2 * (pos - 1) : (width - pos) * 2 + 1;
        } else {
            x = width - baseLayer % width - 1;
        }

        if (!strandPerLayer && z % 2 !== 0) {
            y = height - y - 1;
            if (height % 2 !== 0 && strandStyle === 'Zig Zag') {
                x = width - x - 1;
            }
        }

        let node = [x, y, z];
        [x, y, z] = applyRotations(node, width, height, depth);

        if (xf > 0) {
            x = width - x - 1;
        }

        // Normalize coordinates to [0, 1] range
        x = x / (width - 1);
        y = y / (height - 1);
        z = z / (depth - 1);

        if (!points[nodeNumber]) {
            points[nodeNumber] = [];
        }
        points[nodeNumber].push({ x, y, z });

        nodeNumber++;
    }

    return {
        rows: height,
        cols: width,
        depth: depth,
        points,
    };
};