import * as THREE from 'three';
import DmxModel from './DmxModel';

class DmxDimmerAbility extends DmxModel {
    constructor(modelData) {
        super(modelData);
        this.dimmerChannel = modelData.dimmerChannel || 0;
        this.intensity = 1.0; // Default to full intensity
    }

    createMesh() {
        // Create a circular disc to represent the dimmer
        const geometry = new THREE.CircleGeometry(0.5, 32);
        const material = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            side: THREE.DoubleSide,
            transparent: true,
            opacity: this.intensity
        });
        const mesh = new THREE.Mesh(geometry, material);

        // Add a frame around the dimmer
        const frameGeometry = new THREE.RingGeometry(0.5, 0.55, 32);
        const frameMaterial = new THREE.MeshBasicMaterial({ color: 0x888888, side: THREE.DoubleSide });
        const frame = new THREE.Mesh(frameGeometry, frameMaterial);
        mesh.add(frame);

        return mesh;
    }

    updateFromDmxValues(dmxValues) {
        if (this.dimmerChannel > 0 && this.dimmerChannel <= dmxValues.length) {
            this.intensity = dmxValues[this.dimmerChannel - 1] / 255;
            this.updateMesh();
        }
    }

    updateMesh() {
        if (this.mesh) {
            this.mesh.material.opacity = this.intensity;
        }
    }

    getIntensity() {
        return this.intensity;
    }

    isDimmerChannel(channel) {
        return channel === this.dimmerChannel;
    }

    getNumChannels() {
        return this.dimmerChannel > 0 ? 1 : 0;
    }

    setDimmerChannel(channel) {
        this.dimmerChannel = channel;
    }

    getDimmerProperties() {
        return {
            dimmerChannel: this.dimmerChannel
        };
    }

    // This method can be used to update properties from a UI
    onPropertyChange(propertyName, value) {
        if (propertyName === 'dimmerChannel') {
            this.setDimmerChannel(parseInt(value));
            return true;
        }
        return false;
    }
}

export default DmxDimmerAbility;