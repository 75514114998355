import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Snackbar,
  IconButton,
  useMediaQuery,
  useTheme,
  Fab,
  Tooltip,
  Drawer,
} from '@mui/material';
import {
  Close as CloseIcon,
  ZoomIn,
  ZoomOut,
  ThreeDRotation,
  Refresh,
  GridOn,
  GridOff,
  ViewInAr,
  Fullscreen,
  FullscreenExit,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import ModelRenderer from './ModelRenderer';
import { generateVisualizerPDF } from '../utils/PDFGenerator';
import FullScreenWiringDialog from './FullScreenWiringDialog';
import { useLayout } from './LayoutContext';
import { logInfo, logWarning, logError } from '../utils/logger';
import GridlinesObject from './models/GridlinesObject';

const VisualizerContainer = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  overflow: 'hidden',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  padding: 0,
  margin: 0,
}));

const SidePanel = styled(Drawer)(({ theme }) => ({
  width: 250,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 250,
    boxSizing: 'border-box',
    paddingTop: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiDrawer-paper': {
      width: '80%',
      maxWidth: 300,
      paddingTop: '10px',
    },
  },
}));

const CanvasContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  height: 'calc(100% - 64px)', // Subtract the height of the top menu
  width: '100%',
  backgroundColor: theme.palette.background.default,
  position: 'relative',
  marginTop: '64px', // Add top margin to account for the menu
}));

const Visualizer = ({ layout: propLayout, isSharedLayout }) => {
  const { layout: contextLayout, setLayout: setContextLayout } = useLayout();
  const [localLayout, setLocalLayout] = useState(propLayout || contextLayout);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [is3DMode, setIs3DMode] = useState(true);
  const [showBoundingBoxes, setShowBoundingBoxes] = useState(false);
  const [showWiringDialog, setShowWiringDialog] = useState(false);
  const { shareId } = useParams();
  const mountRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const controlsRef = useRef(null);
  const modelRendererRef = useRef(null);
  const initialCameraPositionRef = useRef(null);
  const [snackbarPosition, setSnackbarPosition] = useState({ x: 0, y: 0 });
  const [worldScale, setWorldScale] = useState(1);
  const [showGridlines, setShowGridlines] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isSceneReady, setIsSceneReady] = useState(false);
  const layoutRef = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [sidePanelOpen, setSidePanelOpen] = useState(!isMobile);
  const [showConnections, setShowConnections] = useState(false);
  const connectionLinesRef = useRef(null);
  const navigate = useNavigate();
  const [wiringDialogKey, setWiringDialogKey] = useState(0);
  const layoutName = useMemo(() => localLayout?.name || 'My Layout', [localLayout]);
  const [gridlines, setGridlines] = useState(null);

  const flowingLineMaterial = new THREE.ShaderMaterial({
    uniforms: {
      color: { value: new THREE.Color(0x00ffff) },
      time: { value: 0 },
      speed: { value: 2 },
      intensity: { value: 2 },
      lineThickness: { value: 0.5 },
      flowDirection: { value: new THREE.Vector3(0, 1, 0) },
      chainIndex: { value: 0 },
    },
    vertexShader: `
    varying vec2 vUv;
    uniform float lineThickness;
    uniform vec3 flowDirection;
    uniform float chainIndex;
    void main() {
      vUv = uv;
      vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
      gl_Position = projectionMatrix * mvPosition;
      gl_PointSize = lineThickness * 1000.0 / -mvPosition.z;
    }
  `,
    fragmentShader: `
    uniform vec3 color;
    uniform float time;
    uniform float speed;
    uniform float intensity;
    uniform vec3 flowDirection;
    uniform float chainIndex;
    varying vec2 vUv;
    void main() {
      float flowFactor = dot(vec3(vUv.x, 0.0, 0.0), flowDirection);
      float adjustedTime = time * speed + chainIndex * 0.1;
      float t = mod(adjustedTime - flowFactor, 1.0);
      float pulse = pow(sin(t * 3.14159 * 2.0) * 0.5 + 0.5, 3.0) * intensity;
      vec3 finalColor = mix(color, color * 1.2, pulse);
      float alpha = smoothstep(0.5, 0.1, length(gl_PointCoord - vec2(0.5)));
      gl_FragColor = vec4(finalColor, alpha);
    }
  `,
    transparent: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
  });

  const createFlowLine = (startPoint, endPoint, color, isLast, chainIndex) => {
    const points = [];
    const segments = 100;
    const arcHeight = 5; // Adjust this value to control the arc height

    for (let i = 0; i <= segments; i++) {
      const t = i / segments;
      const point = new THREE.Vector3().lerpVectors(startPoint, endPoint, t);
      // Flip the arc to curve downwards
      point.y -= Math.sin(t * Math.PI) * arcHeight;
      points.push(point);
    }

    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const material = flowingLineMaterial.clone();
    material.uniforms.color.value = new THREE.Color(isLast ? 0x33ff33 : 0xff3333);
    material.uniforms.lineThickness.value = 0.3;

    const flowDirection = new THREE.Vector3().subVectors(endPoint, startPoint).normalize();
    material.uniforms.flowDirection = { value: flowDirection };
    material.uniforms.chainIndex = { value: chainIndex };

    const line = new THREE.Points(geometry, material);
    line.frustumCulled = false;
    return line;
  };

  const createPulsingSphere = (position, color, size = 0.5) => {
    const geometry = new THREE.SphereGeometry(size, 32, 32);
    const material = new THREE.MeshBasicMaterial({
      color: color,
      transparent: true,
      opacity: 0.8,
    });
    const sphere = new THREE.Mesh(geometry, material);
    sphere.position.copy(position);
    sphere.userData.initialScale = sphere.scale.clone();
    sphere.userData.pulseFactor = Math.random() * 0.5 + 0.75;
    return sphere;
  };

  const createChevron = (startPoint, endPoint, color) => {
    const direction = new THREE.Vector3().subVectors(endPoint, startPoint).normalize();
    const chevronSize = 0.5;
    const chevronGeometry = new THREE.BufferGeometry();
    const vertices = new Float32Array([
      0,
      chevronSize / 2,
      0,
      -chevronSize / 2,
      -chevronSize / 2,
      0,
      chevronSize / 2,
      -chevronSize / 2,
      0,
    ]);
    chevronGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
    const chevronMaterial = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide });
    const chevron = new THREE.Mesh(chevronGeometry, chevronMaterial);

    // Position chevron
    const midPoint = new THREE.Vector3().lerpVectors(startPoint, endPoint, 0.5);
    chevron.position.copy(midPoint);

    // Orient chevron
    const lookAt = new THREE.Vector3().addVectors(midPoint, direction);
    chevron.lookAt(lookAt);

    return chevron;
  };

  const findModelInScene = useCallback((modelName) => {
    let foundModel = null;
    sceneRef.current.traverse((object) => {
      if (object.userData && object.userData.name === modelName) {
        foundModel = object;
      }
    });
    return foundModel;
  }, []);

  const getModelPosition = useCallback((model, position = 'center') => {
    if (!model) {
      logWarning('Model is undefined');
      return new THREE.Vector3();
    }
    let geometryObject = model;
    if (model.type === 'Group') {
      geometryObject = model.children.find((child) => child.isMesh || child.isPoints);
    }
    if (!geometryObject || !geometryObject.geometry) {
      logWarning('Model or its geometry is not properly defined:', model);
      return model.position;
    }
    geometryObject.updateMatrixWorld();

    const positionAttribute = geometryObject.geometry.getAttribute('position');
    const colorAttribute = geometryObject.geometry.getAttribute('color');

    if (positionAttribute && colorAttribute) {
      let index;
      if (position === 'first') {
        index = 0;
        for (let i = 0; i < colorAttribute.count; i++) {
          if (
            colorAttribute.getX(i) === 0 &&
            colorAttribute.getY(i) === 1 &&
            colorAttribute.getZ(i) === 1
          ) {
            index = i;
            break;
          }
        }
      } else if (position === 'last') {
        index = positionAttribute.count - 1;
        for (let i = colorAttribute.count - 1; i >= 0; i--) {
          if (
            colorAttribute.getX(i) === 0.5 &&
            colorAttribute.getY(i) === 0 &&
            colorAttribute.getZ(i) === 0
          ) {
            index = i;
            break;
          }
        }
      } else {
        // For 'center', use the bounding sphere center
        if (geometryObject.geometry.boundingSphere) {
          const center = geometryObject.geometry.boundingSphere.center.clone();
          return center.applyMatrix4(geometryObject.matrixWorld);
        }
        return geometryObject.position;
      }
      const point = new THREE.Vector3().fromBufferAttribute(positionAttribute, index);
      return point.applyMatrix4(geometryObject.matrixWorld);
    }

    return geometryObject.position;
  }, []);

  const drawConnections = useCallback(() => {
    if (!sceneRef.current || !layoutRef.current || !layoutRef.current.portMapping) return;
    if (connectionLinesRef.current) {
      sceneRef.current.remove(connectionLinesRef.current);
    }
    const lineGroup = new THREE.Group();
    lineGroup.name = 'connectionLines';

    Object.entries(layoutRef.current.portMapping).forEach(([controllerName, ports]) => {
      Object.entries(ports).forEach(([portNumber, models]) => {
        if (models.length > 1) {
          for (let i = models.length - 1; i > 0; i--) {
            const startModel = findModelInScene(models[i].name);
            const endModel = findModelInScene(models[i - 1].name);
            if (startModel && endModel) {
              const startPoint = getModelPosition(startModel, 'last');
              const endPoint = getModelPosition(endModel, 'first');
              const isLast = i === models.length - 1;
              const color = isLast ? 0x33ff33 : 0xff3333;
              const chainIndex = models.length - 1 - i;

              // Create the curved line
              const line = createFlowLine(startPoint, endPoint, color, isLast, chainIndex);
              lineGroup.add(line);

              // Create the flowing particles
              const particleSystem = createFlowingParticles(startPoint, endPoint, color);
              lineGroup.add(particleSystem);

              // Keep the pulsing spheres at start and end points
              const startSphere = createPulsingSphere(startPoint, color);
              const endSphere = createPulsingSphere(endPoint, 0x33ff33);
              lineGroup.add(startSphere, endSphere);

              // Keep the chevron
              const chevron = createChevron(startPoint, endPoint, color);
              lineGroup.add(chevron);

              // Keep the point lights
              const startLight = new THREE.PointLight(color, 1, 10);
              startLight.position.copy(startPoint);
              const endLight = new THREE.PointLight(0x33ff33, 1, 10);
              endLight.position.copy(endPoint);
              lineGroup.add(startLight, endLight);
            }
          }
        }
      });
    });

    sceneRef.current.add(lineGroup);
    connectionLinesRef.current = lineGroup;

    const animate = () => {
      const time = performance.now() * 0.001;
      lineGroup.traverse((object) => {
        if (object.material && object.material.uniforms) {
          object.material.uniforms.time.value = time;
        }
        if (object.isMesh && object.userData.initialScale) {
          const pulseFactor = object.userData.pulseFactor || 1;
          const scale = 1 + Math.sin(time * 5 * pulseFactor) * 0.1;
          object.scale.copy(object.userData.initialScale).multiplyScalar(scale);
        }
        if (object.isPoints && object.userData.isFlowingParticle) {
          updateFlowingParticles(object, time);
        }
      });

      if (rendererRef.current && sceneRef.current && cameraRef.current) {
        rendererRef.current.render(sceneRef.current, cameraRef.current);
      }
      requestAnimationFrame(animate);
    };
    animate();

    return () => {
      if (connectionLinesRef.current) {
        sceneRef.current.remove(connectionLinesRef.current);
        connectionLinesRef.current = null;
      }
    };
  }, [findModelInScene, getModelPosition]);

  const createFlowingParticles = (startPoint, endPoint, color) => {
    const particleCount = 50;
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(particleCount * 3);
    const sizes = new Float32Array(particleCount);

    for (let i = 0; i < particleCount; i++) {
      const t = Math.random();
      const point = new THREE.Vector3().lerpVectors(startPoint, endPoint, t);
      point.y -= Math.sin(t * Math.PI) * 5; // Match the arc of the line
      positions[i * 3] = point.x;
      positions[i * 3 + 1] = point.y;
      positions[i * 3 + 2] = point.z;
      sizes[i] = Math.random() * 2 + 1;
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));

    const material = new THREE.ShaderMaterial({
      uniforms: {
        color: { value: new THREE.Color(color) },
        time: { value: 0 },
      },
      vertexShader: `
      attribute float size;
      uniform float time;
      varying float vAlpha;
      void main() {
        vAlpha = mod(time - position.x * 0.1, 1.0);
        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
        gl_PointSize = size * (300.0 / -mvPosition.z);
        gl_Position = projectionMatrix * mvPosition;
      }
    `,
      fragmentShader: `
      uniform vec3 color;
      varying float vAlpha;
      void main() {
        float distanceToCenter = length(gl_PointCoord - vec2(0.5));
        float alpha = smoothstep(0.5, 0.2, distanceToCenter) * vAlpha;
        gl_FragColor = vec4(color, alpha);
      }
    `,
      transparent: true,
      depthWrite: false,
      blending: THREE.AdditiveBlending,
    });

    const particleSystem = new THREE.Points(geometry, material);
    particleSystem.userData.isFlowingParticle = true;
    particleSystem.userData.startPoint = startPoint;
    particleSystem.userData.endPoint = endPoint;

    return particleSystem;
  };

  const updateFlowingParticles = (particleSystem, time) => {
    const positions = particleSystem.geometry.attributes.position.array;
    const startPoint = particleSystem.userData.startPoint;
    const endPoint = particleSystem.userData.endPoint;

    for (let i = 0; i < positions.length; i += 3) {
      const t = (time * 0.5 + i * 0.01) % 1; // This line is changed
      const point = new THREE.Vector3().lerpVectors(startPoint, endPoint, t);
      point.y -= Math.sin(t * Math.PI) * 5; // Match the arc of the line
      positions[i] = point.x;
      positions[i + 1] = point.y;
      positions[i + 2] = point.z;
    }

    particleSystem.geometry.attributes.position.needsUpdate = true;
    particleSystem.material.uniforms.time.value = time;
  };

  useEffect(() => {
    let animationFrameId;

    if (showConnections && sceneRef.current && layoutRef.current) {
      drawConnections();
    } else if (!showConnections && connectionLinesRef.current) {
      sceneRef.current.remove(connectionLinesRef.current);
      connectionLinesRef.current = null;
    }

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [showConnections, drawConnections]);

  useEffect(() => {
    if (isSharedLayout && shareId && !localLayout) {
      setIsLoading(true);
      axios
        .get(`/api/layout/share/${shareId}`)
        .then((response) => {
          setLocalLayout(response.data);
          setContextLayout(response.data);
          layoutRef.current = response.data;
          setIsLoading(false);
          setIsInitialized(true);
        })
        .catch((error) => {
          logError('Error fetching shared layout:', error);
          setIsLoading(false);
        });
    } else if (propLayout) {
      setLocalLayout(propLayout);
      layoutRef.current = propLayout;
      setIsInitialized(true);
    } else if (contextLayout) {
      setLocalLayout(contextLayout);
      layoutRef.current = contextLayout;
      setIsInitialized(true);
    }
  }, [isSharedLayout, shareId, propLayout, contextLayout, setContextLayout]);

  useEffect(() => {
    if (!isInitialized) return;
    const initializeScene = () => {
      if (!mountRef.current || !layoutRef.current) {
        setTimeout(initializeScene, 100);
        return;
      }
      createScene();
    };
    initializeScene();
  }, [isInitialized]);

  const handleResize = useCallback(() => {
    if (mountRef.current && cameraRef.current && rendererRef.current) {
      const container = mountRef.current;
      const { clientWidth, clientHeight } = container;
      cameraRef.current.aspect = clientWidth / clientHeight;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(clientWidth, clientHeight);
    }
  }, []);

  const applyViewpoint = useCallback((camera, controls, viewpoint) => {
    if (!camera || !controls || !viewpoint) return;
    const { posX, posY, posZ, angleX, angleY, angleZ, distance, zoom, panx, pany, panz } =
      viewpoint;
    camera.position.set(parseFloat(posX), parseFloat(posY), parseFloat(posZ));
    camera.rotation.set(
      THREE.MathUtils.degToRad(parseFloat(angleX)),
      THREE.MathUtils.degToRad(parseFloat(angleY)),
      THREE.MathUtils.degToRad(parseFloat(angleZ))
    );
    camera.zoom = parseFloat(zoom);
    camera.updateProjectionMatrix();
    controls.target.set(parseFloat(panx), parseFloat(pany), parseFloat(panz));
    controls.update();
    const direction = new THREE.Vector3();
    camera.getWorldDirection(direction);
    direction.multiplyScalar(parseFloat(distance));
    camera.position.add(direction);
  }, []);

  const handleClick = useCallback(
    (event) => {
      if (!sceneRef.current || !cameraRef.current || !mountRef.current || !isSceneReady) return;
      const container = mountRef.current;
      const rect = container.getBoundingClientRect();
      const mouse = new THREE.Vector2();
      mouse.x = ((event.clientX - rect.left) / container.clientWidth) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / container.clientHeight) * 2 + 1;
      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, cameraRef.current);
      const meshes = [];
      sceneRef.current.traverse((object) => {
        if ((object.isMesh || object.isPoints) && object.visible) {
          meshes.push(object);
        }
      });
      const intersects = raycaster.intersectObjects(meshes, true);
      if (intersects.length > 0) {
        let intersectedObject = intersects[0].object;
        while (intersectedObject && !intersectedObject.userData.name) {
          intersectedObject = intersectedObject.parent;
        }
        if (intersectedObject && intersectedObject.userData && intersectedObject.userData.name) {
          setSelectedModel(intersectedObject.userData);
          setSnackbarOpen(true);
          const intersectionPoint = intersects[0].point;
          const screenPosition = intersectionPoint.project(cameraRef.current);

          // Adjust snackbar positioning
          const menuHeight = 64; // Height of the top menu
          const snackbarHeight = 200; // Approximate height of the snackbar content
          const snackbarWidth = 300; // Maximum width of the snackbar content

          let snackbarX = ((screenPosition.x + 1) * container.clientWidth) / 2;
          let snackbarY = ((-screenPosition.y + 1) * container.clientHeight) / 2;

          // Ensure the snackbar doesn't go off-screen
          snackbarX = Math.max(
            snackbarWidth / 2,
            Math.min(snackbarX, container.clientWidth - snackbarWidth / 2)
          );
          snackbarY = Math.max(
            menuHeight,
            Math.min(snackbarY, container.clientHeight - snackbarHeight - menuHeight)
          );

          setSnackbarPosition({ x: snackbarX, y: snackbarY });
        } else {
          setSelectedModel(null);
          setSnackbarOpen(false);
        }
      } else {
        setSelectedModel(null);
        setSnackbarOpen(false);
      }
    },
    [isSceneReady]
  );

  useEffect(() => {
    const currentMountRef = mountRef.current;
    if (currentMountRef && isSceneReady) {
      currentMountRef.addEventListener('click', handleClick);
    }
    return () => {
      if (currentMountRef) {
        currentMountRef.removeEventListener('click', handleClick);
      }
    };
  }, [handleClick, isSceneReady]);

  const calculateWorldScale = useMemo(
    () => (layout) => {
      if (!layout || !layout.models) return 1;
      const maxDimension = Math.max(
        ...layout.models.map((model) =>
          Math.max(
            model.screenLocation.width,
            model.screenLocation.height,
            model.screenLocation.depth
          )
        )
      );
      return maxDimension > 0 ? 100 / maxDimension : 1;
    },
    []
  );

  const createScene = useCallback(() => {
    if (!layoutRef.current || !layoutRef.current.models || !mountRef.current) return;
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x111111);
    const container = mountRef.current;
    const aspect = container.clientWidth / container.clientHeight;
    const camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 10000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    container.innerHTML = '';
    container.appendChild(renderer.domElement);
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.minDistance = 0.1;
    controls.maxDistance = 10000;
    controls.zoomSpeed = isMobile ? 0.5 : 1.0;
    controls.enablePan = true;
    controls.panSpeed = isMobile ? 0.5 : 0.8;
    controls.mouseButtons = {
      LEFT: THREE.MOUSE.ROTATE,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.PAN,
    };
    controls.touches = {
      ONE: THREE.TOUCH.ROTATE,
      TWO: THREE.TOUCH.DOLLY_PAN,
    };
    renderer.domElement.style.touchAction = 'none';
    renderer.domElement.style.userSelect = 'none';

    sceneRef.current = scene;
    cameraRef.current = camera;
    rendererRef.current = renderer;
    controlsRef.current = controls;
    modelRendererRef.current = new ModelRenderer();

    const viewpoints = layoutRef.current.viewpoints;
    if (viewpoints && viewpoints.DefaultCamera3D) {
      const cam3D = viewpoints.DefaultCamera3D;
      camera.position.set(cam3D.position.x, cam3D.position.y, cam3D.position.z);
      camera.rotation.set(
        THREE.MathUtils.degToRad(cam3D.angle.x),
        THREE.MathUtils.degToRad(cam3D.angle.y),
        THREE.MathUtils.degToRad(cam3D.angle.z)
      );
      controls.target.set(cam3D.pan.x, cam3D.pan.y, cam3D.pan.z);
      camera.zoom = cam3D.zoom;
      logInfo('DefaultCamera3D viewpoint found, using default settings');
    } else {
      logInfo('No DefaultCamera3D viewpoint found, using default settings');
      camera.position.set(0, 0, 2000);
      camera.lookAt(0, 0, 0);
      controls.target.set(0, 0, 0);
    }

    const viewObjects = layoutRef.current.viewObjects || [];
    const gridlinesData = viewObjects.find(obj => obj.DisplayAs === 'Gridlines');
    if (gridlinesData) {
      const newGridlines = new GridlinesObject(gridlinesData);
      newGridlines.createGrid(scene);
      setGridlines(newGridlines);
    }

    controls.update();

    const modelGroup = new THREE.Group();
    scene.add(modelGroup);
    const newWorldScale = calculateWorldScale(layoutRef.current);
    setWorldScale(newWorldScale);
    logInfo('World scale set to:', newWorldScale);

    logInfo('Total models:', layoutRef.current.models.length);

    layoutRef.current.models.forEach((model, index) => {
      try {
        if (!model) {
          logWarning(`Model ${index} is undefined`);
          return;
        }
        if (!model.displayAs) {
          logWarning(`Model ${index} (${model.name}) is missing displayAs property`);
          return;
        }
        const mesh = modelRendererRef.current.createMesh(model);
        if (mesh) {
          if (mesh.userData.model && typeof mesh.userData.model.setWorldScale === 'function') {
            mesh.userData.model.setWorldScale(newWorldScale);
          } else {
            mesh.scale.multiplyScalar(newWorldScale);
          }
          modelGroup.add(mesh);
          logInfo(`Model ${index}: ${model.name}, Type: ${model.displayAs}, Position:`,
            mesh.position.toArray(), 'Scale:', mesh.scale.toArray(), 'Geometry:', mesh.geometry ? mesh.geometry.type : 'N/A');
        } else {
          logWarning(`Model ${index}: ${model.name} - Mesh creation returned null`);
        }
      } catch (error) {
        logError(`Error creating mesh for model ${model ? model.name : index}:`, error);
      }
    });

    const bbox = new THREE.Box3().setFromObject(modelGroup);
    const center = bbox.getCenter(new THREE.Vector3());
    const size = bbox.getSize(new THREE.Vector3());
    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = camera.fov * (Math.PI / 180);
    let cameraZ = Math.abs(maxDim / 2 / Math.tan(fov / 2)) * 1.5;

    logInfo('Scene Bounding Box:',
      'Center:', center.toArray(),
      'Size:', size.toArray(),
      'Min:', bbox.min.toArray(),
      'Max:', bbox.max.toArray()
    );

    if (viewpoints && viewpoints.DefaultCamera3D) {
      logInfo('Using calculated camera position');
      camera.position.set(center.x, center.y, center.z + cameraZ);
      camera.lookAt(center);
      controls.target.copy(center);
    }

    controls.update();
    initialCameraPositionRef.current = {
      position: camera.position.clone(),
      target: controls.target.clone(),
    };

    logInfo('Camera position after adjustment:', camera.position.toArray());
    logInfo('Camera lookAt:', controls.target.toArray());
    logInfo('Controls target:', controls.target.toArray());

    const ambientLight = new THREE.AmbientLight(0x404040, 2);
    scene.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(1, 1, 1);
    scene.add(directionalLight);

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    const resizeObserver = new ResizeObserver(() => {
      const newWidth = container.clientWidth;
      const newHeight = container.clientHeight;
      camera.aspect = newWidth / newHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(newWidth, newHeight);
    });
    resizeObserver.observe(container);

    setIsSceneReady(true);
  }, [calculateWorldScale, isMobile]);

  useEffect(() => {
    if (gridlines && sceneRef.current) {
      if (showGridlines) {
        gridlines.createGrid(sceneRef.current);
      } else {
        sceneRef.current.remove(gridlines.grid);
      }
    }
  }, [showGridlines, gridlines]);


  const toggleBoundingBoxes = useCallback(() => {
    setShowBoundingBoxes((prev) => !prev);
    if (sceneRef.current) {
      sceneRef.current.traverse((object) => {
        if (object.userData && object.userData.isBoundingBox) {
          object.material.visible = !showBoundingBoxes;
        }
      });
    }
  }, [showBoundingBoxes]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const handleResetView = useCallback(() => {
    if (cameraRef.current && controlsRef.current && initialCameraPositionRef.current) {
      const { position, target } = initialCameraPositionRef.current;
      cameraRef.current.position.copy(position);
      cameraRef.current.lookAt(target);
      controlsRef.current.target.copy(target);
      controlsRef.current.update();
      if (rendererRef.current && sceneRef.current) {
        rendererRef.current.render(sceneRef.current, cameraRef.current);
      }
    }
  }, []);

  const handleExportPDF = useCallback(() => {
    if (sceneRef.current && cameraRef.current && layoutRef.current) {
      try {
        logInfo('Starting PDF export');
        generateVisualizerPDF(sceneRef.current, cameraRef.current, layoutRef.current, false, (progress, message) => {
          logInfo(`PDF Export Progress: ${progress}% - ${message}`);
        }).then(() => {
          logInfo('PDF export completed successfully');
        }).catch((error) => {
          logError('Error during PDF export:', error);
        });
      } catch (error) {
        logError('Error initiating PDF export:', error);
      }
    } else {
      logError('Scene, camera, or layout is not ready for PDF export');
    }
  }, []);

  const handleExportDXFPrint = useCallback(() => {
    if (sceneRef.current && cameraRef.current && layoutRef.current) {
      try {
        logInfo('Starting DXF Print export');
        generateVisualizerPDF(sceneRef.current, cameraRef.current, layoutRef.current, true, (progress, message) => {
          logInfo(`DXF Print Export Progress: ${progress}% - ${message}`);
        }).then(() => {
          logInfo('DXF Print export completed successfully');
        }).catch((error) => {
          logError('Error during DXF Print export:', error);
        });
      } catch (error) {
        logError('Error initiating DXF Print export:', error);
      }
    } else {
      logError('Scene, camera, or layout is not ready for DXF Print export');
    }
  }, []);

  const switchViewMode = useCallback(() => {
    if (!layoutRef.current || !layoutRef.current.viewpoints) return;
    setIs3DMode((prevMode) => !prevMode);
    const newMode = is3DMode ? 'DefaultCamera2D' : 'DefaultCamera3D';
    const viewpoint = layoutRef.current.viewpoints[newMode];
    if (cameraRef.current && controlsRef.current && viewpoint) {
      applyViewpoint(cameraRef.current, controlsRef.current, viewpoint);
    }
  }, [is3DMode, applyViewpoint]);

  const moveCameraTo = useCallback((x, y, z, lookAtX, lookAtY, lookAtZ) => {
    if (cameraRef.current && controlsRef.current) {
      cameraRef.current.position.set(x, y, z);
      cameraRef.current.updateProjectionMatrix();
      if (lookAtX !== undefined && lookAtY !== undefined && lookAtZ !== undefined) {
        controlsRef.current.target.set(lookAtX, lookAtY, lookAtZ);
      }
      controlsRef.current.update();
      if (rendererRef.current && sceneRef.current) {
        rendererRef.current.render(sceneRef.current, cameraRef.current);
      }
      logInfo(`Camera moved to (${x}, ${y}, ${z}), looking at (${lookAtX}, ${lookAtY}, ${lookAtZ})`);
    } else {
      logError('Camera or controls not accessible.');
    }
  }, []);

  useEffect(() => {
    window.moveCameraTo = moveCameraTo;
    return () => {
      delete window.moveCameraTo;
    };
  }, [moveCameraTo]);

  const handleShowWiring = useCallback(() => {
    if (selectedModel) {
      setWiringDialogKey(prevKey => prevKey + 1);
      setShowWiringDialog(true);
    }
  }, [selectedModel]);

  const handleCloseWiringDialog = useCallback(() => {
    setShowWiringDialog(false);
    // Force garbage collection
    setTimeout(() => {
      setWiringDialogKey(prevKey => prevKey + 1);
    }, 100);
  }, []);

  const renderSnackbarContent = () => {
    if (!selectedModel) return null;
    return (
      <Box position="relative" pt={3}>
        {' '}
        {/* Add padding top to make room for the close button */}
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setSnackbarOpen(false)}
          sx={{
            position: 'absolute',
            top: 4,
            right: 4,
            color: 'white', // Ensure the icon is visible against the dark background
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <Typography variant="subtitle1" gutterBottom fontWeight="bold">
          {selectedModel.name}
        </Typography>
        <Typography variant="body2">Controller: {selectedModel.controller || 'N/A'}</Typography>
        <Typography variant="body2">Port: {selectedModel.port || 'N/A'}</Typography>
        <Typography variant="body2">String Type: {selectedModel.stringType || 'N/A'}</Typography>
        <Typography variant="body2">
          Start Channel: {selectedModel.startChannel || 'N/A'}
        </Typography>
        <Typography variant="body2">Pixel Count: {selectedModel.pixelCount || 'N/A'}</Typography>
      </Box>
    );
  };

  const handleZoom = useCallback((delta) => {
    if (cameraRef.current && controlsRef.current) {
      const zoomSpeed = 0.1;
      const zoomFactor = Math.pow(0.95, -delta * zoomSpeed); // Note the negative delta

      const direction = new THREE.Vector3().subVectors(
        controlsRef.current.target,
        cameraRef.current.position
      );
      const distance = direction.length();

      // Prevent zooming closer than minDistance or farther than maxDistance
      if (
        (distance > controlsRef.current.minDistance || delta < 0) &&
        (distance < controlsRef.current.maxDistance || delta > 0)
      ) {
        direction.multiplyScalar(1 - zoomFactor);
        cameraRef.current.position.addVectors(controlsRef.current.target, direction);
      }

      controlsRef.current.update();
      if (rendererRef.current && sceneRef.current) {
        rendererRef.current.render(sceneRef.current, cameraRef.current);
      }
    }
  }, []);

  useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault();
      const delta = event.deltaY;
      handleZoom(delta);
    };

    const currentMountRef = mountRef.current;
    if (currentMountRef) {
      currentMountRef.addEventListener('wheel', handleWheel, {
        passive: false,
      });
    }

    return () => {
      if (currentMountRef) {
        currentMountRef.removeEventListener('wheel', handleWheel);
      }
    };
  }, [handleZoom]);

  const toggleFullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  }, []);

  const actions = [
    { icon: <ZoomIn />, name: 'Zoom In', action: () => handleZoom(-0.1) },
    { icon: <ZoomOut />, name: 'Zoom Out', action: () => handleZoom(0.1) },
    { icon: <ThreeDRotation />, name: 'Toggle 3D/2D', action: switchViewMode },
    { icon: <Refresh />, name: 'Reset View', action: handleResetView },
    {
      icon: showGridlines ? <GridOff /> : <GridOn />,
      name: 'Toggle Gridlines',
      action: () => setShowGridlines(!showGridlines),
    },
    {
      icon: <ViewInAr />,
      name: 'Toggle Bounding Boxes',
      action: toggleBoundingBoxes,
    },
    {
      icon: isFullscreen ? <FullscreenExit /> : <Fullscreen />,
      name: isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen',
      action: toggleFullscreen,
    },
  ];

  const handleGoHome = () => {
    navigate('/');
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!layoutRef.current || !layoutRef.current.models || layoutRef.current.models.length === 0) {
    return (
      <CanvasContainer>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography variant="h6" gutterBottom sx={{ color: 'text.primary' }}>
            No layout loaded. Please upload or select a layout first.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleGoHome}>
            Go to Home Page
          </Button>
        </Box>
      </CanvasContainer>
    );
  }

  return (
    <VisualizerContainer>
      <SidePanel
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={!isMobile || sidePanelOpen}
        onClose={() => isMobile && setSidePanelOpen(false)}
      >
        <Box p={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6" sx={{ color: 'text.primary' }}>
              Controls
            </Typography>
            {isMobile && (
              <IconButton onClick={() => setSidePanelOpen(false)}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <Button onClick={switchViewMode} fullWidth variant="outlined" sx={{ mb: 1 }}>
            {is3DMode ? '2D View' : '3D View'}
          </Button>
          <Button onClick={handleResetView} fullWidth variant="outlined" sx={{ mb: 1 }}>
            Reset View
          </Button>
          <Button onClick={handleExportPDF} fullWidth variant="outlined" sx={{ mb: 1 }}>
            Export PDF
          </Button>
          <Button onClick={handleExportDXFPrint} fullWidth variant="outlined" sx={{ mb: 1 }}>
            Export DXF Print
          </Button>
          <Button
            onClick={() => setShowGridlines(!showGridlines)}
            fullWidth
            variant="outlined"
            sx={{ mb: 1 }}
          >
            {showGridlines ? 'Hide Gridlines' : 'Show Gridlines'}
          </Button>
          <Button onClick={toggleBoundingBoxes} fullWidth variant="outlined" sx={{ mb: 1 }}>
            {showBoundingBoxes ? 'Hide Bounding Boxes' : 'Show Bounding Boxes'}
          </Button>
          <Tooltip title="Red indicates the start of a chain, Green shows subsequent connections">
            <Button
              onClick={() => setShowConnections(!showConnections)}
              fullWidth
              variant="outlined"
              sx={{ mb: 1 }}
            >
              {showConnections ? 'Hide Connections' : 'Show Connections'}
            </Button>
          </Tooltip>
          <Button
            variant="contained"
            color="primary"
            onClick={handleShowWiring}
            disabled={!selectedModel}
            fullWidth
            sx={{ mt: 1, opacity: selectedModel ? 1 : 0.5 }}
          >
            Show Wiring
          </Button>
        </Box>
      </SidePanel>
      <CanvasContainer ref={mountRef} />
      {isMobile && (
        <Fab
          color="primary"
          aria-label="toggle side panel"
          onClick={() => setSidePanelOpen(!sidePanelOpen)}
          sx={{
            position: 'absolute',
            top: 16,
            left: 16,
            zIndex: 1300,
          }}
        >
          <MenuIcon />
        </Fab>
      )}
      {snackbarOpen && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{
            position: 'absolute',
            left: `${snackbarPosition.x}px`,
            top: `${snackbarPosition.y + 64}px`, // Increase offset to avoid overlapping with the menu
            transform: 'translate(-50%, 0)',
            zIndex: 9999, // Ensure it's above other UI elements
          }}
        >
          <Box
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              padding: '16px',
              borderRadius: '4px',
              maxWidth: '300px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            {renderSnackbarContent()}
          </Box>
        </Snackbar>
      )}
      {showWiringDialog && selectedModel && (
        <FullScreenWiringDialog
          key={wiringDialogKey}
          model={{ name: selectedModel.name, model: selectedModel.model }}
          open={showWiringDialog}
          onClose={handleCloseWiringDialog}
          layoutName={layoutName}  // Pass the layout name here
        />
      )}
    </VisualizerContainer>
  );
};

export default Visualizer;
