import { logInfo, logError } from '../../utils/logger';

export const parseTreeModel = (modelData) => {
  logInfo('Parsing Tree model:', modelData);
  try {
    const {
      parm1,
      parm2,
      parm3,
      displayAs,
      treeRotation,
      treeSpiralRotations,
      treeBottomTopRatio,
      treePerspective,
      dir,
      startSide,
    } = modelData;

    const strings = parseInt(parm1) || 1;
    const lightsPerString = parseInt(parm2) || 50;
    const strandsPerString = parseInt(parm3) || 1;
    const lightsPerStrand = Math.floor(lightsPerString / strandsPerString);

    const displayMatch = displayAs ? displayAs.match(/Tree\s+(\d+|Flat|Ribbon)/) : null;
    const treeType = displayMatch ? displayMatch[1] : '360';

    let degrees;
    if (treeType === 'Flat') {
      degrees = 0;
    } else if (treeType === 'Ribbon') {
      degrees = -1;
    } else {
      degrees = Math.min(parseInt(treeType) || 360, 360);
    }

    const rotation = parseFloat(treeRotation) || 0;
    const spiralRotations = parseFloat(treeSpiralRotations) || 0;
    const botTopRatio = treeBottomTopRatio !== undefined ? parseFloat(treeBottomTopRatio) : 6.0;
    const perspective = parseFloat(treePerspective) || 0.2;
    const isLtoR = dir !== 'R';
    const isBotToTop = startSide === 'B';

    const totalLights = strings * lightsPerString;
    const totalStrands = strings * strandsPerString;
    const renderHt = lightsPerStrand * 3;
    const renderWi = renderHt / 1.8;

    const radians = (degrees * Math.PI) / 180;
    const radius = renderWi / 2.0;
    let topRadius = radius;
    if (botTopRatio !== 0) {
      topRadius = radius / Math.abs(botTopRatio);
    }
    if (botTopRatio < 0) {
      [topRadius, radius] = [radius, topRadius];
    }

    let startAngle = -radians / 2.0;
    const angleIncr =
      degrees < 350 && totalStrands > 1 ? radians / (totalStrands - 1) : radians / totalStrands;

    startAngle += (rotation * Math.PI) / 180;

    const yPos = calculateYPositions(lightsPerStrand, spiralRotations, botTopRatio);

    const ytop = renderHt;
    const ybot = 0;

    const points = {};
    let nodeNumber = 1;

    for (let s = 0; s < totalStrands; s++) {
      const angle = startAngle + s * angleIncr;
      for (let l = 0; l < lightsPerStrand; l++) {
        const posOnString = yPos[l] / (lightsPerStrand - 1);
        const r = radius + (topRadius - radius) * posOnString;

        let finalAngle = angle;
        if (spiralRotations !== 0) {
          finalAngle += posOnString * spiralRotations * Math.PI * 2;
        }

        const x = r * Math.sin(finalAngle);
        const z = r * Math.cos(finalAngle);
        const y = ybot + (ytop - ybot) * posOnString - renderHt / 2.0;

        if (!points[nodeNumber]) {
          points[nodeNumber] = [];
        }
        points[nodeNumber].push({ x: Math.round(x), y: Math.round(y) });
        nodeNumber++;
      }
    }

    logInfo('Generated points:', {
      totalPoints: Object.keys(points).length,
      samplePoint: points[1],
    });

    let minX = Infinity,
      maxX = -Infinity,
      minY = Infinity,
      maxY = -Infinity;
    Object.values(points).forEach((pointArray) => {
      pointArray.forEach((point) => {
        minX = Math.min(minX, point.x);
        maxX = Math.max(maxX, point.x);
        minY = Math.min(minY, point.y);
        maxY = Math.max(maxY, point.y);
      });
    });

    const width = maxX - minX;
    const height = maxY - minY;

    // Adjust all points to be centered
    Object.values(points).forEach((pointArray) => {
      pointArray.forEach((point) => {
        point.x = ((point.x - minX) / width) * renderWi;
        point.y = ((point.y - minY) / height) * renderHt;
      });
    });

    return { rows: renderHt, cols: renderWi, points };
  } catch (error) {
    logError('Error in parseTreeModel:', error);
    throw error;
  }
};

const calculateYPositions = (lightsPerStrand, spiralRotations, botTopRatio) => {
  const yPos = new Array(lightsPerStrand).fill(0);

  if (spiralRotations === 0) {
    for (let i = 0; i < lightsPerStrand; i++) {
      yPos[i] = i;
    }
  } else {
    const segments = 10;
    const lengths = new Array(segments).fill(0);
    const radius = 0.5;
    const topRadius = radius / Math.abs(botTopRatio);
    const rgap = (radius - topRadius) / segments;
    let total = 0;

    for (let x = 0; x < segments; x++) {
      lengths[x] = 2.0 * Math.PI * (radius - rgap * x);
      lengths[x] *= spiralRotations / segments;
      lengths[x] = Math.sqrt(lengths[x] * lengths[x] + Math.pow(lightsPerStrand / segments, 2));
      total += lengths[x];
    }

    let curSeg = 0;
    let lightsInSeg = Math.round((lengths[0] * lightsPerStrand) / total);
    let curLightInSeg = 0;

    for (let x = 0; x < lightsPerStrand; x++) {
      if (curLightInSeg >= lightsInSeg) {
        curSeg++;
        curLightInSeg = 0;
        if (curSeg === segments - 1) {
          lightsInSeg = lightsPerStrand - x;
        } else {
          lightsInSeg = Math.round((lengths[curSeg] * lightsPerStrand) / total);
        }
      }
      yPos[x] = x;
      curLightInSeg++;
    }
  }

  return yPos;
};
