import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo, logWarning, logError } from '../../utils/logger';

class ImageModel extends BaseModel {
    constructor(modelData) {
        super(modelData);
        this.name = 'ImageModel';
        this.parseModelData();
    }

    parseModelData() {
        const { Width, Height, Image } = this.modelData;
        this.width = parseFloat(Width) || 1;
        this.height = parseFloat(Height) || 1;
        this.imagePath = Image || '';

        logInfo('ImageModel parameters:', {
            width: this.width,
            height: this.height,
            imagePath: this.imagePath,
        });
    }

    createGeometry() {
        const geometry = new THREE.PlaneGeometry(this.width, this.height);
        return geometry;
    }

    createMesh() {
        const geometry = this.createGeometry();
        const texture = new THREE.TextureLoader().load(this.imagePath);
        const material = new THREE.MeshBasicMaterial({
            map: texture,
            side: THREE.DoubleSide,
        });

        const mesh = new THREE.Mesh(geometry, material);
        return this.applyTransformations(mesh);
    }

    applyTransformations(object) {
        const { worldPos } = this.modelData.screenLocation;
        object.position.set(worldPos.x, worldPos.y, worldPos.z);
        return object;
    }

    getDimension() {
        return Math.max(this.width, this.height);
    }
}

export default ImageModel;