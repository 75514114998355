import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo } from '../../utils/logger';

class SingleLineModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.name = 'SingleLineModel';
    this.parseModelData();
  }

  parseModelData() {
    const { parm1, parm2, parm3, startSide, dir, pixelSize, screenLocation, rotation, transparency } =
      this.modelData;
    this.numStrings = parseInt(parm1) || 1;
    this.nodesPerString = parseInt(parm2) || 1;
    this.lightsPerNode = parseInt(parm3) || 1;
    this.startSide = startSide || 'B';
    this.direction = dir || 'L';
    this.isLtoR = this.direction === 'L';
    this.pixelSize = parseFloat(pixelSize) || 2;
    this.transparency = Math.min(Math.max(parseFloat(transparency) || 0, 0), 100);
    this.startPos = new THREE.Vector3(
      parseFloat(screenLocation.worldPos.x) || 0,
      parseFloat(screenLocation.worldPos.y) || 0,
      parseFloat(screenLocation.worldPos.z) || 0
    );
    this.endPos = new THREE.Vector3(
      this.startPos.x + parseFloat(screenLocation.endPos.x) || 0,
      this.startPos.y + parseFloat(screenLocation.endPos.y) || 0,
      this.startPos.z + parseFloat(screenLocation.endPos.z) || 0
    );
    this.rotation = rotation;

    logInfo('SingleLine parameters:', {
      numStrings: this.numStrings,
      nodesPerString: this.nodesPerString,
      lightsPerNode: this.lightsPerNode,
      startSide: this.startSide,
      direction: this.direction,
      pixelSize: this.pixelSize,
      transparency: this.transparency,
      startPos: this.startPos,
      endPos: this.endPos,
      rotation: this.rotation,
    });
  }

  createGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    const totalLights = this.numStrings * this.nodesPerString * this.lightsPerNode;
    const isSingleNode = totalLights === 1;

    if (isSingleNode) {
      // Handle single-node case
      positions.push(0, 0, 0);
      colors.push(1, 1, 1); // White color for the single light
    } else {
      // Multi-node case
      const lineVector = new THREE.Vector3().subVectors(this.endPos, this.startPos);
      const lineLength = lineVector.length();
      const step = lineLength / (totalLights - 1);

      for (let i = 0; i < totalLights; i++) {
        const t = this.isLtoR ? i / (totalLights - 1) : 1 - i / (totalLights - 1);
        const position = new THREE.Vector3().lerpVectors(this.startPos, this.endPos, t);

        positions.push(position.x, position.y, position.z);

        if (i === 0) {
          colors.push(0, 1, 1); // Teal color for the first light
        } else if (i === totalLights - 1) {
          colors.push(0.5, 0, 0); // Dull red color for the last light
        } else {
          colors.push(1, 1, 1); // White color for all other lights
        }
      }
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    logInfo('SingleLineModel geometry created', {
      totalLights,
      vertices: positions.length / 3,
      isSingleNode
    });

    return geometry;
  }

  createMesh() {
    const geometry = this.createGeometry();
    const totalLights = this.numStrings * this.nodesPerString * this.lightsPerNode;
    const isSingleNode = totalLights === 1;

    const material = new THREE.PointsMaterial({
      size: this.pixelSize,
      sizeAttenuation: true,
      vertexColors: true,
      map: this.createCircleTexture(),
      transparent: this.transparency > 0,
      opacity: 1 - (this.transparency / 100),
      alphaTest: 0.1,
      depthWrite: false,
    });

    const points = new THREE.Points(geometry, material);
    return this.applyTransformations(points, isSingleNode);
  }

  applyTransformations(object, isSingleNode) {
    if (isSingleNode) {
      // Single node case
      object.position.copy(this.startPos);
    } else {
      // Multi-node case
      // No additional transformations needed as positions are set in createGeometry
    }

    object.rotation.set(
      THREE.MathUtils.degToRad(this.rotation.x),
      THREE.MathUtils.degToRad(this.rotation.y),
      THREE.MathUtils.degToRad(this.rotation.z)
    );

    logInfo('Applied transformations to SingleLineModel:', {
      position: object.position,
      rotation: object.rotation,
      isSingleNode
    });

    return object;
  }

  createCircleTexture() {
    const canvas = document.createElement('canvas');
    canvas.width = 128;
    canvas.height = 128;
    const context = canvas.getContext('2d');

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = canvas.width / 2;

    const gradient = context.createRadialGradient(centerX, centerY, 0, centerX, centerY, radius);
    gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
    gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0.5)');
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

    context.beginPath();
    context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
    context.fillStyle = gradient;
    context.fill();

    const texture = new THREE.CanvasTexture(canvas);
    texture.premultiplyAlpha = true;
    return texture;
  }
}

export default SingleLineModel;
