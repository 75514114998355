import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box, CircularProgress } from '@mui/material';
import axios from 'axios';

const VerifyEmail = () => {
  const [verificationStatus, setVerificationStatus] = useState('verifying');
  const { token } = useParams();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        console.log('Attempting to verify email with token:', token);
        const response = await axios.get(`/auth/verify-email/${token}`);
        console.log('Verification response:', response.data);
        setVerificationStatus('success');
      } catch (error) {
        console.error('Verification error:', error.response ? error.response.data : error);
        setVerificationStatus('error');
      }
    };
    verifyEmail();
  }, [token]);

  if (verificationStatus === 'verifying') {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box textAlign="center" mt={8}>
      <Typography variant="h4" gutterBottom>
        {verificationStatus === 'success' ? 'Email Verified' : 'Verification Failed'}
      </Typography>
      <Typography variant="body1">
        {verificationStatus === 'success'
          ? 'Your email has been successfully verified. You can now log in to your account.'
          : 'There was an error verifying your email. The verification link may have expired or is invalid.'}
      </Typography>
    </Box>
  );
};

export default VerifyEmail;
