import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo, logWarning, logError } from '../../utils/logger';

class MultiPointModel extends BaseModel {
    constructor(modelData) {
        super(modelData);
        this.name = 'MultiPointModel';
        this.parseModelData();
    }

    parseModelData() {
        const { points, StringType } = this.modelData;
        this.points = points || [];
        this.stringType = StringType || 'RGB';

        logInfo('MultiPointModel parameters:', {
            pointCount: this.points.length,
            stringType: this.stringType,
        });
    }

    createGeometry() {
        const geometry = new THREE.BufferGeometry();
        const positions = [];
        const colors = [];

        this.points.forEach((point, index) => {
            positions.push(point.x, point.y, point.z);

            if (index === 0) {
                colors.push(0, 1, 1); // Teal color for the first point
            } else if (index === this.points.length - 1) {
                colors.push(0.5, 0, 0); // Dull red color for the last point
            } else {
                colors.push(1, 1, 1); // White color for all other points
            }
        });

        geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

        return geometry;
    }

    createMesh() {
        const geometry = this.createGeometry();
        const material = new THREE.PointsMaterial({
            size: parseFloat(this.modelData.PixelSize) || 2,
            sizeAttenuation: false,
            vertexColors: true,
        });

        const points = new THREE.Points(geometry, material);
        return this.applyTransformations(points);
    }

    applyTransformations(object) {
        const { worldPos } = this.modelData.screenLocation;
        object.position.set(worldPos.x, worldPos.y, worldPos.z);
        return object;
    }

    getDimension() {
        if (this.points.length === 0) return 1;

        const bbox = new THREE.Box3().setFromPoints(this.points.map(p => new THREE.Vector3(p.x, p.y, p.z)));
        return Math.max(bbox.max.x - bbox.min.x, bbox.max.y - bbox.min.y, bbox.max.z - bbox.min.z);
    }
}

export default MultiPointModel;