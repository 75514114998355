import * as THREE from 'three';
import DmxModel from '../DmxModel';

class DmxColorAbilityCMY extends DmxModel {
    constructor(modelData) {
        super(modelData);
        this.cyanChannel = modelData.cyanChannel || 1;
        this.magentaChannel = modelData.magentaChannel || 2;
        this.yellowChannel = modelData.yellowChannel || 3;
        this.whiteChannel = modelData.whiteChannel || 0;
        this.color = new THREE.Color(1, 1, 1); // Default to white
    }

    createMesh() {
        // Create three overlapping circular discs to represent CMY color filters
        const geometry = new THREE.CircleGeometry(0.5, 32);
        const cyanMaterial = new THREE.MeshBasicMaterial({ color: 0x00FFFF, transparent: true, opacity: 0.5, side: THREE.DoubleSide });
        const magentaMaterial = new THREE.MeshBasicMaterial({ color: 0xFF00FF, transparent: true, opacity: 0.5, side: THREE.DoubleSide });
        const yellowMaterial = new THREE.MeshBasicMaterial({ color: 0xFFFF00, transparent: true, opacity: 0.5, side: THREE.DoubleSide });

        const cyanMesh = new THREE.Mesh(geometry, cyanMaterial);
        const magentaMesh = new THREE.Mesh(geometry, magentaMaterial);
        const yellowMesh = new THREE.Mesh(geometry, yellowMaterial);

        // Position the discs slightly apart
        cyanMesh.position.z = 0.02;
        magentaMesh.position.z = 0.01;
        yellowMesh.position.z = 0;

        const group = new THREE.Group();
        group.add(cyanMesh);
        group.add(magentaMesh);
        group.add(yellowMesh);

        return group;
    }

    updateColor(c, m, y) {
        // Convert CMY to RGB
        const r = 1 - c;
        const g = 1 - m;
        const b = 1 - y;

        this.color.setRGB(r, g, b);
        if (this.mesh) {
            this.mesh.children[0].material.opacity = c;
            this.mesh.children[1].material.opacity = m;
            this.mesh.children[2].material.opacity = y;
        }
    }

    // Method to update the color based on DMX values
    updateFromDmxValues(dmxValues) {
        const c = dmxValues[this.cyanChannel - 1] / 255;
        const m = dmxValues[this.magentaChannel - 1] / 255;
        const y = dmxValues[this.yellowChannel - 1] / 255;
        const w = this.whiteChannel > 0 ? dmxValues[this.whiteChannel - 1] / 255 : 0;

        if (w > 0) {
            this.updateColor(0, 0, 0);
            if (this.mesh) {
                this.mesh.children.forEach(child => {
                    child.material.opacity = 1 - w;
                });
            }
        } else {
            this.updateColor(c, m, y);
        }
    }

    getBeamColor() {
        return this.color;
    }

    isColorChannel(channel) {
        return channel === this.cyanChannel ||
            channel === this.magentaChannel ||
            channel === this.yellowChannel ||
            channel === this.whiteChannel;
    }

    getNumChannels() {
        return [this.cyanChannel, this.magentaChannel, this.yellowChannel, this.whiteChannel]
            .filter(channel => channel > 0).length;
    }
}

export default DmxColorAbilityCMY;