import * as THREE from 'three';
import BaseModel from './BaseModel';

class CubeModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.name = 'CubeModel';
    this.parseModelData();
  }

  parseModelData() {
    const {
      parm1,
      parm2,
      parm3,
      Start,
      Style,
      StrandPerLine,
      StrandPerLayer,
      StringType,
      PixelSize,
      screenLocation,
      rotation,
    } = this.modelData;

    this.width = parseInt(parm1) || 1;
    this.height = parseInt(parm2) || 1;
    this.depth = parseInt(parm3) || 1;
    this.startLocation = Start || 'Front Bottom Left';
    this.stringDirection = Style || 'Vertical Front/Back';
    this.strandStyle = StrandPerLine || 'Zig Zag';
    this.strandPerLayer = StrandPerLayer === 'TRUE';
    this.stringType = StringType || 'RGB';
    this.pixelSize = parseFloat(PixelSize) || 2;
    this.totalNodes = this.width * this.height * this.depth;
    this.singleNode = this.stringType === 'Single Color';

    this.worldPos = new THREE.Vector3(
      screenLocation.worldPos.x,
      screenLocation.worldPos.y,
      screenLocation.worldPos.z
    );
    this.scale = new THREE.Vector3(
      screenLocation.width,
      screenLocation.height,
      screenLocation.depth
    );
    this.rotation = new THREE.Vector3(
      THREE.MathUtils.degToRad(rotation.x),
      THREE.MathUtils.degToRad(rotation.y),
      THREE.MathUtils.degToRad(rotation.z)
    );

    this.calculateTransformations();

    console.log('Cube parameters:', {
      width: this.width,
      height: this.height,
      depth: this.depth,
      startLocation: this.startLocation,
      stringDirection: this.stringDirection,
      strandStyle: this.strandStyle,
      strandPerLayer: this.strandPerLayer,
      stringType: this.stringType,
      pixelSize: this.pixelSize,
      totalNodes: this.totalNodes,
      singleNode: this.singleNode,
      worldPos: this.worldPos,
      scale: this.scale,
      rotation: this.rotation,
    });
  }

  calculateTransformations() {
    const TOP_BOT_LEFT_RIGHT = [
      'Front Bottom Left',
      'Front Bottom Right',
      'Front Top Left',
      'Front Top Right',
      'Back Bottom Left',
      'Back Bottom Right',
      'Back Top Left',
      'Back Top Right',
    ];

    const CUBE_STYLES = [
      'Vertical Front/Back',
      'Vertical Left/Right',
      'Horizontal Front/Back',
      'Horizontal Left/Right',
      'Stacked Front/Back',
      'Stacked Left/Right',
    ];

    const transformations = [
      [1, 0, -1, 0],   // FBL V FB
      [0, 0, -1, 1],   //     V LR
      [0, -1, 0, 1],   //     H FB
      [0, 0, 0, 0],    //     H LR
      [-1, 2, 0, 1],   //     S FB
      [-1, -1, 0, 0],  //     S LR
      [1, 0, -1, 1],   // FBR V FB
      [0, 0, -1, 0],   //     V LR
      [0, -1, 0, 0],   //     H FB
      [0, 0, 0, 1],    //     H LR
      [-1, 2, 0, 0],   //     S FB
      [-1, -1, 0, 1],  //     S LR
      [1, 0, 1, 1],    // FTL V FB
      [0, 0, 1, 0],    //     V LR
      [0, -1, 2, 0],   //     H FB
      [0, 0, 2, 1],    //     H LR
      [-1, 2, 2, 0],   //     S FB
      [-1, -1, 2, 1],  //     S LR
      [1, 0, 1, 0],    // FTR V FB
      [0, 0, 1, 1],    //     V LR
      [0, -1, 2, 1],   //     H FB
      [0, 0, 2, 0],    //     H LR
      [-1, 2, 2, 1],   //     S FB
      [-1, -1, 2, 0],  //     S LR
      [-1, 0, -1, 1],  // BBL V FB
      [0, 2, 1, 0],    //     V LR
      [0, 1, 0, 0],    //     H FB
      [0, 2, 0, 1],    //     H LR
      [-1, 0, 0, 0],   //     S FB
      [-1, 1, 0, 1],   //     S LR
      [-1, 0, -1, 0],  // BBR V FB
      [0, 2, 1, 1],    //     V LR
      [0, 1, 0, 1],    //     H FB
      [0, 2, 0, 0],    //     H LR
      [-1, 0, 0, 1],   //     S FB
      [-1, 1, 0, 0],   //     S LR
      [-1, 0, 1, 0],   // BTL V FB
      [0, 2, -1, 1],   //     V LR
      [0, -1, 2, 0],   //     H FB
      [2, 0, 0, 0],    //     H LR
      [-1, 2, 2, 0],   //     S FB
      [1, -1, 0, 0],   //     S LR
      [-1, 0, 1, 1],   // BTR V FB
      [0, 2, -1, 0],   //     V LR
      [0, -1, 2, 1],   //     H FB
      [2, 0, 0, 1],    //     H LR
      [-1, 2, 2, 1],   //     S FB
      [1, -1, 0, 1],   //     S LR
    ];

    const startIndex = TOP_BOT_LEFT_RIGHT.indexOf(this.startLocation);
    const styleIndex = CUBE_STYLES.indexOf(this.stringDirection);

    const calcTransformationIndex = () => {
      return startIndex * CUBE_STYLES.length + styleIndex;
    };

    const index = calcTransformationIndex();

    // Ensure index is within bounds
    const transformation = transformations[index % transformations.length];

    this.xr = transformation[0];
    this.yr = transformation[1];
    this.zr = transformation[2];
    this.xf = transformation[3];
  }

  createGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    const locations = this.buildCube();

    for (let i = 0; i < locations.length; i++) {
      const [x, y, z] = locations[i];

      positions.push(x, y, z);

      if (i === 0) {
        colors.push(0, 1, 1); // Teal color for the first light
      } else if (i === this.totalNodes - 1) {
        colors.push(0.5, 0, 0); // Dull red color for the last light
      } else {
        colors.push(1, 1, 1); // White color for all other lights
      }
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    return geometry;
  }

  buildCube() {
    let nodes = [];
    let width = this.width;
    let height = this.height;
    let depth = this.depth;

    // Pre-rotate dimensions
    let w = width;
    let h = height;
    let d = depth;

    if (Math.abs(this.zr) === 1) [w, h] = [h, w];
    if (Math.abs(this.yr) === 1) [w, d] = [d, w];
    if (Math.abs(this.xr) === 1) [h, d] = [d, h];

    const totalNodes = w * h * d;

    for (let i = 0; i < totalNodes; i++) {
      let z = Math.floor(i / (w * h));
      let baseLayer = i % (w * h);
      let y = Math.floor(baseLayer / w);
      let x;

      if (this.strandStyle === 'No Zig Zag' || y % 2 === 0) {
        x = baseLayer % w;
      } else if (this.strandStyle === 'Alternate Pixel') {
        let pos = baseLayer % w + 1;
        if (pos <= (w + 1) / 2) {
          x = 2 * (pos - 1);
        } else {
          x = (w - pos) * 2 + 1;
        }
      } else {
        x = w - baseLayer % w - 1;
      }

      if (!this.strandPerLayer) {
        // every 2nd layer starts at the top
        if (z % 2 !== 0) {
          y = h - y - 1;
          if (h % 2 !== 0 && this.strandStyle === 'Zig Zag') {
            x = w - x - 1;
          }
        }
      }

      // Apply rotations and flips
      let node = [x, y, z];
      [x, y, z] = this.applyRotations(node, w, h, d);

      if (this.xf > 0) {
        x = w - x - 1;
      }

      // Scale to fit in a 1x1x1 cube
      x = (x / (width - 1) - 0.5);
      y = (y / (height - 1) - 0.5);
      z = (z / (depth - 1) - 0.5);

      nodes.push([x, y, z]);
    }

    return nodes;
  }

  applyRotations(node, width, height, depth) {
    let [x, y, z] = node;

    let w = width;
    let h = height;
    let d = depth;

    const rotateX = (pt, by) => {
      for (let i = 0; i < Math.abs(by); i++) {
        if (by > 0) {
          [pt[1], pt[2]] = [d - pt[2] - 1, pt[1]];
        } else {
          [pt[1], pt[2]] = [pt[2], h - pt[1] - 1];
        }
        [h, d] = [d, h];
      }
    };

    const rotateY = (pt, by) => {
      for (let i = 0; i < Math.abs(by); i++) {
        if (by > 0) {
          [pt[0], pt[2]] = [pt[2], w - pt[0] - 1];
        } else {
          [pt[0], pt[2]] = [d - pt[2] - 1, pt[0]];
        }
        [w, d] = [d, w];
      }
    };

    const rotateZ = (pt, by) => {
      for (let i = 0; i < Math.abs(by); i++) {
        if (by > 0) {
          [pt[0], pt[1]] = [pt[1], w - pt[0] - 1];
        } else {
          [pt[0], pt[1]] = [h - pt[1] - 1, pt[0]];
        }
        [w, h] = [h, w];
      }
    };

    rotateX([x, y, z], this.xr);
    rotateY([x, y, z], this.yr);
    rotateZ([x, y, z], this.zr);

    return [x, y, z];
  }

  createMesh() {
    const geometry = this.createGeometry();
    const material = new THREE.PointsMaterial({
      size: this.pixelSize,
      sizeAttenuation: false,
      vertexColors: true,
    });

    const points = new THREE.Points(geometry, material);
    return this.applyTransformations(points);
  }

  applyTransformations(object) {
    object.position.copy(this.worldPos);
    object.scale.copy(this.scale);
    object.rotation.setFromVector3(this.rotation);

    console.log('Applied transformations:', {
      position: object.position,
      scale: object.scale,
      rotation: object.rotation,
    });

    return object;
  }
}

export default CubeModel;
