import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo } from '../../utils/logger';

class WindowFrameModel extends BaseModel {
  constructor(modelData) {
    super(modelData);
    this.name = 'WindowFrameModel';
    this.parseModelData();
  }

  parseModelData() {
    const { parm1, parm2, parm3, StartSide, Dir, screenLocation, rotation } = this.modelData;
    this.topBottomNodes = parseInt(parm1) || 1;
    this.leftRightNodes = parseInt(parm2) || 1;
    this.startSide = StartSide || 'B';
    this.direction = Dir || 'L';
    this.worldPos = new THREE.Vector3(
      parseFloat(screenLocation.worldPos.x) || 0,
      parseFloat(screenLocation.worldPos.y) || 0,
      parseFloat(screenLocation.worldPos.z) || 0
    );
    this.rotation = rotation;
    this.width = parseFloat(screenLocation.width) || 1;
    this.height = parseFloat(screenLocation.height) || 1;
    this.depth = parseFloat(screenLocation.depth) || 1;
    this.pixelSize = parseFloat(this.modelData.pixelSize) || 2;
    this.pixelCount = parseInt(this.modelData.pixelCount) || 0;

    logInfo('WindowFrameModel parsed data:', this);
  }

  createGeometry() {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    const totalNodes = this.pixelCount || this.topBottomNodes * 2 + this.leftRightNodes * 2;
    logInfo('Total nodes:', totalNodes);

    const width = Math.max(this.topBottomNodes, this.leftRightNodes) - 1;
    const height = this.leftRightNodes - 1;

    logInfo('Calculated dimensions:', { width, height });

    const addNode = (x, y, z = 0, isFirst = false) => {
      positions.push(x, y, z);
      if (isFirst) {
        colors.push(0, 1, 1); // Teal color for the first node
      } else {
        colors.push(1, 1, 1); // White color for other nodes
      }
    };

    // Top
    for (let i = 0; i < this.topBottomNodes; i++) {
      addNode(i, height, 0, i === 0);
    }

    // Right
    for (let i = height - 1; i >= 0; i--) {
      addNode(width, i, 0);
    }

    // Bottom
    for (let i = width; i >= 0; i--) {
      addNode(i, 0, 0);
    }

    // Left
    for (let i = 1; i < height; i++) {
      addNode(0, i, 0);
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    return geometry;
  }

  createMesh() {
    const geometry = this.createGeometry();
    const material = new THREE.PointsMaterial({
      size: this.pixelSize,
      sizeAttenuation: false,
      vertexColors: true,
    });

    const points = new THREE.Points(geometry, material);
    return this.applyTransformations(points);
  }

  applyTransformations(object) {
    object.position.copy(this.worldPos);

    object.rotation.set(
      THREE.MathUtils.degToRad(this.rotation.x),
      THREE.MathUtils.degToRad(this.rotation.y),
      THREE.MathUtils.degToRad(this.rotation.z)
    );

    object.scale.set(this.width, this.height, this.depth);

    logInfo('Applied transformations:', {
      position: object.position,
      rotation: object.rotation,
      scale: object.scale,
    });

    return object;
  }

  setWorldScale(scale) {
    this.worldScale = scale;
    logInfo('World scale set to:', scale);
  }
}

export default WindowFrameModel;
