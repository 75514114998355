export const parseCandyCanesModel = (modelData) => {
  const { parm1, parm2, parm3, CandyCaneHeight, CandyCaneReverse } = modelData;
  const numCanes = parseInt(parm1) || 1;
  const segmentsPerCane = parseInt(parm2) || 1;
  const lightsPerSegment = parseInt(parm3) || 1;
  const caneHeight = parseFloat(CandyCaneHeight) || 1.0;
  const reverse = CandyCaneReverse === 'true';

  const points = {};
  let globalNodeNumber = 1;

  const caneGap = 2.0;
  const widthPerCane = (segmentsPerCane * lightsPerSegment * 3.0) / 9.0;
  const lightsPerCane = segmentsPerCane * lightsPerSegment;
  const upright = Math.floor((lightsPerCane * 6) / 9);
  const arclights = lightsPerCane - upright;

  for (let cane = 0; cane < numCanes; cane++) {
    let localNodeNumber = 1;
    const x = cane * (widthPerCane + caneGap) + (reverse ? widthPerCane : 0);
    const cx = x + (reverse ? -widthPerCane / 2 : widthPerCane / 2);

    // Upright part
    for (let light = 0; light < upright; light++) {
      const y = caneHeight * light;
      points[globalNodeNumber] = [{ x, y, node: localNodeNumber }];
      globalNodeNumber++;
      localNodeNumber++;
    }

    // Curved part
    for (let light = 0; light < arclights; light++) {
      const arcAngle = Math.PI - (Math.PI * (light + 1)) / arclights;
      const y = (upright - 1) * caneHeight + (Math.sin(arcAngle) * widthPerCane) / 2;
      const xOffset = (Math.cos(arcAngle) * widthPerCane) / 2;
      const curveX = reverse ? cx - xOffset : cx + xOffset;
      points[globalNodeNumber] = [{ x: curveX, y, node: localNodeNumber }];
      globalNodeNumber++;
      localNodeNumber++;
    }
  }

  return {
    rows: segmentsPerCane * lightsPerSegment,
    cols: numCanes * (widthPerCane + caneGap),
    points
  };
};
