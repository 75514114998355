const isDevelopment = process.env.REACT_APP_ENV === 'development';

const customLog = (type, ...args) => {
  if (isDevelopment) {
    switch (type) {
      case 'error':
        console.error(...args);
        break;
      case 'warn':
        console.warn(...args);
        break;
      case 'info':
        console.info(...args);
        break;
      default:
        console.log(...args);
    }
  } else {
    // In production, we don't log anything
  }
};

export const logInfo = (...args) => customLog('info', ...args);
export const logWarning = (...args) => customLog('warn', ...args);
export const logError = (...args) => customLog('error', ...args);

// Display xLights in console for production mode
if (!isDevelopment) {
  console.log(`
  ██╗  ██╗██╗     ██╗ ██████╗ ██╗  ██╗████████╗███████╗
  ╚██╗██╔╝██║     ██║██╔════╝ ██║  ██║╚══██╔══╝██╔════╝
   ╚███╔╝ ██║     ██║██║  ███╗███████║   ██║   ███████╗
   ██╔██╗ ██║     ██║██║   ██║██╔══██║   ██║   ╚════██║
  ██╔╝ ██╗███████╗██║╚██████╔╝██║  ██║   ██║   ███████║
  ╚═╝  ╚═╝╚══════╝╚═╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝
  `);
}
