import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Button,
  Grid,
  Paper,
  Box,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
  useTheme,
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Alert } from '@mui/material';
import {
  Visibility,
  Share,
  Clear,
  Menu as MenuIcon,
  Home as HomeIcon,
  ViewInAr as VisualizeIcon,
  SettingsInputComponent as PortMappingIcon,
  BugReport as ReportIcon,
  Brightness4,
  Brightness7,
  ExitToApp as LogoutIcon,
} from '@mui/icons-material';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';
import GoogleIcon from '@mui/icons-material/Google';
import { motion, AnimatePresence } from 'framer-motion';
import FileUpload from './FileUpload';
import Visualizer from './visualizer';
import ControllerPortMapping from './ControllerPortMapping';
import BugReportForm from './BugReportForm';
import ErrorBoundary from './ErrorBoundary';
import UserLayouts from './UserLayouts';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import logo from '../assets/logo.png';
import logoImage from '../assets/apple-icon-144x144.png';
import SharedLayout from './SharedLayout';
import { LayoutProvider } from './LayoutContext';
import LoadingOverlay from './LoadingOverlay';
import { createErrorHandler, AppError } from '../utils/errorHandler';
import { logInfo, logWarning, logError } from '../utils/logger';
import GlobalErrorBoundary from './GlobalErrorBoundary';
import VerifyEmail from './VerifyEmail';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import { resendVerificationEmail } from '../utils/api';
import { countries } from '../utils/countries';
import { Link as MuiLink } from '@mui/material';
import CircleModelTest from './CircleModelTest';


// Axios interceptor
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    logError('Axios request interceptor error:', error);
    return Promise.reject(error);
  }
);

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.mode === 'dark' ? '#121212' : '#ffffff',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.appBar,
  height: '64px',
  '& .MuiToolbar-root': {
    minHeight: '64px',
    padding: '0 16px',
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const NavItems = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const StyledNavButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const MobileMenu = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: 250,
    background: theme.palette.background.default,
  },
}));

const MobileMenuItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const MobileMenuIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '40px',
  color: theme.palette.text.primary,
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  marginTop: '64px',
  marginBottom: theme.spacing(7),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(4),
  },
  backgroundColor: 'transparent',
  padding: 0,
  maxWidth: '100% !important',
  width: '100%',
  height: 'calc(100vh - 64px)', // Set a fixed height
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden', // Prevent scrolling on the container
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 0,
  boxShadow: 'none',
  border: 'none',
  minHeight: '100%', // Ensure the paper fills the available space
}));

const ConfirmDialog = ({ open, onClose, onConfirm, title, message }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const VisualizerContent = ({ layout, isSharedLayout, isLoading, setIsLoading }) => {
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const visualizationRef = useRef(null);

  useEffect(() => {
    const visualization = visualizationRef.current;
    if (!visualization) return;

    let lastDistance = 0;
    let lastTouchX = 0;
    let lastTouchY = 0;

    const handleTouchStart = (e) => {
      if (e.touches.length === 2) {
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];
        lastDistance = Math.hypot(touch1.clientX - touch2.clientX, touch1.clientY - touch2.clientY);
      } else if (e.touches.length === 1) {
        lastTouchX = e.touches[0].clientX;
        lastTouchY = e.touches[0].clientY;
      }
    };

    const handleTouchMove = (e) => {
      if (e.touches.length === 2) {
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];
        const distance = Math.hypot(
          touch1.clientX - touch2.clientX,
          touch1.clientY - touch2.clientY
        );
        const newScale = scale * (distance / lastDistance);
        setScale(Math.min(Math.max(newScale, 0.5), 3));
        lastDistance = distance;
      } else if (e.touches.length === 1) {
        const deltaX = e.touches[0].clientX - lastTouchX;
        const deltaY = e.touches[0].clientY - lastTouchY;
        setPosition((prev) => ({ x: prev.x + deltaX, y: prev.y + deltaY }));
        lastTouchX = e.touches[0].clientX;
        lastTouchY = e.touches[0].clientY;
      }
    };

    visualization.addEventListener('touchstart', handleTouchStart);
    visualization.addEventListener('touchmove', handleTouchMove);

    return () => {
      visualization.removeEventListener('touchstart', handleTouchStart);
      visualization.removeEventListener('touchmove', handleTouchMove);
    };
  }, [scale]);

  return (
    <Box
      ref={visualizationRef}
      sx={{
        position: 'relative',
        height: 'calc(100vh - 64px)',
        overflow: 'hidden',
      }}
    >
      <Visualizer
        layout={layout}
        isSharedLayout={isSharedLayout}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        style={{
          transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
          transition: 'transform 0.1s ease-out',
        }}
      />
    </Box>
  );
};

const LoginForm = ({ onLogin, onToggleForm, onForgotPassword }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showResendVerification, setShowResendVerification] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onLogin(email, password);
    } catch (err) {
      if (
        err.response &&
        err.response.status === 403 &&
        err.response.data.error === 'Please verify your email before logging in'
      ) {
        setError('Please verify your email before logging in.');
        setShowResendVerification(true);
      } else {
        setError('Invalid email or password');
        setShowResendVerification(false);
      }
    }
  };

  const handleResendVerification = async () => {
    try {
      await resendVerificationEmail(email);
      setError('Verification email resent. Please check your inbox.');
      setShowResendVerification(false);
    } catch (err) {
      setError('Failed to resend verification email. Please try again.');
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Sign In
      </Button>
      <Button
        fullWidth
        variant="outlined"
        startIcon={<GoogleIcon />}
        onClick={() => (window.location.href = '/auth/google')}
        sx={{ mb: 2 }}
      >
        Sign in with Google
      </Button>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <MuiLink component="button" variant="body2" onClick={onToggleForm}>
          {"Don't have an account? Sign Up"}
        </MuiLink>
        <MuiLink component="button" variant="body2" onClick={onForgotPassword}>
          Forgot password?
        </MuiLink>
      </Box>
      {error && (
        <Typography color="error" align="center" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      {showResendVerification && (
        <Button
          variant="text"
          color="primary"
          onClick={handleResendVerification}
          sx={{ mt: 1 }}
          fullWidth
        >
          Resend verification email
        </Button>
      )}
    </Box>
  );
};

const RegisterForm = ({ onRegister, onToggleForm }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onRegister(formData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="given-name"
            name="firstName"
            required
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
            value={formData.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            value={formData.lastName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={formData.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={formData.password}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="city"
            label="City"
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="state"
            label="State/Province"
            name="state"
            value={formData.state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="zipcode"
            label="Zipcode/Postal Code"
            name="zipcode"
            value={formData.zipcode}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth required>
            <InputLabel id="country-label">Country</InputLabel>
            <Select
              labelId="country-label"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              label="Country"
            >
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Sign Up
      </Button>
      <Typography align="center">
        <Link href="#" onClick={onToggleForm} variant="body2">
          Already have an account? Sign in
        </Link>
      </Typography>
    </Box>
  );
};

const AuthDialog = ({
  open,
  onClose,
  isLoginForm,
  setIsLoginForm,
  onLogin,
  onRegister,
  authError,
  onForgotPassword,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{isLoginForm ? 'Sign In' : 'Sign Up'}</DialogTitle>
      <DialogContent>
        {authError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {authError}
          </Alert>
        )}
        {isLoginForm ? (
          <LoginForm
            onLogin={onLogin}
            onToggleForm={() => setIsLoginForm(false)}
            onForgotPassword={onForgotPassword}
          />
        ) : (
          <RegisterForm onRegister={onRegister} onToggleForm={() => setIsLoginForm(true)} />
        )}
      </DialogContent>
    </Dialog>
  );
};

const AppContent = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [layoutId, setLayoutId] = useState(null);
  const [shareId, setShareId] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [layout, setLayout] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: '',
    message: '',
  });
  const [userLayouts, setUserLayouts] = useState([]);
  const [isLoadingLayouts, setIsLoadingLayouts] = useState(false);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode !== null ? JSON.parse(savedMode) : prefersDarkMode;
  });
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isVisualizerLoading, setIsVisualizerLoading] = useState(false);
  const [editingLayout, setEditingLayout] = useState(null);
  const [editingName, setEditingName] = useState('');
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [authError, setAuthError] = useState(null);
  const [authDialogOpen, setAuthDialogOpen] = useState(false);
  const [layoutsFetched, setLayoutsFetched] = useState(false);
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const [showResendVerification, setShowResendVerification] = useState(false);
  const [resendVerificationEmail, setResendVerificationEmail] = useState('');
  const handleError = createErrorHandler(setSnackbar);

  const [shouldFetchUser, setShouldFetchUser] = useState(true);

  const debugLayoutCount = useCallback(async () => {
    try {
      const response = await axios.get('/api/user/layouts/debug');
      // logInfo('Debug layout information:', response.data);
      if (response.data.userLayoutsCount !== response.data.mongoLayoutsCount) {
        logWarning('Mismatch between user layouts and MongoDB layouts');
        logWarning('User layouts:', response.data.userLayoutsCount);
        logWarning('MongoDB layouts:', response.data.mongoLayoutsCount);
      }
    } catch (error) {
      logError('Error fetching debug layout information:', error);
    }
  }, []);

  const fetchUserLayouts = useCallback(async () => {
    if (!user) return;
    setIsLoadingLayouts(true);
    try {
      const response = await axios.get('/api/user/layouts');
      setUserLayouts(response.data);
      setLayoutsFetched(true);
      // logInfo(`User layouts fetched successfully. Count: ${response.data.length}`);
      await debugLayoutCount(); // Call the debug function after fetching layouts
    } catch (error) {
      console.error('Error fetching user layouts:', error);
      handleError(new AppError('Failed to fetch user layouts. Please try again.', 500));
    } finally {
      setIsLoadingLayouts(false);
    }
  }, [user, handleError, debugLayoutCount]);

  useEffect(() => {
    if (user && (!layoutsFetched || isInitialFetch)) {
      fetchUserLayouts();
      setIsInitialFetch(false);
    }
  }, [user, layoutsFetched, fetchUserLayouts, isInitialFetch]);

  const handleEditLayout = useCallback((layout) => {
    setEditingLayout(layout);
    setEditingName(layout.name);
    logInfo(`Editing layout: ${layout.name}`);
  }, []);

  const handleSaveLayoutName = useCallback(async () => {
    if (!editingLayout) return;

    try {
      await axios.put(`/api/layout/${editingLayout._id}/name`, {
        name: editingName,
      });
      setEditingLayout(null);
      // Update the local state with the new name
      setUserLayouts((prevLayouts) =>
        prevLayouts.map((layout) =>
          layout._id === editingLayout._id ? { ...layout, name: editingName } : layout
        )
      );
      logInfo('Layout name updated successfully');
    } catch (error) {
      handleError(new AppError('Failed to update layout name. Please try again.', 500));
    }
  }, [editingLayout, editingName, handleError]);

  useEffect(() => {
    if (location.pathname === '/visualize') {
      setIsVisualizerLoading(true);
    } else {
      setIsVisualizerLoading(false);
    }
  }, [location]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', JSON.stringify(newMode));
      logInfo(`Dark mode ${newMode ? 'enabled' : 'disabled'}`);
      return newMode;
    });
  };

  useEffect(() => {
    const handleSystemPreferenceChange = (e) => {
      if (!localStorage.getItem('darkMode')) {
        setDarkMode(e.matches);
      }
    };
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    darkModeMediaQuery.addListener(handleSystemPreferenceChange);
    return () => darkModeMediaQuery.removeListener(handleSystemPreferenceChange);
  }, []);

  const customTheme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
      background: {
        default: darkMode ? '#121212' : '#ffffff',
        paper: darkMode ? '#1e1e1e' : '#ffffff',
      },
      text: {
        primary: darkMode ? '#ffffff' : '#000000',
        secondary: darkMode ? '#b0b0b0' : '#757575',
      },
      link: {
        main: darkMode ? '#90caf9' : '#3f51b5',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: darkMode ? '#121212' : '#ffffff',
            margin: 0,
            padding: 0,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: darkMode ? '#1e1e1e' : '#ffffff',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: darkMode ? '#b0b0b0' : 'rgba(0, 0, 0, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: darkMode ? '#ffffff' : 'rgba(0, 0, 0, 0.87)',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#3f51b5',
              },
            },
            '& .MuiInputLabel-root': {
              color: darkMode ? '#b0b0b0' : 'rgba(0, 0, 0, 0.6)',
            },
            '& .MuiInputBase-input': {
              color: darkMode ? '#ffffff' : 'rgba(0, 0, 0, 0.87)',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.MuiButton-containedPrimary': {
              backgroundColor: '#3f51b5',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#303f9f',
              },
            },
            '&.MuiButton-outlinedPrimary': {
              borderColor: darkMode ? '#ffffff' : '#3f51b5',
              color: darkMode ? '#ffffff' : '#3f51b5',
              '&:hover': {
                borderColor: darkMode ? '#b0b0b0' : '#303f9f',
                backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(63, 81, 181, 0.08)',
              },
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: darkMode ? '#90caf9' : '#3f51b5', // Bright blue for dark mode, primary color for light mode
            textDecoration: 'none',
            '&:hover': {
              color: darkMode ? '#bbdefb' : '#303f9f',
              textDecoration: 'underline',
            },
            // Styles for auth toggle link
            '&.auth-toggle-link': {
              color: darkMode ? '#90caf9' : '#3f51b5',
              '&:hover': {
                color: darkMode ? '#bbdefb' : '#303f9f',
              },
            },
            // Styles for footer links
            '&[color="textSecondary"]': {
              color: darkMode ? '#ffffff' : 'inherit',
              '&:hover': {
                color: darkMode ? '#bbdefb' : '#303f9f',
              },
            },
          },
        },
      },
    },
  });

  const fetchUser = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token && shouldFetchUser) {
      try {
        const response = await axios.get('/api/user');
        const userData = response.data;
        setUser({
          ...userData,
          maxLayouts: userData.maxLayouts || 3,
          layouts: userData.layouts || [],
        });
        logInfo('User data fetched successfully');
        setShouldFetchUser(false);
        return userData;
      } catch (error) {
        if (error.response && error.response.status === 429) {
          console.log('Rate limit reached, retrying in 5 seconds');
          setTimeout(() => setShouldFetchUser(true), 5000);
        } else {
          handleError(new AppError('Failed to fetch user data. Please try logging in again.', 401));
          setUser(null);
          localStorage.removeItem('token');
        }
        return null;
      }
    }
    return null;
  }, [handleError, shouldFetchUser, setUser]);

  useEffect(() => {
    if (shouldFetchUser) {
      fetchUser();
    }
  }, [fetchUser, shouldFetchUser]);

  const handleGoogleLoginSuccess = useCallback(
    async (token) => {
      logInfo('Handling Google login success, token received');
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      try {
        logInfo('Fetching user data');
        const response = await axios.get('/api/user');
        logInfo('User data response received');
        const userData = response.data;
        if (userData && userData.id) {
          logInfo('User data fetched successfully');
          setUser({
            ...userData,
            maxLayouts: userData.maxLayouts || 3,
            layouts: userData.layouts || [],
          });
          setSnackbar({
            open: true,
            message: 'Successfully logged in with Google!',
            severity: 'success',
          });
          logInfo('Google login successful');
          logInfo('Navigating to home page');
          navigate('/');
        } else {
          logError('Invalid user data received');
          throw new Error('Invalid user data received');
        }
      } catch (error) {
        logError('Error in handleGoogleLoginSuccess:', error);
        if (error.response) {
          logError('Error response:', error.response.data);
          logError('Error status:', error.response.status);
        }
        handleError(
          new AppError(`Failed to fetch user data after Google login: ${error.message}`, 500)
        );
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
      }
    },
    [navigate, handleError, setUser]
  );

  useEffect(() => {
    logInfo('Checking for Google login token');
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const error = urlParams.get('error');

    logInfo('URL params:', { token: token ? 'present' : 'absent', error: error || 'none' });

    if (token || error) {
      logInfo('Checking URL parameters for Google login');
      if (token) {
        logInfo('Token found in URL, handling Google login success');
        handleGoogleLoginSuccess(token);

        window.history.replaceState({}, document.title, window.location.pathname);
      } else if (error) {
        logError('Error found in URL:', error);
        handleError(new AppError(`Error during Google login: ${error}`, 400));

        window.history.replaceState({}, document.title, window.location.pathname);
      }
    }
  }, []);

  const fetchLayout = useCallback(
    async (id) => {
      try {
        setIsLoading(true);
        const response = await axios.get(`/api/layout/${id}`);
        setLayout(response.data);
        setLayoutId(id);
        setShareId(response.data.shareId);
        // logInfo(`Layout fetched successfully: ${id}`);
      } catch (error) {
        handleError(new AppError('Failed to fetch layout data. Please try again.', 500));
        setLayout(null);
        setLayoutId(null);
        setShareId(null);
        localStorage.removeItem('layoutId');
      } finally {
        setIsLoading(false);
      }
    },
    [handleError]
  );

  useEffect(() => {
    const storedLayoutId = localStorage.getItem('layoutId');
    if (storedLayoutId && !layout) {
      setLayoutId(storedLayoutId);
      fetchLayout(storedLayoutId);
    }
  }, [fetchLayout, layout]);

  const handleUploadSuccess = useCallback(
    async (response) => {
      setLayoutId(response.id);
      setShareId(response.shareId);
      localStorage.setItem('layoutId', response.id);
      setSnackbar({
        open: true,
        message: 'Files uploaded successfully!',
        severity: 'success',
      });
      logInfo('Files uploaded successfully');
      await fetchLayout(response.id);
      await fetchUserLayouts(); // Refresh user layouts
      if (response.userLayoutCount !== undefined) {
        logInfo(`User layouts updated after upload. New count: ${response.userLayoutCount}`);
        setUser(prevUser => ({
          ...prevUser,
          layouts: Array(response.userLayoutCount).fill(null) // Create a new array with the correct length
        }));
      }
    },
    [fetchLayout, fetchUserLayouts, setUser]
  );

  const checkLayoutConsistency = useCallback(async () => {
    if (user) {
      try {
        const response = await axios.get('/api/user/layouts/count');
        const serverCount = response.data.count;
        if (serverCount !== userLayouts.length) {
          logWarning(`Layout count mismatch. Server: ${serverCount}, Client: ${userLayouts.length}`);
          await fetchUserLayouts(); // Refresh layouts if there's a mismatch
        }
      } catch (error) {
        logError('Error checking layout consistency:', error);
      }
    }
  }, [user, userLayouts.length, fetchUserLayouts]);

  // Call this function after login and periodically
  useEffect(() => {
    if (user) {
      checkLayoutConsistency();
      const intervalId = setInterval(checkLayoutConsistency, 60000); // Check every minute
      return () => clearInterval(intervalId);
    }
  }, [user, checkLayoutConsistency]);

  const handleShare = useCallback(() => {
    if (shareId) {
      const url = `${window.location.origin}/share/${shareId}`;
      navigator.clipboard
        .writeText(url)
        .then(() => {
          setSnackbar({
            open: true,
            message: 'Share URL copied to clipboard!',
            severity: 'success',
          });
          logInfo('Share URL copied to clipboard');
        })
        .catch((err) => {
          handleError(new AppError('Failed to copy URL to clipboard. Please try again.', 500));
        });
    } else if (layoutId) {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      axios
        .post(`/api/layout/${layoutId}/share`, {}, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          const newShareId = response.data.shareId;
          setShareId(newShareId);
          const url = `${window.location.origin}/share/${newShareId}`;
          return navigator.clipboard.writeText(url);
        })
        .then(() => {
          setSnackbar({
            open: true,
            message: 'Share URL copied to clipboard!',
            severity: 'success',
          });
          logInfo('New share URL generated and copied to clipboard');
        })
        .catch((error) => {
          handleError(new AppError('Failed to generate share link. Please try again.', 500));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      logWarning('Attempted to share without a layout');
      setSnackbar({
        open: true,
        message: 'No layout to share. Please upload files first.',
        severity: 'warning',
      });
    }
  }, [shareId, layoutId, handleError]);

  const handleClearConfirmation = useCallback(() => {
    setConfirmDialog({
      open: true,
      title: 'Clear Configuration',
      message: 'Are you sure you want to clear out this config?',
    });
  }, []);

  const handleClearConfirmed = useCallback(() => {
    setLayoutId(null);
    setShareId(null);
    setLayout(null);
    localStorage.removeItem('layoutId');
    setSnackbar({
      open: true,
      message: 'Visualizer cleared successfully!',
      severity: 'success',
    });
    setConfirmDialog((prevDialog) => ({ ...prevDialog, open: false }));
    logInfo('About to navigate to home page');
    navigate('/', { replace: true });
    logInfo('Navigation to home page completed');
  }, [navigate]);


  const handleMobileMenuToggle = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const handleBottomNavigation = (event, newValue) => {
    navigate(newValue);
    setMobileMenuOpen(false);
  };

  const handleLogin = async (email, password) => {
    try {
      const response = await axios.post('/auth/login', { email, password });
      setUser(response.data.user);
      localStorage.setItem('token', response.data.token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      setAuthError(null);
      setAuthDialogOpen(false);
      setLayoutsFetched(false);
      setIsInitialFetch(true);
      logInfo('User logged in successfully');
      await debugLayoutCount(); // Call the debug function after login
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'An error occurred during login';
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.error === 'Please verify your email before logging in'
      ) {
        setShowResendVerification(true);
        setResendVerificationEmail(email);
      }
      setAuthError(errorMessage);
      logWarning('Login attempt failed');
      handleError(new AppError(errorMessage, 401));
    }
  };

  const handleResendVerification = async () => {
    try {
      await resendVerificationEmail(resendVerificationEmail);
      setSnackbar({
        open: true,
        message: 'Verification email resent. Please check your inbox.',
        severity: 'success',
      });
      setShowResendVerification(false);
    } catch (err) {
      const errorMessage =
        err.response?.data?.error || 'An error occurred while resending the verification email';
      setSnackbar({
        open: true,
        message: `Failed to resend verification email: ${errorMessage}`,
        severity: 'error',
      });
    }
  };

  const handleForgotPassword = () => {
    setAuthDialogOpen(false);
    navigate('/forgot-password');
  };

  const handleRegister = async (userData) => {
    try {
      await axios.post('/auth/register', userData);
      setAuthError(null);
      setIsLoginForm(true);
      logInfo('User registered successfully');
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'An error occurred during registration';
      setAuthError(errorMessage);
      logWarning('Registration attempt failed');
      handleError(new AppError(errorMessage, 400));
    }
  };

  const handleLogout = () => {
    setUser(null);
    setUserLayouts([]);
    setLayoutsFetched(false);
    setIsInitialFetch(true);
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
    logInfo('User logged out');
  };

  const handleOpenAuthDialog = () => {
    setAuthDialogOpen(true);
    setIsLoginForm(true);
  };

  const handleCloseAuthDialog = () => {
    setAuthDialogOpen(false);
    setAuthError(null);
  };

  const NoLayoutMessage = () => (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="50vh"
    >
      <Typography variant="h6" gutterBottom sx={{ color: 'text.primary' }}>
        No layout loaded. Please upload or select a layout first.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/"
        style={{ marginTop: '20px' }}
      >
        Go to Home Page
      </Button>
    </Box>
  );

  useEffect(() => {
    document.body.style.margin = '0';
    document.body.style.padding = '0';
    document.body.style.overflowX = 'hidden'; // Prevent horizontal overflow
  }, []);

  return (
    <ThemeProvider theme={customTheme}>
      <Box bgcolor="background.default" minHeight="100vh" display="flex" flexDirection="column">
        <StyledAppBar>
          <Toolbar>
            <MenuButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMobileMenuToggle}
            >
              <MenuIcon />
            </MenuButton>
            {!isMobile && (
              <>
                <img src={logo} alt="Logo" style={{ height: '32px', marginRight: '10px' }} />
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Companion
                </Typography>
              </>
            )}
            <NavItems>
              <StyledNavButton color="inherit" component={Link} to="/">
                Home
              </StyledNavButton>
              <StyledNavButton color="inherit" component={Link} to="/visualize">
                Visualize
              </StyledNavButton>
              <StyledNavButton color="inherit" component={Link} to="/portmapping">
                Port Mapping
              </StyledNavButton>
              <StyledNavButton color="inherit" component={Link} to="/report">
                Report Issue
              </StyledNavButton>
            </NavItems>
            <Box display="flex" alignItems="center" sx={{ marginLeft: 'auto' }}>
              {!isMobile &&
                (user ? (
                  <>
                    <Typography variant="body2" sx={{ marginRight: '20px' }}>
                      {user.name}
                    </Typography>
                    <Button color="inherit" variant="outlined" onClick={handleLogout} size="small">
                      Logout
                    </Button>
                  </>
                ) : (
                  <Button variant="contained" color="primary" onClick={handleOpenAuthDialog}>
                    Sign in
                  </Button>
                ))}
              <IconButton
                color="inherit"
                onClick={toggleDarkMode}
                sx={{ ml: 2 }}
                aria-label="toggle dark mode"
              >
                {darkMode ? <Brightness7 /> : <Brightness4 />}
              </IconButton>
            </Box>
          </Toolbar>
        </StyledAppBar>
        <MobileMenu anchor="left" open={mobileMenuOpen} onClose={handleMobileMenuToggle}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <List sx={{ flexGrow: 1 }}>
              <MobileMenuItem button component={Link} to="/" onClick={handleMobileMenuToggle}>
                <MobileMenuIcon>
                  <HomeIcon />
                </MobileMenuIcon>
                <ListItemText primary="Home" />
              </MobileMenuItem>
              <MobileMenuItem
                button
                component={Link}
                to="/visualize"
                onClick={handleMobileMenuToggle}
              >
                <MobileMenuIcon>
                  <VisualizeIcon />
                </MobileMenuIcon>
                <ListItemText primary="Visualize" />
              </MobileMenuItem>
              <MobileMenuItem
                button
                component={Link}
                to="/portmapping"
                onClick={handleMobileMenuToggle}
              >
                <MobileMenuIcon>
                  <PortMappingIcon />
                </MobileMenuIcon>
                <ListItemText primary="Port Mapping" />
              </MobileMenuItem>
              <MobileMenuItem button component={Link} to="/report" onClick={handleMobileMenuToggle}>
                <MobileMenuIcon>
                  <ReportIcon />
                </MobileMenuIcon>
                <ListItemText primary="Report Issue" />
              </MobileMenuItem>
            </List>
          </Box>
        </MobileMenu>
        <ContentContainer maxWidth={false} disableGutters>
          <Box sx={{ bgcolor: 'background.default' }}>
            <AnimatePresence mode="wait">
              <motion.div
                key={location.pathname}
                initial={{ opacity: 0, scale: 0.99 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.99 }}
                transition={{
                  type: 'tween',
                  ease: 'easeInOut',
                  duration: 0.2,
                  opacity: { duration: 0.3 },
                  scale: { duration: 0.4 },
                }}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  overflow: 'auto',
                }}
              >
                <Routes>
                  <Route
                    path="/"
                    element={
                      <StyledPaper elevation={0}>
                        <Box p={2}>
                          <Grid container spacing={3}>
                            {user && (
                              <Grid item xs={12}>
                                <StyledPaper elevation={0}>
                                  <Box p={2}>
                                    <Typography variant="h5" gutterBottom>
                                      Your Saved Layouts
                                    </Typography>
                                    {isLoadingLayouts ? (
                                      <Box display="flex" justifyContent="center" p={2}>
                                        <CircularProgress />
                                      </Box>
                                    ) : (
                                      <UserLayouts
                                        layouts={userLayouts}
                                        onLayoutSelect={fetchLayout}
                                        onLayoutsChange={fetchUserLayouts}
                                        editingLayout={editingLayout}
                                        editingName={editingName}
                                        onEditLayout={handleEditLayout}
                                        onSaveLayoutName={handleSaveLayoutName}
                                        onEditNameChange={setEditingName}
                                      />
                                    )}
                                  </Box>
                                </StyledPaper>
                              </Grid>
                            )}
                            {!isMobile && (
                              <Grid item xs={12} md={6}>
                                <StyledPaper elevation={0}>
                                  <Box p={2}>
                                    <Typography variant="h5" gutterBottom>
                                      Upload Files
                                    </Typography>
                                    <FileUpload onUploadSuccess={handleUploadSuccess} user={user} />
                                  </Box>
                                </StyledPaper>
                              </Grid>
                            )}
                            {layout && (
                              <Grid item xs={12} md={6}>
                                <StyledPaper elevation={0}>
                                  <Box p={2}>
                                    <Typography variant="h5" gutterBottom>
                                      Visualization
                                    </Typography>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          startIcon={<Visibility />}
                                          component={Link}
                                          to="/visualize"
                                          fullWidth
                                        >
                                          xLights Layout
                                        </Button>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          startIcon={<Share />}
                                          onClick={handleShare}
                                          disabled={isLoading}
                                          fullWidth
                                        >
                                          Share Layout
                                        </Button>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Button
                                          variant="contained"
                                          color="secondary"
                                          startIcon={<Clear />}
                                          onClick={handleClearConfirmation}
                                          fullWidth
                                        >
                                          Clear Layout
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </StyledPaper>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </StyledPaper>
                    }
                  />
                  <Route
                    path="/visualize"
                    element={
                      <VisualizerContent
                        layout={layout}
                        isSharedLayout={false}
                        isLoading={isVisualizerLoading}
                        setIsLoading={setIsVisualizerLoading}
                      />
                    }
                  />
                  <Route
                    path="/portmapping"
                    element={
                      isLoading ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="50vh"
                        >
                          <CircularProgress />
                        </Box>
                      ) : layout ? (
                        <ControllerPortMapping layout={layout} />
                      ) : (
                        <NoLayoutMessage />
                      )
                    }
                  />
                  <Route path="/report" element={<BugReportForm />} />
                  <Route path="/share/:shareId/*" element={<SharedLayout />} />
                  <Route path="/terms" element={<TermsAndConditions />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password/:token" element={<ResetPassword />} />
                  <Route path="/verify-email/:token" element={<VerifyEmail />} />
                  <Route path="/circletest" element={<CircleModelTest />} />
                </Routes>
              </motion.div>
            </AnimatePresence>
          </Box>
        </ContentContainer>
        {!location.pathname.includes('/visualize') && (
          <Box
            component="footer"
            sx={{
              mt: 'auto',
              bgcolor: 'background.paper',
              p: 4,
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <img
                src={logoImage}
                alt="xLights Visualizer Logo"
                style={{ width: '80px', height: '80px', marginTop: '5px', marginBottom: '15px' }}
              />
              <Typography variant="body2" color="textSecondary" align="center">
                <MuiLink component={Link} to="/privacy" color="inherit" sx={{ mr: 1 }}>
                  Privacy Policy
                </MuiLink>
                <MuiLink component={Link} to="/terms" color="inherit">
                  Terms & Conditions
                </MuiLink>
              </Typography>
            </Box>
          </Box>
        )}
        {isMobile && (
          <Paper
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
            }}
            elevation={3}
          >
            <BottomNavigation
              value={location.pathname}
              onChange={handleBottomNavigation}
              showLabels
              sx={{
                '& .MuiBottomNavigationAction-root': {
                  color: theme.palette.text.secondary,
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                  },
                },
              }}
            >
              <BottomNavigationAction label="Home" value="/" icon={<HomeIcon />} />
              <BottomNavigationAction
                label="Visualize"
                value="/visualize"
                icon={<VisualizeIcon />}
              />
              <BottomNavigationAction
                label="Port Mapping"
                value="/portmapping"
                icon={<PortMappingIcon />}
              />
              <BottomNavigationAction label="Report" value="/report" icon={<ReportIcon />} />
            </BottomNavigation>
          </Paper>
        )}
        <AuthDialog
          open={authDialogOpen}
          onClose={handleCloseAuthDialog}
          isLoginForm={isLoginForm}
          setIsLoginForm={setIsLoginForm}
          onLogin={handleLogin}
          onRegister={handleRegister}
          authError={authError}
          onForgotPassword={handleForgotPassword}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
        <ConfirmDialog
          open={confirmDialog.open}
          title={confirmDialog.title}
          message={confirmDialog.message}
          onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
          onConfirm={handleClearConfirmed}
        />
        <Dialog open={showResendVerification} onClose={() => setShowResendVerification(false)}>
          <DialogTitle>Resend Verification Email</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Your email is not verified. Would you like to resend the verification email?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowResendVerification(false)}>Cancel</Button>
            <Button onClick={handleResendVerification} color="primary">
              Resend
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

function App() {
  return (
    <GlobalErrorBoundary>
      <Router>
        <ErrorBoundary>
          <LayoutProvider>
            <AppContent />
          </LayoutProvider>
        </ErrorBoundary>
      </Router>
    </GlobalErrorBoundary>
  );
}

export default App;
