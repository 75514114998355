import * as THREE from 'three';
import DmxModel from './DmxModel';
import DmxColorAbilityRGB from './MovingHeads/DmxColorAbilityRGB';

class DmxFloodlight extends DmxModel {
    constructor(modelData) {
        super(modelData);
        this.displayAs = "DmxFloodlight";
        this.beamLength = modelData.beamLength || 1;
        this.shutterChannel = modelData.shutterChannel || 0;
        this.shutterThreshold = modelData.shutterThreshold || 1;
        this.shutterOnValue = modelData.shutterOnValue || 0;
        this.colorAbility = new DmxColorAbilityRGB(modelData);
    }

    createMesh() {
        const group = new THREE.Group();

        // Create the base of the floodlight
        const baseGeometry = new THREE.CylinderGeometry(0.2, 0.3, 0.1, 32);
        const baseMaterial = new THREE.MeshPhongMaterial({ color: 0x888888 });
        const baseMesh = new THREE.Mesh(baseGeometry, baseMaterial);
        group.add(baseMesh);

        // Create the light housing
        const housingGeometry = new THREE.CylinderGeometry(0.25, 0.25, 0.3, 32);
        const housingMaterial = new THREE.MeshPhongMaterial({ color: 0x444444 });
        const housingMesh = new THREE.Mesh(housingGeometry, housingMaterial);
        housingMesh.position.y = 0.2;
        group.add(housingMesh);

        // Create the light beam
        const beamGeometry = new THREE.ConeGeometry(0.5, this.beamLength, 32);
        const beamMaterial = new THREE.MeshPhongMaterial({
            color: 0xffffff,
            transparent: true,
            opacity: 0.5,
            side: THREE.DoubleSide
        });
        this.beamMesh = new THREE.Mesh(beamGeometry, beamMaterial);
        this.beamMesh.position.y = 0.35 + this.beamLength / 2;
        this.beamMesh.rotation.x = Math.PI;
        group.add(this.beamMesh);

        // Add a point light to simulate the light output
        this.light = new THREE.PointLight(0xffffff, 1, this.beamLength * 2);
        this.light.position.set(0, 0.35, 0);
        group.add(this.light);

        return group;
    }

    updateFromDmxValues(dmxValues) {
        super.updateFromDmxValues(dmxValues);

        // Update color
        this.colorAbility.updateFromDmxValues(dmxValues);
        const color = this.colorAbility.getBeamColor();

        // Update shutter
        const shutterOpen = this.isShutterOpen(dmxValues);

        // Apply updates to the mesh
        if (this.beamMesh && this.light) {
            this.beamMesh.material.color.setRGB(color.r, color.g, color.b);
            this.light.color.setRGB(color.r, color.g, color.b);
            this.beamMesh.visible = shutterOpen;
            this.light.intensity = shutterOpen ? 1 : 0;
        }
    }

    isShutterOpen(dmxValues) {
        if (this.shutterChannel > 0 && this.shutterChannel <= dmxValues.length) {
            return dmxValues[this.shutterChannel - 1] >= this.shutterThreshold;
        }
        return true;
    }

    getColors(allowSelected, c) {
        return this.colorAbility.getBeamColor();
    }

    setBeamLength(length) {
        this.beamLength = length;
        if (this.beamMesh) {
            const newGeometry = new THREE.ConeGeometry(0.5, this.beamLength, 32);
            this.beamMesh.geometry.dispose();
            this.beamMesh.geometry = newGeometry;
            this.beamMesh.position.y = 0.35 + this.beamLength / 2;
        }
        if (this.light) {
            this.light.distance = this.beamLength * 2;
        }
    }

    onPropertyChange(propertyName, value) {
        switch (propertyName) {
            case 'beamLength':
                this.setBeamLength(parseFloat(value));
                return true;
            case 'shutterChannel':
                this.shutterChannel = parseInt(value);
                return true;
            case 'shutterThreshold':
                this.shutterThreshold = parseInt(value);
                return true;
            case 'shutterOnValue':
                this.shutterOnValue = parseInt(value);
                return true;
            default:
                return super.onPropertyChange(propertyName, value);
        }
    }

    getProperties() {
        return {
            ...super.getProperties(),
            beamLength: this.beamLength,
            shutterChannel: this.shutterChannel,
            shutterThreshold: this.shutterThreshold,
            shutterOnValue: this.shutterOnValue,
            ...this.colorAbility.getChannelProperties()
        };
    }
}

export default DmxFloodlight;