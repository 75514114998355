import * as THREE from 'three';
import BaseModel from './BaseModel';
import { logInfo, logWarning, logError } from '../../utils/logger';

class WreathModel extends BaseModel {
    constructor(modelData) {
        super(modelData);
        this.name = 'WreathModel';
        this.parseModelData();
    }

    parseModelData() {
        const {
            parm1,
            parm2,
            parm3,
            WreathType,
            WreathWidth,
            WreathHeight,
            CustomWidth,
            CustomHeight,
            CustomDepth,
            StringType,
        } = this.modelData;

        this.topRadius = parseInt(parm1) || 50;
        this.bottomRadius = parseInt(parm2) || 50;
        this.radialSize = parseInt(parm3) || 1;
        this.wreathType = WreathType || 'Circle';
        this.wreathWidth = parseFloat(WreathWidth) || 1.0;
        this.wreathHeight = parseFloat(WreathHeight) || 1.0;
        this.customWidth = parseFloat(CustomWidth) || 1.0;
        this.customHeight = parseFloat(CustomHeight) || 1.0;
        this.customDepth = parseFloat(CustomDepth) || 1.0;
        this.stringType = StringType || 'RGB';

        logInfo('Wreath parameters:', {
            topRadius: this.topRadius,
            bottomRadius: this.bottomRadius,
            radialSize: this.radialSize,
            wreathType: this.wreathType,
            wreathWidth: this.wreathWidth,
            wreathHeight: this.wreathHeight,
            customWidth: this.customWidth,
            customHeight: this.customHeight,
            customDepth: this.customDepth,
            stringType: this.stringType,
        });
    }

    createGeometry() {
        const geometry = new THREE.BufferGeometry();
        const positions = [];
        const colors = [];

        const totalLights = (this.topRadius + this.bottomRadius) * 2;

        if (this.wreathType === 'Circle') {
            this.createCircleGeometry(positions, colors, totalLights);
        } else if (this.wreathType === 'Square') {
            this.createSquareGeometry(positions, colors, totalLights);
        } else {
            logError('Unsupported wreath type:', this.wreathType);
        }

        geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

        return geometry;
    }

    createCircleGeometry(positions, colors, totalLights) {
        const radius = (this.wreathWidth + this.wreathHeight) / 4;
        const angleStep = (2 * Math.PI) / totalLights;

        for (let i = 0; i < totalLights; i++) {
            const angle = i * angleStep;
            const x = radius * Math.cos(angle);
            const y = radius * Math.sin(angle);
            positions.push(x, y, 0);

            if (i === 0) {
                colors.push(0, 1, 1); // Teal color for the first light
            } else if (i === totalLights - 1) {
                colors.push(0.5, 0, 0); // Dull red color for the last light
            } else {
                colors.push(1, 1, 1); // White color for all other lights
            }
        }
    }

    createSquareGeometry(positions, colors, totalLights) {
        const width = this.wreathWidth;
        const height = this.wreathHeight;
        const lightsPerSide = Math.floor(totalLights / 4);

        const createSide = (startX, startY, endX, endY, lights) => {
            const stepX = (endX - startX) / lights;
            const stepY = (endY - startY) / lights;
            for (let i = 0; i < lights; i++) {
                const x = startX + stepX * i;
                const y = startY + stepY * i;
                positions.push(x, y, 0);

                if (positions.length / 3 === 1) {
                    colors.push(0, 1, 1); // Teal color for the first light
                } else if (positions.length / 3 === totalLights) {
                    colors.push(0.5, 0, 0); // Dull red color for the last light
                } else {
                    colors.push(1, 1, 1); // White color for all other lights
                }
            }
        };

        createSide(-width / 2, -height / 2, width / 2, -height / 2, lightsPerSide); // Bottom
        createSide(width / 2, -height / 2, width / 2, height / 2, lightsPerSide);   // Right
        createSide(width / 2, height / 2, -width / 2, height / 2, lightsPerSide);   // Top
        createSide(-width / 2, height / 2, -width / 2, -height / 2, lightsPerSide); // Left
    }

    createMesh() {
        const geometry = this.createGeometry();
        const material = new THREE.PointsMaterial({
            size: parseFloat(this.modelData.PixelSize) || 2,
            sizeAttenuation: false,
            vertexColors: true,
        });

        const points = new THREE.Points(geometry, material);
        return this.applyTransformations(points);
    }

    applyTransformations(object) {
        const { worldPos } = this.modelData.screenLocation;
        object.position.set(worldPos.x, worldPos.y, worldPos.z);

        // Apply scaling based on the wreath dimensions
        const scale = Math.max(this.wreathWidth, this.wreathHeight) / 2;
        object.scale.set(scale, scale, scale);

        return object;
    }

    getDimension() {
        return Math.max(this.wreathWidth, this.wreathHeight);
    }
}

export default WreathModel;