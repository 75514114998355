import { logError } from './logger';

export class AppError extends Error {
  constructor(message, statusCode = 500) {
    super(message);
    this.statusCode = statusCode;
    this.name = this.constructor.name;
    Error.captureStackTrace(this, this.constructor);
  }
}

export const handleError = (error, setSnackbar) => {
  console.error('Error:', error);
  logError(error);

  let message = 'An unexpected error occurred. Please try again.';
  let severity = 'error';

  if (error instanceof AppError) {
    message = error.message;
    severity = error.statusCode < 500 ? 'warning' : 'error';
  } else if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    message = error.response.data.message || error.response.data.error || message;
    severity = error.response.status < 500 ? 'warning' : 'error';
  } else if (error.request) {
    // The request was made but no response was received
    message = 'No response received from server. Please check your connection.';
    severity = 'warning';
  } else if (error.message) {
    // Something happened in setting up the request that triggered an Error
    message = error.message;
  }

  setSnackbar({
    open: true,
    message,
    severity,
  });
};

export const createErrorHandler = (setSnackbar) => (error) => handleError(error, setSnackbar);
